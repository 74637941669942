import React from 'react'
import {Spin, Comment, Avatar, Tooltip, Pagination, Row} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios';
import CommentList from './CommentList'
import { transform, transformatTime } from '../../utils/utils'
import QueueAnim from 'rc-queue-anim'
import cookie from 'js-cookie'

class AskList extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      list: props.detail.quesHdListVoList || [],
      // pageInfo: {
      //   current: 1,
      //   size: 10,
      //   total: 0
      // },
      loading: true
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      list: nextProps.detail.quesHdListVoList || [],
      // pageInfo: {
      //   current: 1,
      //   size: 10,
      //   total: 0
      // },
      loading: false
    })
  }

  getList(param = {}) {
    this.setState({
      loading: true
    })
    // console.log('getList',this.props.defaultData)
    axios.postString(this.props.api, {
      ...this.props.defaultData,
      ...param
    }).then(res => {
      this.setState({
        loading: false,
        list: res.records,
        // pageInfo: {
        //   current: res.current,
        //   size: res.size,
        //   total: res.total
        // }
      })
    }).catch(() => {
      this.setState({
        loading: false
      })
    })
  }
  showAll(index) {
    this.setState({
      list: this.state.list.map((item, i) => {
        return index === i ? {
          ...item,
          showAll: !item.showAll
        } : item
      })
    })
  }
  replay = (values, commentList, callback) => {
    const { qid, pid, itemIndex } = commentList.props
    const { uid, headPic, nickName } = JSON.parse(cookie.get('user'))
    const { content } = values
    axios.postString('question/reply', {
      qid, pid, uid, content
    }).then(() => {
      callback(true)
      this.setState({
        list: this.state.list.map((item, index) => {
          if (index === itemIndex) {
            return {
              ...item,
              hdList: item.hdList.concat({
                createTime: Date.now(),
                headPic: headPic,
                content,
                showName: nickName
              })
            }
          } else {
            return item
          }
        })
      })
    }).catch(() => {
      callback(false)
    })
  }
  render() {
    // const pagination = {
    //   current: this.state.pageInfo.current,
    //   size: this.state.pageInfo.size,
    //   total: this.state.pageInfo.total,
    //   showQuickJumper: true,
    //   showSizeChanger: true,
    //   showTotal: () => {
    //     return `共 ${this.state.pageInfo.total} 条记录 第 ${this.state.pageInfo.current} / ${Math.ceil(this.state.pageInfo.total/this.state.pageInfo.size)} 页`
    //   },
    //   onChange:(current, size) => {
    //     this.getList({current})
    //   },
    //   onShowSizeChange:(current, size) => {
    //     this.getList({current, size})
    //   },
    // }
    console.log('AskList.render()')
    //
    return (
      <div className='detail-box'>
        <Spin
          spinning={this.state.loading}
        >
          {
            this.state.list.map((item, index) => {
              return (
                <Row key={index}>
                  <div style={{ flex: 1 }}>
                    <Comment
                      author={item.showName}
                      avatar={
                        <Avatar
                          src={item.headPic}
                          alt={item.showName}
                        />
                      }
                      content={
                        <p>
                          {item.content}
                        </p>
                      }
                      datetime={
                        <Tooltip title={transformatTime(new Date(item.createTime))}>
                          <span>{transform(new Date(item.createTime)).fromNow()}</span>
                        </Tooltip>
                      }
                    >
                      <QueueAnim
                          style={{'overflow': 'hidden'}}
                          animConfig={[
                            {opacity: [1, 0], translateY: [0, -50]},
                            {height: ['100%', 0] }
                          ]}
                      >
                      {
                        item.showAll ? (
                          <CommentList
                            key='CommentList'
                            replay={this.replay}
                            list={item.hdList}
                            qid={item.qid}
                            pid={item.pid}
                            itemIndex={index}
                          />
                        ) : null
                      }
                      </QueueAnim>
                    </Comment>
                  </div>
                  <a
                    style={{'display': 'flex', 'margin': '0 50px 0 50px', 'alignItems': 'center'}}
                    onClick={() => {
                      this.showAll(index)
                    }}
                  >
                    {
                      item.showAll ? <UpOutlined /> : <DownOutlined />
                    }
                  </a>
                </Row>

              )
            })
          }
        </Spin>
        {/*<Pagination*/}
          {/*{ ...pagination }*/}
        {/*/>*/}
      </div>
    )
  }
}
export default withRouter(AskList)
