import React, { useState } from 'react'
import { Select } from 'antd'
import axios from '../request/axios'

let loading = false
let searchQueue = []
let cacheData = {}

class CustomMutipleSelect extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      options: []
    }
  }
  componentDidMount() {
    if (this.props.value) {
      this.updateOptions(this.props.mode ? this.props.value.map(item => JSON.parse(item)) : [JSON.parse(this.props.value)])
      setTimeout(() => {
        this.onChange(this.props.value)
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.value && nextProps.value) {
      this.updateOptions(this.props.mode ? nextProps.value.map(item => JSON.parse(item)) : [JSON.parse(nextProps.value)])
      this.onChange(nextProps.value)
    }
  }
  onSearch = (value) => {
    if (value.trim()) {
      searchQueue.push(value)
      if (!loading) {
        this.doSearch()
      }
    }
  }
  doSearch =  () => {
    const value = searchQueue[searchQueue.length - 1]
    searchQueue = []
    if (cacheData[value]) {
      this.updateOptions(cacheData[value])
    } else {
      loading = true
      axios.postString(this.props.api, {
        ...this.props.defaultData,
        [this.props.searchName]: value
      }).then(res => {
        // console.log(res)
        cacheData[value] = res.records
        this.clearLoading()
        this.updateOptions(res.records)
      }).catch(() => {
        this.clearLoading()
      })
    }
  }
  clearLoading = () => {
    loading = false
    if (searchQueue.length) {
      this.doSearch()
    }
  }
  updateOptions = (options) => {
    this.setState({
      options: options.map((item, index) => {
        // return props.extraData ? Object.assign.apply(null, [{
        //   key: index,
        //   value: props.getValue ? item[props.dataName] : props.getValue(item)
        // }].concat(props.extraData(item).map(extra => {
        //   return {
        //     [extra.name.toLowerCase()]: extra.value
        //   }
        // }))) : {
        //   key: index,
        //   value: props.getValue ? item[props.dataName] : props.getValue(item)
        // }

        return (
          <Select.Option
            value={JSON.stringify(item)}
            key={index}
          >
            {this.props.getLabel && this.props.getLabel(item) ? this.props.getLabel(item) : item[this.props.searchName]}
          </Select.Option>
        )
      })
    })
  }
  onChange = (values) => {
    this.props.father.formRef.current.setFieldsValue({
      [this.props.name]: this.props.getValues ? this.props.getValues(values) : this.props.mode ? values.map(value => {
        return JSON.parse(value)[this.props.name]
      }) : JSON.parse(values)[this.props.name]
    })
    this.props.handleCheck && this.props.handleCheck(values, this.props.father.formRef.current)
    // props.extraData && props.extraData().forEach(extra => {
    //   props.father.formRef.current.setFieldsValue({
    //     [extra.name]: obj[extra.name.toLowerCase()]
    //   })
    // })
    // console.log(values, obj)
  }
  render() {
    const options = this.state.options.map(option => option)
    return (
      <Select
        mode={this.props.mode || null}
        showSearch
        onSearch={this.onSearch}
        onChange={this.onChange}
        key={this.props.value}
        defaultValue={(this.props.getLabel && this.props.value ? (this.props.mode ? JSON.parse(this.props.value).map(item => this.props.getLabel(item)) : this.props.getLabel(JSON.parse(this.props.value))) : this.props.value) || undefined}
        placeholder={this.props.placeholder || '请搜索选择'}
        filterOption={false}
        notFoundContent={(
          <div className={'align-center'}>没有符合的项</div>
        )}
      >
        {options}
      </Select>
    )
  }

}

export default CustomMutipleSelect