import React from 'react'
import { transformatTime } from '../../utils/utils'
import { Badge } from 'antd'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { lawyerStateText } from '../../config/types'
import cookie from 'js-cookie'



function LawyerQuery(props) {

  const userType = cookie.get('userType')
  const isAdmin = userType === 'Admin'
  const isLawFirm = userType === 'LawFirm'
  const isOrganization = userType === 'Organization'

  const columns = [
    {
      title: '律师',
      dataIndex: 'userReal',
      render: userReal => userReal.name
    },
    {
      title: '手机号',
      align: 'center',
      dataIndex: 'phone'
    },
    {
      title: '律师事务所',
      dataIndex: 'agency',
      render: agency => agency.agencyName
    },
    {
      title: '擅长领域',
      dataIndex: 'specialityList',
      render: specialityList => specialityList.map(item => item.name).join(',')
    },
    {
      title: '支持法律援助',
      align: 'center',
      dataIndex: 'isPub',
      render: isPub => isPub === 1 ? (<Badge color='#52c41a' />) : ''
    },
    isAdmin ? {
      title: '状态',
      dataIndex: 'checkState',
      align: 'center',
      render: state => {
        return (
          <Badge color={lawyerStateText[state].color} text={lawyerStateText[state].label} />
        )
      }
    } : {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      render: state => {
        return (
          <Badge color={lawyerStateText[state].color} text={lawyerStateText[state].label} />
        )
      }
    },
    {
      title: '入驻时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      // {
      //   label: '新建',
      //   icon: <PlusOutlined/>,
      //   onClick() {
      //     props.history.push('add')
      //   }
      // }
    ],
    params: [
      {
        name: 'name',
        label: '律师名称'
      },
      {
        name: 'state',
        label: '状态',
        type: 'select',
        options: Object.entries(lawyerStateText).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: 'detail/:uid'
    },
    // {
    //   type: 'link',
    //   label: '修改',
    //   url: 'edit/:uid'
    // },
    {
      type: 'delete',
      label: '解除关系',
      api: 'lawyer/disLawyer/:uid',
      condition: row => isLawFirm && ['1'].includes(row.state.toString())
    },
    {
      type: 'audit',
      api: 'lawyer/audit',
      dataName: 'uid',
      params: [
        {
          name: 'auditStatus',
          to: 'state',
          type: 'radio',
          label: '审核结果',
          options: Object.entries(lawyerStateText).filter(item => ['1', '2'].includes(item[0])).map(item => {
            return {
              value: item[0],
              label: item[1].label
            }
          })
        },
        {
          name: 'auditContent',
          label: '审核意见',
          type: 'textArea'
        }
      ],
      condition: row => isLawFirm && ['0', '2'].includes(row.state.toString())
    },
    {
      type: 'audit',
      api: 'lawyer/checkState',
      dataName: 'id',
      defaultData: row => ({
        checkState: '1',
        uid: row.uid
      }),
      condition: row => isAdmin && ['0'].includes(row.checkState.toString())
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="uid"
        columns={columns}
        search={search}
        actions={actions}
        api='lawyer/query'
      />

    </>
  )
}
export default withRouter(LawyerQuery)
