import cookie from 'js-cookie'
import { parse } from 'qs'
import moment from 'moment'
import { momentZhCn } from '../config/lang'
import MenuList from '../config/menuList'

moment.lang('zh-cn', momentZhCn)

export function isLogin () {
  return cookie.get('token') && cookie.get('token') !== 'undefined'
}

export function toLogin(remember) {
  cookie.remove('token')
  cookie.remove('JSESSIONID')
  const from = `?from=${encodeURIComponent(window.location.pathname+window.location.search)}`
  window.location.href = `${window.location.origin}/login${remember ? from : ''}`
}

export function logIn (res, history) {
  cookie.set('token', res.token)
  cookie.set('user', JSON.stringify(res))
  const search = history.location.search
  let from = '/home'
  if (search) {
    from = parse(search.substring(1))['from'] || from
  }
  history.push(decodeURIComponent(from))
}

export const tagColors = ['magenta', 'red', 'volcano', 'orange', 'green', 'cyan', 'blue', 'geekblue', 'purple']

/**
 * 格式化时间
 */
export function transformatTime(date, format) {
  return moment(date, 'YYYYMMDDHHmmssSS').format(format || 'YYYY-MM-DD HH:mm:ss')
}

/**
 * 格式化日期
 */
export function transformDate(date, format) {
  return moment(date, 'YYYYMMDDHHmmss').format(format || 'YYYY年MM月DD日')
}
/**
 * 格式化日期
 */
export function transform(date, format) {
  return moment(date, format || 'YYYYMMDDHHmmss')
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })
}
/**
 * 根据地址 匹配 MenuList 父对象及子对象
 */
export function getMatchMenuTree(history) {
  let parents = []
  const pathname = history.location.pathname.replace(/\/\d[^/]+/g,'')
  const matchPath = (list, tempMenuArr = []) => {
    list.some(menu => {
      if (menu.to && menu.to.replace(/\/:[a-zA-Z]+/g,'') === pathname) {
        return parents = tempMenuArr.concat([menu])
      } else if (menu.children) {
        return matchPath(menu.children, tempMenuArr.concat([menu]))
      }
    })
  }
  matchPath(MenuList)
  return parents
}

export function getArgs (key) {  
    var args = {}; 
    var query = window.location.search.substring(1); 
    var pairs = query.split("&");  
    for(var i = 0; i < pairs.length; i++) { 
      var pos = pairs[i].indexOf('='); 
      if (pos == -1) continue; 
              var argname = pairs[i].substring(0,pos);
              var value = pairs[i].substring(pos+1);
              value = decodeURIComponent(value);
              if(args[argname]){
                  if(typeof args[argname] != "array")
                      args[argname] = [args[argname]];
                  args[argname].push(value)
              }else
                  args[argname] = value; 
    }
      return key?args[key]:args;
  }