import React, { useState } from 'react'
import { Badge } from 'antd'
import { transformatTime, getArgs } from '../../../utils/utils'
import CustomPageHeader from '../../../components/CustomPageHeader'
import CustomTable from '../../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { caseStateText } from '../../../config/types'
import axios from '../../../request/axios'

function GroupCaseList(props) {
  const batchInfo = props.match.params.batchInfo
  const [batchCaseId, tempCaseId, batchName] = batchInfo.split('_')
  const [timestamp, setTimestamp] = useState(new Date().getTime())
  const [caseId, setCaseId] = useState(tempCaseId)
  const columns = [
      {
        title: '案件名称',
        dataIndex: 'name'
      },
  
      {
        title: '案件类型',
        dataIndex: 'type'
      },
      {
        title: '公益机构',
        dataIndex: 'orgAgencyName'
      },
      // {
      //   title: '案号',
      //   dataIndex: 'caseNo'
      // },
      // {
      //   title: '律所案号',
      //   dataIndex: 'caseNo'
      // },
      {
        title: '状态',
        dataIndex: 'state',
        render: state => {
          return (
            <Badge color={(caseStateText[state.toString()] || {}).color} text={(caseStateText[state.toString()] || {}).label} />
          )
        }
      },
      {
        title: '当事人',
        dataIndex: 'accuserNames'
      },
      {
        title: '创建时间',
        dataIndex: 'createTime',
        align: 'center',
        render: createTime => transformatTime(new Date(createTime))
      },
      {
        title: '办案律师',
        dataIndex: 'lawyerName',
        render: lawyerName => lawyerName ? lawyerName.toString() : ''
      }
  ]
  const search = {
    extra: [
      {
        label: '引入新案件',
        icon: <PlusOutlined />,
        type: 'list',
        api: 'case/query',
        columns: [
          {
            title: '案件名称',
            dataIndex: 'name'
          },
          {
            title: '案件类型',
            dataIndex: 'type'
          },
          {
            title: '公益机构',
            dataIndex: 'orgAgencyName'
          }
        ],
        search: {
          params: [
            {
              name: 'title',
              label: '案件名称'
            }
          ]
        },
        rowKey: 'id',
        onHandleOk: (selectedRowKeys) => {
          axios.postString(`batch/addBatch/${batchCaseId}?ids=${selectedRowKeys.toString()}`).then(res => {
            setTimestamp(new Date().getTime())
          }).catch(() => {})
        }
      }
    ],
    params: [
      {
        name: 'name',
        label: '群体案件名'
      }
    ]
  }
  const actions = [
    {
      type: 'audit',
      label: '设为主案件',
      api:  'batch/setMain',
      defaultData: row => {
        return {
          batchId: batchCaseId,
          caseId: row['id']
        }
      },
      condition: row => row['id'] !== parseInt(caseId),
      handleOk: row => {
        setCaseId(row['id'])
      }
    },
    {
      type: 'link',
      label: '查看/编辑',
      url: `/case/groupCase/${batchCaseId}_${caseId}_${batchName}/edit/:id`,
      setState: row => {
        return {
          batchId: caseId
        }
      }
    },
    {
      type: 'delete',
      label: '移除子案件',
      api:  'batch/removeBatch/:id'
    },
  ]
  
  
  return (
    <>
    <CustomPageHeader
      title={'群体案件名称：' + batchName}
    />
    <CustomTable
      rowKey="id"
      api={`batch/listCase/${batchCaseId}`}
      timestamp={timestamp}
      actions={actions}
      columns={columns}
      search={search}
      noPagination={true}
    />
    </>
  )
}
export default GroupCaseList
