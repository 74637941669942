import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import { agencyTypeText } from '../../config/types'



function AgencyAdd(props) {
  const id = props.match.params.id
  const AGENCYPATH = id ? `/agency/edit/${id}` : '/agency/add'
  const isOrganization = props.history.location.pathname === AGENCYPATH

  let formData = [
    {
      name: 'agencyName',
      label: isOrganization ? '机构名称' : '律师事务所'
    },
    {
      name: 'agencyCode',
      label: isOrganization ? '机构代码' : '事务所代码'
    },
    {
      name: 'manager',
      label: isOrganization ? '机构负责人' : '事务所负责人'
    },
    isOrganization
      ? {
        name: 'agencyType',
        label: '机构类型',
        type: 'select',
        options: Object.entries(agencyTypeText).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      }
      : {
        condition: () => false
      }
    ,
    {
      name: 'addStr,province,city,area',
      label: '省市区',
      type: 'area'
    },
    {
      name: 'address',
      label: '地址'
    },
    {
      name: 'busPic',
      label: '营业执照',
      type: 'uploadImg',
      folderPath: '/company/license/'
    },
    {
      name: 'tel',
      label: '电话'
    },
    {
      name: 'memo',
      type: 'textArea',
      label: '描述'
    },
    {
      name: 'licencePic',
      label: '执业许可证',
      type: 'uploadImg',
      folderPath: '/company/license/'
    },
    {
      name: 'busPic',
      label: '机构形象图',
      type: 'uploadImg',
      folderPath: '/company/license/'
    },
    {
      name: 'mobile',
      type: 'customInput',
      label: '机构管理员',
      placeholder: '请输入管理员手机号',
      maxLength: 11,
      addonAfter: null,
      onValueChange: (_this, e) => {
        if (e.currentTarget.value.length === 11) {
          axios.postString('user/isExsitByMobile', {
            mobile: e.currentTarget.value
          }).then(res => {
            if (res.type) {
              _this.props.father.formRef.current.setFieldsValue({
                [isOrganization ? 'adminOrgAgencyId' : 'adminAgencyId']: res.uid
              })
              _this.setState({
                addonAfter: res.newStr
              })
            } else {
              _this.props.father.formRef.current.setFieldsValue({
                [isOrganization ? 'adminOrgAgencyId' : 'adminAgencyId']: null
              })
              _this.setState({
                addonAfter: null
              })
              _this.props.father.formRef.current.validateFields(['mobile'], values => Promise.reject('111'))
            }
          }).catch(() => {})
        } else {
          _this.props.father.formRef.current.setFieldsValue({
            [isOrganization ? 'adminOrgAgencyId' : 'adminAgencyId']: null
          })
          _this.setState({
            addonAfter: null
          })
        }

      },
      rules: [
        {required: true, message: '请输入管理员手机号'},
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (value.length < 11) {
              return Promise.reject('手机号不完整')
            } else {
              return new Promise((resolve, reject) => {
                axios.postString('user/isExsitByMobile', {
                  mobile: value
                }).then(res => {
                  if (res.type) {
                     resolve()
                  } else {
                    reject(res.newStr)
                  }
                }).catch(() => {
                  reject('服务器处理失败')
                })
              })
            }
          },
        })]
    },
    {
      name: isOrganization ? 'adminOrgAgencyId' : 'adminAgencyId',
      type: 'hidden'
    }
  ]
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    id && axios.postString(`agency/detail/${id}`).then(res => {
      setInitialValues({
        ...res,
        agencyType: (res.agencyType || '').toString(),
        mobile: (res[isOrganization ? 'orgUser' : 'user'] || {}).mobile,
        [isOrganization ? 'adminOrgAgencyId' : 'adminAgencyId']: (res[isOrganization ? 'userOrg' : 'user'] || {}).id
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={initialValues}
        action='agency/edit'
        defaultData={{ type: isOrganization ? '2' : '1'}}
      />
    </>
  )
}

export default withRouter(AgencyAdd)
