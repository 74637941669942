import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Form, ConfigProvider } from 'antd'
import { validateMessages } from '../config/validateConfig'

import { getInput } from './factory'

class CustomModalForm extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      refresh: true
    }
  }
  refresh() {
    this.setState({
      refresh: !this.state.refresh
    })
  }

  render() {
    // console.log('custommodalform.render()')
    return (
      <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
        <Form
          ref={this.props.formRef}
        >
          {
            this.props.formData.filter(item => !item.condition || item.condition(this.props.formRef)).map((param, i) => {
              return param.render
                ? param.render(this.props.row, {formRef: this.props.formRef}, this)
                : (
                    <Form.Item
                      key={i}
                      name={param.name}
                      label={param.label}
                      rules={param.rules || (param.type === 'text' ? [] : [{required: true}])}
                    >
                      {getInput(param, this.props.row, {formRef: this.props.formRef}, this)}
                    </Form.Item>
                  )
            })
          }
        </Form>
      </ConfigProvider>
    )
  }
}

export default CustomModalForm
