import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Form, ConfigProvider, Select, message, Input } from 'antd'
import axios from '../../request/axios'
import { validateMessages } from '../../config/validateConfig'


class Appoint extends React.Component{
  constructor(props) {
    super(props)
    const sf = props.sf
    this.state = {
      sf,
      lawyerFirms: [],
      lawyers: []
    }
  }
  componentDidMount() {
    
    axios.postString(`${this.state.sf}/case/zp/${this.props.row.id}`).then(lawyerFirms => {
      if (!lawyerFirms || !lawyerFirms.length) {
        message.info('没有找到符合条件的律所')
      }
      this.setState({
        lawyerFirms
      })
    }).catch(() => {})
  }
  render() {
    return (
      <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
        <Form
          ref={this.props.formRef}
        >
          <Form.Item
            label={'律所'}
          >
            <Select
              placeholder={'请先选择律所'}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={id => {
                axios.postString(`lawyer/query`, {
                  agencyId: id,
                  isPub: 1,
                  size: 1000
                }).then(res => {
                  if (!res.records.length) {
                    message.info('该律所下没有找到符合条件的律师')
                  }
                  this.setState({
                    lawyers: res.records
                  })
                }).catch(() => {})
              }}
            >
              {
                this.state.lawyerFirms.map((item, index) => (
                  <Select.Option value={item.id} key={index}>{item.agencyName}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            label={'指派律师'}
            rules={[{required: true}]}
          >
            <Select
              placeholder={'可同时指派多个律师'}
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={'没有找到符合条件的律师'}
              onChange={values => {
                this.props.formRef.current.setFieldsValue({
                  realIds: values.toString()
                })
              }}
            >
              {
                this.state.lawyers.map((item, index) => (
                  <Select.Option value={item.realId} key={index}>{item.userReal.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name={'realIds'}
            noStyle
          >
            <Input type='hidden' />
          </Form.Item>
        </Form>
      </ConfigProvider>
    )
  }
}

export default Appoint