import { combineReducers } from 'redux';
import switchCollapsed from './switchCollapsed'
import sendVCode from './sendVCode'

const reducer = combineReducers({
  switchCollapsed,
  sendVCode
})

export default reducer


