import React from 'react'
import { Input, Button, Modal, Form } from 'antd'
import { setSpecialValue, getFormItem } from './factory'
import './CustomModal.less'

class CustomModal extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }
  componentWillReceiveProps(nextProps) {

  }

  formRef = React.createRef()
  render() {
    const initialValues = this.props.activeIndex !== null ? this.props.list[this.props.activeIndex] : (this.props.initialValues || {})
    return (
      <Modal
        title={this.props.label}
        width={900}
        visible={true}
        onCancel={this.props.handleCancel}
        footer={[
          <Button key="back" onClick={this.props.handleCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={this.state.loading} onClick={() => {
            this.formRef.current.validateFields().then(() => {
              this.setState({
                loading: true
              })
              this.props.handleOk(this.formRef.current.getFieldsValue()).catch(() => {
                this.setState({
                  loading: false
                })
              })
            }).catch(() => {})
          }}>
            确定
          </Button>,
        ]}
      >
        <Form
          ref={this.formRef}
          initialValues={initialValues}
          layout={'inline'}
          onFinish={values => {
            return values
          }}
        >
          {
            this.props.options.map((item, i) => {
              setSpecialValue(item, initialValues)
              return item.type === 'hidden' ? (
                <Form.Item
                  key={i}
                  name={item.name}
                  noStyle
                >
                  <Input type='hidden' defaultValue={item.value}/>
                </Form.Item>
              ) : (
                <Form.Item
                  style={{'width': item.type === 'array' ? '100%' : 'inherit'}}
                  key={i}
                  name={item.name.split(',')[0]}
                  label={item.label}
                  rules={item.rules || [{required: true}]}
                >
                  {getFormItem(item, this)}
                </Form.Item>
              )
            })
          }
        </Form>
      </Modal>
    )
  }
}

export default CustomModal