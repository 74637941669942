import React from 'react'
import OSS from 'ali-oss'
import { Upload, message, Button, Modal } from 'antd'
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import axios from '../request/axios'
import { acceptType } from "config/types"

class AliyunOSSUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      OSSData: {},
      loading: false,
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      folderPath: this.props.row
      ? (this.props.folderPath || '').split('/').map(item => item && item.charAt(0) === ':' ? this.props.row[item.substring(1)] : item || '').join('/') || '/'
      : this.props.folderPath,
      fileList: props.defaultValue ? props.defaultValue.split(',').map((item,index) => {
        return {
          uid: index,
          url: item,
          status: 'done'
        }
      }) : []
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return nextProps.defaultValue !== this.props.defaultValue || nextState.previewVisible !== this.state.previewVisible
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        fileList: nextProps.defaultValue ? nextProps.defaultValue.split(',').map((item,index) => {
          return {
            uid: index,
            url: item,
            status: 'done'
          }
        }) : []
      })
    }
  }

  handleCancel = () => {
    this.setState({ previewVisible: false })
  }

  handlePreview = async (file, title) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj)
    // }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: title || file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  init = async () => {
    try {
      const OSSData = await new Promise(resolve => {
        axios.postString('oss/getOss').then(res => {
          resolve(res)
        }).catch(error => {
          throw error
        })
      })

      this.setState({
        OSSData: {
          accessKeyId: OSSData.accessKeyId,
          accessKeySecret: OSSData.accessKeySecret,
          bucket: OSSData.bucketName,
          region: OSSData.endpoint.split('.')[0],
          stsToken: OSSData.securityToken,
          expiration: OSSData.expiration
        }
      })
    } catch (error) {
      message.error(error)
    }
  }

  onChange = (info, onSuccess) => {
    switch (info.file.status) {
      case 'removed':
        this.setState({
          fileList: info.fileList
        })
        onSuccess(info.fileList)
        break
      // case 'uploading':
      //   this.setState({ loading: true })
      //   break
      // case 'done':
      //   this.setState({
      //     fileList: info.fileList,
      //     loading: false
      //   })
      //   break
    }
  }

  transformFile = file => {
    const suffix = file.name.slice(file.name.lastIndexOf('.'))
    file.url = Date.now() + suffix
    return file
  }

  beforeUpload = async () => {
    const { OSSData } = this.state
    if (!OSSData.expiration || new Date(OSSData.expiration).getTime() < Date.now()) {
      await this.init();
    }
    return true;
  };

  customRequest = async (obj, onSuccess) => {
    const { OSSData } = this.state
    const file = obj.file
    this.setState({
      loading: true
    })
    // this.setState({
    //   fileList: this.state.fileList.concat({
    //     uid: file.uid,
    //     status: 'done',
    //     url: await getBase64(obj.originFileObj)
    //   }),
    //   loading: false
    // })
    let client = new OSS(OSSData)
    client.put(this.state.folderPath + file.url, file).then(back => {
      let fileList = this.state.fileList.concat({
        url: back.url,
        name: file.name,
        type: file.type,
        status: 'done',
        uid: file.uid
      })
      this.setState({
        fileList,
        loading: false
      })
      onSuccess(fileList)
    }).catch(err => {
      console.error('error:', err)
    })
  }

  // setFileList = fileList => this.setState({fileList})

  render() {
    const { onSuccess, listType, limit, title } = this.props
    const { fileList, previewVisible, previewImage, previewTitle } = this.state;
    const uploadImg = (
      <>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">上传</div>
      </>
    )
    const uploadFile = (
      <>
        <Button>
          <UploadOutlined /> 上传
        </Button>
      </>
    )
    return (
      <>
        <Upload
          name='file'
          listType={listType}
          accept={this.props.accept || (listType === 'picture-card' ? acceptType.image : null)}
          fileList={fileList}
          multiple={limit > 1}
          onChange={info => {
            this.onChange(info, onSuccess)
          }}
          transformFile={this.transformFile}
          beforeUpload={this.beforeUpload}
          onPreview={file => {
            this.handlePreview(file, title)
          }}
          customRequest={obj => {
            this.customRequest(obj, onSuccess)
          }}
        >
          {limit > fileList.length ? (listType === 'picture-card' ? uploadImg : uploadFile) : null}
        </Upload>
        {
          listType === 'picture-card'
            ? (
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={this.handleCancel}
              >
                <img style={{ width: '100%' }} src={previewImage} />
              </Modal>
            )
            : ''
        }

      </>
    )
  }
}

export default AliyunOSSUpload
