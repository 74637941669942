import React from 'react'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import cookie from 'js-cookie'
function DoucmentModeQuery(props) {
  const { uid } = JSON.parse(cookie.get('user'))
  const userType = cookie.get('userType')
  const isAdmin = userType === 'Admin'
  const columns = [
    {
      title: '模版名称',
      dataIndex: 'name'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      {
        label: '新建模板',
        icon: <PlusOutlined/>,
        onClick() {
          props.history.push('add')
        }
      }
    ],
    params: [
      // {
      //   name: 'title',
      //   label: '案件名称'
      // },
      // {
      //   name: 'state',
      //   label: '状态',
      //   type: 'select',
      //   options: Object.entries(caseStateText).map(item => {
      //     return {
      //       value: item[0],
      //       label: item[1].label
      //     }
      //   })
      // }
    ]
  }
  const actions = [
    {
      type: 'link',
      label: '修改',
      url: 'edit/:id',
      condition: row => isAdmin || row.sid === uid
    },
    {
      type: 'delete',
      api: 'mould/del/:id',
      condition: row => isAdmin || row.sid === uid
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        api='mould/query'
      />

    </>
  )
}
export default withRouter(DoucmentModeQuery)
