import React, { Suspense } from 'react'

import { Layout, Spin } from 'antd'

// layouts
import Header from '../layouts/Header'
// import Footer from '../layouts/Footer'
import Sider from '../layouts/Sider'
// router在Content里
import Content from '../layouts/Content'

function Home() {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className="loading">
            <Spin/>
          </div>
        }
      >
        <Sider/>
        <Layout>
          <Header/>
          <Content/>
          {/*<Footer/>*/}
        </Layout>
      </Suspense>
    </Layout>
  )
}
export default Home