import React from 'react'
import { Form, Input, Button, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { getFormItem, setSpecialValue } from './factory'
import axios from '../request/axios'

import './CustomForm.less'


class CustomForm extends React.Component{
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentWillReceiveProps(nextProps) {
    this.formRef.current.setFieldsValue(nextProps.initialValues)
  }
  handlerSubmit = values => {
    this.props.formData.forEach(item => {
      if (item.valueFormat) {
        values[item.name] = item.valueFormat(values[item.name])
      } else if (item.type === 'date') {
        values[item.name] = values[item.name].format('YYYY-MM-DD')
      } else if (item.type === 'switch') {
        values[item.name] = values[item.name] ? '1' : '0'
      }
    })
    // Object.entries(values).forEach(item => {
    //   if (Object.prototype.toString.call(item[1]).replace(/\[object\s+([^\]]+)\]/,"$1").toLowerCase() === 'object') {
    //     if (item[1]._isAMomentObject) {
    //       values[item[0]] = item[1].format('YYYY-MM-DD')
    //     }
    //   } else if (typeof item[1] === 'boolean') {
    //     values[item[0]] = item[1] ? '1' : '0'
    //   }
    // })
    // console.log(values)
    // return
    axios[this.props.method || 'postString'](this.props.action, {
      ...this.props.defaultData,
      ...values
    }).then(res => {
      message.success('操作成功！')
      this.props.onHandleOK && this.props.onHandleOK(values, res, this)
      !this.props.notBack && setTimeout(() => {
        this.props.history.goBack()
      }, 1000)
    }).catch(() => {})
  }
  formRef = React.createRef()
  render() {
    //const
    // form.setFieldsValue(props.initialValues)
    console.log('initialValues', this.props.initialValues)
    return (
      <div className="submit-form-box">
        <Form
          ref={this.formRef}
          initialValues={this.props.initialValues}
          onFinish={this.handlerSubmit}
        >
          {
            this.props.initialValues[this.props.keyRow || 'id'] ? (
              <Form.Item
                name={this.props.keyRow || 'id'}
                noStyle
              >
                <Input type='hidden' />
              </Form.Item>
            ) : ''
          }
          {
            this.props.formData.filter(item => {
              return !item.condition || item.condition(this.props.initialValues)
            }).map((item, index) => {
              item.name && setSpecialValue(item, this.props.initialValues)
              return item.type === 'hidden' ? (
                <Form.Item
                  key={index}
                  name={item.name}
                  noStyle
                >
                  <Input type='hidden' defaultValue={item.value}/>
                </Form.Item>
              ) : (
                <Form.Item
                  key={index}
                  name={item.name && item.type !== 'text' ? item.name.split(',')[0] : undefined}
                  label={item.label}
                  rules={item.rules || (item.type === 'text' ? [] : [{required: true}])}
                  {
                    ... (item.type === 'switch' ? { valuePropName: 'checked'} : {})
                  }
                  style={{ 'maxWidth': ['array', 'editor'].includes(item.type) ? 'auto' : '600px'}}
                >
                  {
                    getFormItem(item, this)
                  }
                </Form.Item>
              )
            })
          }
          <Form.Item
            wrapperCol = {{ offset: 6 }}
          >
            <Button
              type='primary'
              htmlType="submit"
              size='large'
            >
              提交
            </Button>
            {
              !this.props.notBack
                ? <Button
                  style={{'marginLeft': '20px'}}
                  size='large'
                  onClick={() => {
                    this.props.history.goBack()
                  }}
                >
                  返回
                </Button>
                : ''
            }
          </Form.Item>
        </Form>
      </div>
    )
  }

}

export default withRouter(CustomForm)
