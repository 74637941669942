import React from 'react'
import CustomPageHeader from '../../../components/CustomPageHeader'
import { FolderOpenOutlined, LinkOutlined, ArrowUpOutlined, ArrowDownOutlined, FileImageOutlined, AudioOutlined, VideoCameraOutlined, QuestionCircleOutlined, FilePdfOutlined, CopyOutlined } from '@ant-design/icons'
import { Tree, Button, Row, Col, Card, Tooltip, Modal } from 'antd'
import axios from '../../../request/axios'
import { getEvidencePreview } from '../../../components/factory'
import { system } from '../../../config/system'
import cookie from 'js-cookie'
import './query.less'
import { withRouter } from 'react-router-dom'

class Archive extends React.Component{
  constructor(props) {
    super(props)
    const caseId = props.match.params.caseId
    const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
    this.state = {
      caseId,
      sf,
      treeData: [],
      showEvidence: null
    }

  }

  componentDidMount() {
    axios.postString(`${this.state.sf}/caseDic/getList/${this.state.caseId}`, {}, {
      NPprogress: true
    }).then(res => {
      this.setState({
        treeData: res.map((item, index) => {
          const multiple = !item.isSub && item.url && item.url.split(',').length > 1
          return {
            ...item,
            title: item.name,
            key: item.id,
            isLeaf: !item.isSub,
            level: 1,
            index,
            multiple,
            icon: item.isSub ? (<FolderOpenOutlined />) : (multiple ? <CopyOutlined /> : <LinkOutlined />)
          }
        })
      })

    }).catch(() => {})
  }

  onLoadData = (node) => {
    return new Promise((resolve, reject) => {
      if ( node.children ) {
        resolve()
      } else {
        axios.postString(`${this.state.sf}/caseDic/getSub`, {
          caseId: this.state.caseId,
          dicId: node.url
        }).then(res => {
          const icons = {
            image: <FileImageOutlined />,
            audio: <AudioOutlined />,
            video: <VideoCameraOutlined />,
            application: <FilePdfOutlined />
          }
          const mapRes = (list, level) => {
            return list.map((item, index) => {
              const isLeaf = !item.list || !item.list.length
              const type = item.perfix ? item.perfix.split('/')[0] : ''
              return {
                ...item,
                title: item.name || '为定义',
                key: (item.id || item.pid) + '_' + level + '_' + index,
                level,
                isLeaf,
                children: !isLeaf ? mapRes(item.list, level + 1) : undefined,
                icon: isLeaf && !item.list ? ( (type && icons[type]) || (<QuestionCircleOutlined />) ) : (<FolderOpenOutlined />)
              }
            })
          }
          this.setState({
            treeData: this.state.treeData.map(item => {
              return item.key === node.key ? {
                ...item,
                children: mapRes(res, 2)
              } : item
            })
          })
          resolve()
        }).catch(() => {
          reject()
        })
      }
    })

  }
  switchIndex = (e, index1, index2) => {
    e.stopPropagation()
    const switchItem = () => {
      this.setState({
        treeData: this.state.treeData.map((item, index) => {
          if (index === index1) {
            return {
              ...this.state.treeData[index2],
              pxh: item.pxh,
              index: item.index
            }
          } else if (index === index2) {
            return {
              ...this.state.treeData[index1],
              pxh: item.pxh,
              index: item.index
            }
          } else {
            return item
          }
        })
      })
    }
    switchItem()
    axios.postString(`${this.state.sf}/caseDic/px`, {
      id: this.state.treeData[index1].id,
      pxh: this.state.treeData[index2].pxh,
      id1: this.state.treeData[index2].id,
      pxh1: this.state.treeData[index1].pxh
    }).then(() => {}).catch(() => {
      // 如果失败，就撤销
      switchItem()
    })
  }
  render() {
    const token = cookie.get('token')
    return (
      <>
      <CustomPageHeader/>
      <div
        className={'detail-box'}
      >
        <Row
          style={{ flexWrap: 'nowrap' }}
          justify='space-around'
        >
          <Col flex='300px'>
            <Tree
              showIcon
              draggable
              treeData={this.state.treeData}
              loadData={this.onLoadData}
              titleRender={node => {
                return (
                  <>
                    <div
                      className={'tree-title'}
                    >
                      {
                        (!node.isSub && node.level === 1)
                          ?
                          (
                            <Button
                              type={'link'}
                              onClick={e => {
                                if (node.multiple) {
                                  Modal.info({
                                    className: 'modal-width-form',
                                    width: 500,
                                    maskClosable: true,
                                    keyboard: true,
                                    title: '文件列表',
                                    content: node.url.split(',').map((url, index) => {
                                      return (
                                        <Button
                                          key={index}
                                          onClick={() => {
                                            window.open(/^http/.test(url) ? url : `${system.download}${url}?token=${token}`)
                                          }}
                                          type={'link'}
                                          size={'small'}
                                          icon={<LinkOutlined />}
                                        >
                                          {url.split('/').pop()}
                                        </Button>
                                      )
                                    }),
                                    okText: '确认'
                                  })
                                } else {
                                  window.open(/^http/.test(node.url) ? node.url : `${system.download}${node.url}?token=${token}`)
                                }
                              }}
                            >
                              {node.title}
                            </Button>
                          ) : node.title
                      }
                    </div>
                    {
                      node.level === 1
                        ?
                        (
                          <div
                            className={'tree-px'}
                          >
                            {
                              node.index !== 0
                                ?
                                (
                                  <Tooltip title={'上移'} >
                                    <Button
                                      type={'link'}
                                      onClick={e => {
                                        this.switchIndex(e, node.index, node.index - 1)
                                      }}
                                    >
                                      <ArrowUpOutlined />
                                    </Button>
                                  </Tooltip>
                                )
                                : ''
                            }
                            {
                              node.index !== this.state.treeData.length - 1
                                ? (
                                  <Tooltip title={'下移'} >
                                    <Button
                                      type={'link'}
                                      onClick={e => {
                                        this.switchIndex(e, node.index, node.index + 1)
                                      }}
                                    >
                                      <ArrowDownOutlined />
                                    </Button>
                                  </Tooltip>
                                )
                                : ''
                            }
                          </div>
                        )
                        : ''
                    }

                  </>
                )
              }}
              onSelect={(selectedKeys, e) => {
                if (e.node.isSub) {

                } else {
                  if (e.node.level === 1) {
                    // window.open(`${system.download}${e.node.url}?token=${token}`)
                  } else if (e.node.perfix && e.node.content) {
                    if (e.node.perfix === 'application/pdf') {
                      window.open(e.node.content)
                    } else {
                      this.setState({
                        showEvidence: {
                          perfix: e.node.perfix,
                          address: e.node.content,
                          name: e.node.title
                        }
                      })
                    }

                  } else {
                    this.setState({
                      showEvidence: null
                    })
                  }

                }
              }}
            />
          </Col>
          <Col
            flex='auto'

          >
            <div
              style={{'display': 'flex', 'justifyContent': 'center', 'alignItem': 'center'}}
            >
              {
                this.state.showEvidence ? (
                  <Card
                    style={{ width: 360 }}
                    cover={
                      <div
                        style={{ width: 360, height: 360, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}
                      >
                        {
                          getEvidencePreview(this.state.showEvidence, [this.state.showEvidence], 360)
                        }
                      </div>
                    }
                  >
                    {
                      this.state.showEvidence.name
                    }
                  </Card>

                ) : ''
              }
            </div>
          </Col>
        </Row>
      </div>
      </>
    )
  }


}

export default withRouter(Archive)