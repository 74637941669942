import React, { useState } from 'react'
import { Descriptions, Button, Row, Col, Statistic, Steps, Tabs, Spin, Timeline, message, Popconfirm, Space, Form, Table, Avatar, Tooltip, Input } from 'antd'
import { getDetail, getFormItem } from '../../components/factory'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomEvidenceTree from '../../components/CustomEvidenceTree'
import CustomDocumentList from '../../components/CustomDocumentList'
import { transformatTime } from '../../utils/utils'
import * as Icon from '@ant-design/icons'
import Zmage from 'react-zmage'
import cookie from 'js-cookie'
import { FolderOpenOutlined, UserOutlined } from '@ant-design/icons'
import { caseSuccessStep, caseError11, caseError41, caseType, sueType, scopeType, accuserType, occType, sexType, relationType } from '../../config/types'

class NoticeDetail extends React.Component{
  constructor(props) {
    super(props)
    const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
    const userType = cookie.get('userType')
    const isLaywer = userType === 'Lawyer'
    const batchInfo = props.match.params.batchInfo
    const caseId = props.match.params.caseId
    let batchId = null
    if (batchInfo) {
      const [tempBatchId, tempCaseId] = batchInfo.split('_')
      if (caseId == tempCaseId) {
        batchId = tempBatchId
      }
    }
    this.state = {
      caseId,
      sf,
      batchId,
      headerDetail: [],
      otherDetail: [],
      accuserList: [],
      caseLawyerList: [],
      states: [],
      tabs: [
        {
          tabName: '详情',
          isLoad: false,
          onChange: () => {
            axios.postString(`${sf}/case/detail/${this.state.caseId}`).then(res => {
              const states = res.lawCase.state == '11' ? caseError11 : res.lawCase.state == '41' ? caseError41 : caseSuccessStep
              this.setState({
                states: states.map(item => {
                  return {
                    ...item,
                    date: res.lawCase[item.field] ? transformatTime(new Date(res.lawCase[item.field])) : ''
                  }
                }),
                name: res.lawCase.name,
                stateStr: res.lawCase.stateStr,
                amount: res.lawCase.caseDetail.amount,
                stateIndex: states.findIndex(item => item.value == (res.lawCase.state || '10')),
                headerDetail: [
                  {
                    label: '律师事务所',
                    value: res.lawCase.agencyName
                  },
                  {
                    label: '代理律师',
                    value: res.caseLawyerList.map(lawyer => lawyer.userReal.name).join('、')
                  },
                  {
                    label: '类型',
                    value: res.lawCase.type
                  },
                  {
                    label: '当事人',
                    value: res.accuserList.map(acc => acc.name).join('、')
                  },
                  {
                    label: '创建时间',
                    value: transformatTime(new Date(res.lawCase.createTime))
                  }
                ],
                tabs: this.state.tabs.map((tab, index) => {
                  if (index === 0) {
                    return {
                      ...tab,
                      isLoad: true,
                      data: {
                        defaultData: {
                          id: this.state.caseId,
                          typeId: res.lawCase.typeId,
                        },
                        list: [
                          {
                            title: '基础信息',
                            colSpan: 8,
                            list: [
                              {
                                name: 'type',
                                label: '聘请内容',
                                type: 'cascader',
                                options: caseType,
                                value: res.lawCase.type,
                                render: (item) => item.value
                              },
                              {
                                label: '机构案号',
                                name: 'caseNO',
                                value: res.lawCase.caseNo
                              },
                              {
                                name: 'sue',
                                label: '诉讼地位',
                                type: 'select',
                                value: `${res.lawCase.caseDetail.sue}`,
                                options: Object.entries(sueType).map(item => {
                                  return {
                                    value: item[0],
                                    label: item[1]
                                  }
                                }),
                                render: item => sueType[item.value]
                              },
                              {
                                label: '案由',
                                name: 'anyou',
                                value: res.lawCase.caseDetail.anyou
                              },
                              {
                                label: '争议金额',
                                name: 'amount',
                                value: res.lawCase.caseDetail.amount
                              },
                              {
                                name: 'scope',
                                label: '委托授权',
                                type: 'select',
                                value: `${res.lawCase.scope}`,
                                options: Object.entries(scopeType).map(item => {
                                  return {
                                    value: item[0],
                                    label: item[1]
                                  }
                                }),
                                render: item => scopeType[item.value]
                              },
                              {
                                name: 'addStr,province,city,area',
                                label: '案件所在地',
                                type: 'area',
                                value: [res.lawCase.addStr,res.lawCase.province,res.lawCase.city,res.lawCase.area].toString(),
                                render: item => item.value.split(',')[0]
                              },
                              {
                                label: '所在街道',
                                name: 'address',
                                value: res.lawCase.address
                              },
                              {
                                name: 'hearId',
                                type: 'customSelect',
                                label: '审理机关',
                                getLabel: (item = {}) => {
                                  return item.name
                                },
                                getValues: (value) => {
                                  const item = JSON.parse(value)
                                  return item
                                },
                                api: 'hear/query',
                                searchName: 'name',
                                value: JSON.stringify({id:res.lawCase.hear ,name: res.lawCase.hearStr}),
                                render: item => JSON.parse(item.value).name
                              },
                            ],
                          },
                          {
                            title: '案件详情',
                            colSpan: 24,
                            noLabel: true,
                            list: [
                              {
                                name: 'des',
                                type: 'textArea',
                                value: res.lawCase.caseDetail.des
                              }
                            ]
                          },
                          {
                            title: '诉求',
                            colSpan: 24,
                            noLabel: true,
                            list: [
                              {
                                name: 'pursue',
                                ype: 'textArea',
                                value: res.lawCase.caseDetail.pursue
                              }
                            ]
                          }
                          // {
                          //   title: '当事人信息',
                          //   list: res.accuserList.map(item => {
                          //     return [
                          //       {
                          //         label: '姓名',
                          //         value: item.name
                          //       },
                          //       {
                          //         label: '证件号',
                          //         value: item.idcard
                          //       },
                          //       {
                          //         label: '联系方式',
                          //         value: item.tel
                          //       },
                          //       {
                          //         label: '地址',
                          //         value: (item.addStr || '') + ' ' + (item.address || '')
                          //       }
                          //     ]
                          //   })
                          // },
                          // {
                          //   title: '当事人家属',
                          //   list: res.clients.map(item => {
                          //     return [
                          //       {
                          //         label: '姓名',
                          //         value: item.name
                          //       },
                          //       {
                          //         label: '年龄',
                          //         value: item.age
                          //       },
                          //       {
                          //         label: '性别',
                          //         value: sexType[item.sex || 0]
                          //       },
                          //       {
                          //         label: '与当事人关系',
                          //         value: item.relationName
                          //       },
                          //       {
                          //         label: '工作',
                          //         value: item.workName
                          //       },
                          //       {
                          //         label: '工作单位',
                          //         value: item.danwei
                          //       },
                          //       {
                          //         label: '收入',
                          //         value: item.income || '无'
                          //       },
                          //     ]
                          //   })
                          // },
                          // {
                          //   title: '对方当事人信息',
                          //   extra: this.state.batchId ? [
                          //     <Popconfirm
                          //       title={`同步对方当事人到群体案件其他子案件?`}
                          //       icon={<Icon.QuestionCircleOutlined twoToneColor='blue'/>}
                          //       onConfirm={() => {
                          //         axios.postString(`batch/tb/duifang/${this.state.batchId}`).then(() => {
                          //           message.success('群体案件对方当事人同步成功')
                          //         }).catch(() => {})
                          //       }}
                          //     >
                          //       <Button
                          //         type="primary"
                          //         icon={<Icon.SyncOutlined />}
                          //       >
                          //         同步基本信息
                          //       </Button>
                          //     </Popconfirm>
                          //   ] : [],
                          //   list: res.duifangs.map(item => {
                          //     return [
                          //       {
                          //         label: '姓名/公司',
                          //         value: item.name
                          //       },
                          //       {
                          //         label: '证件号',
                          //         value: item.idcard
                          //       },
                          //       {
                          //         label: '联系方式',
                          //         value: item.tel
                          //       },
                          //       {
                          //         label: '地址',
                          //         value: (item.addStr || '') + ' ' + (item.address || '')
                          //       }
                          //     ]
                          //   })
                          // },
                          // {
                          //   title: '委托人信息',
                          //   list: res.entrusts.map(item => {
                          //     return [
                          //       {
                          //         label: '姓名/公司',
                          //         value: item.name
                          //       },
                          //       {
                          //         label: '证件号',
                          //         value: item.idcard
                          //       },
                          //       {
                          //         label: '联系方式',
                          //         value: item.tel
                          //       },
                          //       {
                          //         label: '地址',
                          //         value: (item.addStr || '') + ' ' + (item.address || '')
                          //       }
                          //     ]
                          //   })
                          // }
                        ]
                      }
                    }
                  } else if (index === 1) {
                    return {
                      ...tab,
                      isLoad: true,
                      data: {
                        list: [
                          {
                            label: '我方当事人',
                            params: [
                              {
                                type: (this.state.batchId || isLaywer) ? 'array' : 'text',
                                label: '自然人',
                                options: [
                                  {
                                    name: 'id',
                                    type: 'hidden'
                                  },
                                  {
                                    name: 'caseId',
                                    type: 'hidden'
                                  },
                                  {
                                    name: 'name',
                                    label: '姓名'
                                  },
                                  {
                                    name: 'sex',
                                    label: '性别',
                                    type: 'radio',
                                    options: Object.entries(sexType).map(item => {
                                      return {
                                        value: item[0],
                                        label: item[1]
                                      }
                                    }),
                                    render: value => value ? sexType[value] : ''
                                  },
                                  {
                                    name: 'idPic',
                                    label: '证件照',
                                    type: 'uploadImg',
                                    limit: 2,
                                    folderPath: '/person/cert/',
                                    render: images => (images ? images.split(',') : []).map((src, index) => {
                                      return (
                                        <Zmage
                                          key={index}
                                          width={70}
                                          height={70}
                                          style={{objectFit: 'contain', margin: '10px'}}
                                          src={src}
                                          defaultPage={index}
                                          set={images.split(',').map(src => {
                                            return {
                                              src
                                            }
                                          })}
                                        />
                                      )
                                    }),
                                  },
                                  {
                                    name: 'type',
                                    label: '诉讼地位',
                                    type: 'select',
                                    options: Object.entries(accuserType).map(item => {
                                      return {
                                        value: item[0],
                                        label: item[1]
                                      }
                                    }),
                                    render: value => value ? accuserType[value] : ''
                                  },
                                  {
                                    name: 'tel',
                                    label: '电话',
                                    render: value => (
                                      <Tooltip  title={value} placement="top">
                                        {value ? value.replace(/^(\d{2})\d+$/, '$1***') : ''}
                                      </Tooltip>
                                    )
                                  },
                                  {
                                    name: 'idcard',
                                    label: '身份证',
                                    render: value => (
                                      <Tooltip  title={value} placement="top">
                                        {value ? value.replace(/^(\d{2})\d+$/, '$1***') : ''}
                                      </Tooltip>
                                    )
                                  },
                                  // {
                                  //   name: 'orgCode',
                                  //   label: '机构编码'
                                  // },
                                  // {
                                  //   name: 'companyName',
                                  //   label: '企业名称'
                                  // },
                                  {
                                    name: 'addStr,province,city,area',
                                    label: '省市区',
                                    type: 'area'
                                  },
                                  {
                                    name: 'address',
                                    label: '地址',
                                    render: value => (
                                      <Tooltip  title={value} placement="top">
                                        {value ? value.replace(/^(.{3}).+$/, '$1...') : ''}
                                      </Tooltip>
                                    )
                                  },
                                  {
                                    name: 'entrustName',
                                    label: '委托人'
                                  },
                                  {
                                    name: 'entrustIdcard',
                                    label: '委托书证件',
                                    render: value => (
                                      <Tooltip  title={value} placement="top">
                                        {value ? value.replace(/^(\d{2})\d+$/, '$1***') : ''}
                                      </Tooltip>
                                    )
                                  },
                                  !sf ? {
                                    type: 'hidden'
                                  } : {
                                    name: 'clients',
                                    label: '家庭成员',
                                    type: 'array',
                                    options: [
                                      {
                                        name: 'name',
                                        label: '姓名'
                                      },
                                      {
                                        name: 'sex',
                                        type: 'radio',
                                        label: '性别',
                                        options: Object.entries(sexType).map(item => {
                                          return {
                                            value: item[0],
                                            label: item[1]
                                          }
                                        }),
                                        render: type => sexType[type]
                                      },
                                      {
                                        name: 'age',
                                        label: '年龄'
                                      },
                                      {
                                        name: 'relation',
                                        label: '家庭关系',
                                        type: 'select',
                                        options: Object.entries(relationType).map(item => {
                                          return {
                                            value: item[0],
                                            label: item[1]
                                          }
                                        }),
                                        render: type => relationType[type]
                                      },
                                      {
                                        name: 'work',
                                        type: 'select',
                                        label: '工作',
                                        options: Object.entries(occType).map(item => {
                                          return {
                                            value: item[0],
                                            label: item[1]
                                          }
                                        }),
                                        render: value => value ? occType[value] : ''
                                      },
                                      {
                                        name: 'income',
                                        label: '收入'
                                      }
                                    ],
                                    render: clients => (
                                      <Avatar.Group>
                                        {
                                          clients.map((client, index) => (
                                            <Tooltip  title={relationType[client.relation]} key={index} placement="top">
                                              <Avatar style={{ backgroundColor: client.sex === '1' ? '#87d068' : '#f56a00' }} icon={<UserOutlined />} />
                                            </Tooltip>
                                          ))
                                        }
                                      </Avatar.Group>
                                    )
                                  },
                                  // {
                                  //   name: 'occ',
                                  //   type: 'select',
                                  //   label: '职业',
                                  //   options: Object.entries(occType).map(item => {
                                  //     return {
                                  //       value: item[0],
                                  //       label: item[1]
                                  //     }
                                  //   }),
                                  //   render: value => value ? occType[value] : ''
                                  // },
                                  // {
                                  //   name: 'income',
                                  //   label: '收入'
                                  // }
                                ],
                                initialValues: {
                                  caseId: this.state.caseId
                                },
                                value: res.accuserList.map(item => {
                                  return {
                                    ...item,
                                    sex: item.sex || item.sex === 0 ? (item.sex + '') : null,
                                    type: item.type || item.type === 0 ? (item.type + '') : null,
                                    entrustName: item.caseEntrust.userReal,
                                    entrustIdcard: item.caseEntrust.entrustIdcard,
                                    caseId: this.state.caseId,
                                    clients: (item.clients || []).map(client => {
                                      return {
                                        ...client,
                                        sex: client.sex || client.sex === 0 ? (client.sex + '') : null,
                                        work: client.work || client.work === 0 ? (client.work + '') : null
                                      }
                                    })
                                  }
                                }),
                                handleOk: values => new Promise((resolve, reject) => {
                                  axios.post(`${sf}/case/accuser/edit`, values).then(id => {
                                    message.success(`我方当事人${values.id ? '修改' : '新增'}成功`)
                                    resolve({id})
                                  }).catch(() => {
                                    reject()
                                  })
                                }),
                                customDelete: {
                                  label: '删除',
                                  type: 'delete',
                                  api: `${sf}/case/accuser/del/:id`
                                },
                                render: item => (
                                  item.value.length ? (
                                    <Table
                                      rowKey={'index'}
                                      dataSource={item.value.map((item, index) => {
                                        return {
                                          ...item,
                                          index
                                        }
                                      })}
                                      columns={item.options.filter(item => item.name).map(item => {
                                        return {
                                          render: item.render,
                                          title: item.label,
                                          dataIndex: item.name.split(',')[0]
                                        }
                                      })}
                                      bordered
                                      pagination={false}
                                      size={'small'}
                                    />
                                  ) : ''
                                )
                              },
                              sf ?
                                {
                                  type: (this.state.batchId || isLaywer) ? 'array' : 'text',
                                  label: '企业',
                                  options: [
                                    {
                                      name: 'id',
                                      type: 'hidden'
                                    },
                                    {
                                      name: 'caseId',
                                      type: 'hidden'
                                    },
                                    {
                                      name: 'companyName',
                                      label: '企业名称'
                                    },
                                    {
                                      name: 'name',
                                      label: '企业法人'
                                    },
                                    // {
                                    //   name: 'sex',
                                    //   label: '法人性别',
                                    //   type: 'radio',
                                    //   options: Object.entries(sexType).map(item => {
                                    //     return {
                                    //       value: item[0],
                                    //       label: item[1]
                                    //     }
                                    //   }),
                                    //   render: value => value ? sexType[value] : ''
                                    // },
                                    {
                                      name: 'type',
                                      label: '诉讼地位',
                                      type: 'select',
                                      options: Object.entries(accuserType).map(item => {
                                        return {
                                          value: item[0],
                                          label: item[1]
                                        }
                                      }),
                                      render: value => value ? accuserType[value] : ''
                                    },
                                    {
                                      name: 'tel',
                                      label: '法人电话'
                                    },
                                    // {
                                    //   name: 'idcard',
                                    //   label: '法人身份证'
                                    // },
                                    {
                                      name: 'orgCode',
                                      label: '机构编码'
                                    },

                                    {
                                      name: 'addStr,province,city,area',
                                      label: '省市区',
                                      type: 'area'
                                    },
                                    {
                                      name: 'address',
                                      label: '地址'
                                    }
                                  ],
                                  initialValues: {
                                    caseId: this.state.caseId
                                  },
                                  value: res.accuserList.map(item => {
                                    return {
                                      ...item,
                                      sex: item.sex || item.sex === 0 ? (item.sex + '') : null,
                                      type: item.type || item.type === 0 ? (item.type + '') : null,
                                      entrustName: item.caseEntrust.userReal,
                                      entrustIdcard: item.caseEntrust.entrustIdcard,
                                      caseId: this.state.caseId,
                                      clients: (item.clients || []).map(client => {
                                        return {
                                          ...client,
                                          sex: client.sex || client.sex === 0 ? (client.sex + '') : null,
                                          work: client.work || client.work === 0 ? (client.work + '') : null
                                        }
                                      })
                                    }
                                  }),
                                  handleOk: values => new Promise((resolve, reject) => {
                                    axios.post(`${sf}/case/accuser/edit`, values).then(id => {
                                      message.success(`我方当事人${values.id ? '修改' : '新增'}成功`)
                                      resolve({id})
                                    }).catch(() => {
                                      reject()
                                    })
                                  }),
                                  customDelete: {
                                    label: '删除',
                                    type: 'delete',
                                    api: `${sf}/case/accuser/del/:id`
                                  },
                                  render: item => (
                                    item.value.length ? (
                                      <Table
                                        rowKey={'index'}
                                        dataSource={item.value.map((item, index) => {
                                          return {
                                            ...item,
                                            index
                                          }
                                        })}
                                        columns={item.options.filter(item => item.name).map(item => {
                                          return {
                                            render: item.render,
                                            title: item.label,
                                            dataIndex: item.name.split(',')[0]
                                          }
                                        })}
                                        bordered
                                        pagination={false}
                                        size={'small'}
                                      />
                                    ) : ''
                                  )
                                } : {
                                  type: 'hidden'
                                }
                            ]
                          },
                          {
                            label: '对方当事人',
                            params: [
                              {
                                type: (this.state.batchId || isLaywer) ? 'array' : 'text',
                                label: '自然人',
                                options: [
                                  {
                                    name: 'id',
                                    type: 'hidden'
                                  },
                                  {
                                    name: 'caseId',
                                    type: 'hidden',
                                  },
                                  {
                                    name: 'name',
                                    label: '姓名'
                                  },
                                  {
                                    name: 'sex',
                                    label: '性别',
                                    type: 'radio',
                                    options: Object.entries(sexType).map(item => {
                                      return {
                                        value: item[0],
                                        label: item[1]
                                      }
                                    }),
                                    render: value => value ? sexType[value] : ''
                                  },
                                  {
                                    name: 'type',
                                    label: '诉讼地位',
                                    type: 'select',
                                    options: Object.entries(accuserType).map(item => {
                                      return {
                                        value: item[0],
                                        label: item[1]
                                      }
                                    }),
                                    render: value => value ? accuserType[value] : ''
                                  },
                                  {
                                    name: 'tel',
                                    label: '电话'
                                  },
                                  {
                                    name: 'idcard',
                                    label: '身份证'
                                  },
                                  // {
                                  //   name: 'orgCode',
                                  //   label: '机构编码'
                                  // },
                                  // {
                                  //   name: 'companyName',
                                  //   label: '企业名称'
                                  // },
                                  {
                                    name: 'addStr,province,city,area',
                                    label: '省市区',
                                    type: 'area'
                                  },
                                  {
                                    name: 'address',
                                    label: '地址'
                                  }
                                ],
                                initialValues: {
                                  caseId: this.state.caseId
                                },
                                value: res.duifangs.filter(item => !item.companyName).map(item => {
                                  return {
                                    ...item,
                                    sex: item.sex || item.sex === 0 ? (item.sex + '') : null,
                                    type: item.type || item.type === 0 ? (item.type + '') : null,
                                    caseId: this.state.caseId,
                                  }
                                }),
                                handleOk: values => new Promise((resolve, reject) => {
                                  axios.post(`${sf}/case/duifang/edit`, values).then(id => {
                                    message.success(`对方当事人${values.id ? '修改' : '新增'}成功`)
                                    resolve({id})
                                  }).catch(() => {
                                    reject()
                                  })
                                }),
                                customDelete: {
                                  label: '删除',
                                  type: 'delete',
                                  api: `${sf}/case/duifang/del/:id`
                                },
                                render: item => (
                                  item.value.length ? (
                                    <Table
                                      rowKey={'index'}
                                      dataSource={item.value.map((item, index) => {
                                        return {
                                          ...item,
                                          index
                                        }
                                      })}
                                      columns={[{
                                        title: '序号',
                                        align: 'center',
                                        render: (row1, row2, index) => {
                                          return index + 1
                                        }
                                      }].concat(item.options.filter(item => item.name).map(item => {
                                        return {
                                          render: item.render,
                                          title: item.label,
                                          dataIndex: item.name.split(',')[0]
                                        }
                                      }))}
                                      bordered
                                      pagination={false}
                                      size={'small'}
                                    />
                                  ) : ''
                                )
                              },
                              {
                                type: (this.state.batchId || isLaywer) ? 'array' : 'text',
                                label: '企业',
                                options: [
                                  {
                                    name: 'id',
                                    type: 'hidden'
                                  },
                                  {
                                    name: 'caseId',
                                    type: 'hidden'
                                  },
                                  {
                                    name: 'companyName',
                                    label: '企业名称'
                                  },
                                  {
                                    name: 'name',
                                    label: '企业法人'
                                  },
                                  // {
                                  //   name: 'sex',
                                  //   label: '法人性别',
                                  //   type: 'radio',
                                  //   options: Object.entries(sexType).map(item => {
                                  //     return {
                                  //       value: item[0],
                                  //       label: item[1]
                                  //     }
                                  //   }),
                                  //   render: value => value ? sexType[value] : ''
                                  // },
                                  {
                                    name: 'type',
                                    label: '诉讼地位',
                                    type: 'select',
                                    options: Object.entries(accuserType).map(item => {
                                      return {
                                        value: item[0],
                                        label: item[1]
                                      }
                                    }),
                                    render: value => value ? accuserType[value] : ''
                                  },
                                  {
                                    name: 'tel',
                                    label: '法人电话'
                                  },
                                  // {
                                  //   name: 'idcard',
                                  //   label: '法人身份证'
                                  // },
                                  {
                                    name: 'orgCode',
                                    label: '机构编码'
                                  },

                                  {
                                    name: 'addStr,province,city,area',
                                    label: '省市区',
                                    type: 'area'
                                  },
                                  {
                                    name: 'address',
                                    label: '地址'
                                  }
                                ],
                                initialValues: {
                                  caseId: this.state.caseId
                                },
                                value: res.duifangs.filter(item => item.companyName).map(item => {
                                  return {
                                    ...item,
                                    sex: item.sex || item.sex === 0 ? (item.sex + '') : null,
                                    type: item.type || item.type === 0 ? (item.type + '') : null,
                                    caseId: this.state.caseId,
                                  }
                                }),
                                handleOk: values => new Promise((resolve, reject) => {
                                  axios.postString(`${sf}/case/duifang/edit`, values).then(id => {
                                    message.success(`对方当事人${values.id ? '修改' : '新增'}成功`)
                                    resolve({id})
                                  }).catch(() => {
                                    reject()
                                  })
                                }),
                                customDelete: {
                                  label: '删除',
                                  type: 'delete',
                                  api: `${sf}/case/duifang/del/:id`
                                },
                                render: item => (
                                  item.value.length ? (
                                    <Table
                                      rowKey={'index'}
                                      dataSource={item.value.map((item, index) => {
                                        return {
                                          ...item,
                                          index
                                        }
                                      })}
                                      columns={[{
                                        title: '序号',
                                        align: 'center',
                                        render: (row1, row2, index) => {
                                          return index + 1
                                        }
                                      }].concat(item.options.filter(item => item.name).map(item => {
                                        return {
                                          render: item.render,
                                          title: item.label,
                                          dataIndex: item.name.split(',')[0]
                                        }
                                      }))}
                                      bordered
                                      pagination={false}
                                      size={'small'}
                                    />
                                  ) : ''
                                )
                              },
                            ]
                          }
                        ]
                      }
                    }
                  } else {
                    return tab
                  }
                })
              })
            }).catch(() => {
              // props.history.goBack()
            })
          },
          render: data => {
            const _this = this
            const Formlayout = () => {
              const [isEdit, setIsEdit] = useState(false)
              const [isLoading, setLoading] = useState(false)
              var formRef = React.createRef()
              const initialValues = isEdit ? Object.assign.apply(null, data.list.map(item => {
                return Object.assign.apply(null, item.list.map(item => {
                  if (item.type === 'area') {
                    return Object.assign.apply(null, item.name.split(',').map((name, i) => {
                      return {
                        [name]: item.value ? item.value.split(',')[i] : ''
                      }
                    }))
                  } else {
                    return {
                      [item.name]: item.value
                    }
                  }
                }))
              })) : {}
              return (
                <Form
                  ref={formRef}
                  onFinish={values => {
                    setLoading(true)
                    axios.post(`${sf}/case/add`, {
                      ...values,
                      hearId: values['hearId'].id,
                      ...data.defaultData
                    }).then(res => {
                      message.success('保存成功！')
                      data.list.forEach(item => {
                        item.list.forEach(item => {
                          if (item.type === 'area') {
                            item.value = item.name.split(',').map(name => values[name]).toString()
                          } else if (item.name === 'hearId') {
                            item.value = JSON.stringify(values[item.name])
                          } else {
                            item.value = values[item.name]
                          }
                        })
                      })
                      setIsEdit(false)
                      setLoading(false)
                    }).catch(() => {
                      setLoading(true)
                    })
                  }}
                  key={new Date().getTime()}
                  initialValues={{
                    ...initialValues,
                    id: _this.state.caseId
                  }}
                >

                    <Form.Item>
                      <div className="align-right">
                        <Space size={10}>
                          {
                            (_this.state.batchId || isLaywer) ? (
                              isEdit ? (
                                <Button
                                  type='primary'
                                  htmlType="submit"
                                  loading={isLoading}
                                >
                                  保存
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setIsEdit(true)
                                  }}
                                >
                                  编辑
                                </Button>
                              )
                            ) : ''
                          }
                          {
                            _this.state.batchId ?
                              <Popconfirm
                                title={`同步案件详情到群体案件其他子案件?`}
                                icon={<Icon.QuestionCircleOutlined twoToneColor='blue'/>}
                                onConfirm={() => {
                                  axios.postString(`batch/tb/detail/${_this.state.batchId}`).then(() => {
                                    message.success('群体案件详情同步成功')
                                  }).catch(() => {
                                  })
                                }}
                              >
                                <Button
                                  type="primary"
                                  icon={<Icon.SyncOutlined/>}
                                >
                                  同步基本信息
                                </Button>

                              </Popconfirm> : ''
                          }
                        </Space>
                      </div>
                    </Form.Item>

                  {
                    data.list.map((category, index) => {
                      return (
                        <div key={index}>
                          <Form.Item>
                            <h3 style={{"fontWeight":"bold"}}>{category.title}</h3>
                          </Form.Item>
                          <Row gutter={[4, 4]}>
                          {
                            category.list.map((item,cIndex) => {
                              const type = isEdit ? item.type : 'text'
                              return (
                                <Col span={category.colSpan} key={cIndex}>
                                  <Form.Item
                                    wrapperCol = {{ span: 18 }}
                                    name={item.name && type !== 'text' ? item.name.split(',')[0] : undefined}
                                    label={category.noLabel ? null : (<span style={{'color': '#777'}}>{item.label}</span>)}
                                    rules={item.rules || (type === 'text' ? [] : [{required: true}])}
                                    {
                                      ... (type === 'switch' ? { valuePropName: 'checked'} : {})
                                    }
                                    labelCol={{span: 6}}
                                  >
                                    {
                                      getFormItem({
                                        ...item,
                                        type
                                      }, {
                                        formRef
                                      })
                                    }
                                  </Form.Item>
                                </Col>
                              )
                            })
                          }
                          </Row>
                        </div>
                      )
                    })
                  }
                </Form>
              )
            }
            return <Formlayout />
          },
          data: {}
        },
        {
          tabName: '当事人',
          isLoad: false,
          render: data => {
            const _this = this
            const Formlayout = () => {
              const formRef = React.createRef()
              const initialValues = Object.assign.apply(null, data.list.map(item => {
                return {
                  [item.name]: item.value
                }
              }))
              return (
                <Form
                  ref={formRef}
                  initialValues={initialValues}
                >
                  {
                    _this.state.batchId ?
                    <Form.Item>
                      <div className="align-right">
                        <Space size={10}>
                          <Popconfirm
                            title={`同步对方当事人到群体案件其他子案件?`}
                            icon={<Icon.QuestionCircleOutlined twoToneColor='blue'/>}
                            onConfirm={() => {
                              axios.postString(`batch/tb/duifang/${this.state.batchId}`).then(() => {
                                message.success('群体案件对方当事人同步成功')
                              }).catch(() => {})
                            }}
                          >
                            <Button
                              type="primary"
                              icon={<Icon.SyncOutlined />}
                            >
                              同步对方当事人
                            </Button>
                          </Popconfirm>
                        </Space>
                      </div>
                    </Form.Item> : ''
                  }
                  {
                    data.list.map((item, index) => {
                      return (
                        <div key={index}>
                          <Form.Item>
                            <h3>{item.label}</h3>
                          </Form.Item>
                          {
                            item.params.filter(item => item.type !== 'hidden').map((item, index) => {
                              return (
                                <Form.Item
                                  key={index}
                                  name={item.name}
                                  label={null}
                                  rules={[{required: true}]}
                                >
                                  {
                                    getFormItem(item, {
                                      formRef
                                    })
                                  }
                                </Form.Item>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </Form>
              )
            }
            return <Formlayout />
          },
          data: {}
        },
        {
          tabName: '证据',
          isLoad: false,
          onChange: activeIndex => {
            axios.postString(`${sf}/caseEvidence/evidenceList/${this.state.caseId}`).then(res => {
              this.setState({
                tabs: this.state.tabs.map((tab, index) => {
                  return activeIndex == index
                    ? {
                      ...tab,
                      isLoad: true,
                      data: res
                    } : tab
                })
              })
            }).catch(() => {})
          },
          render: data => {
            let tempData = {}
            const iconMap = [
              <UserOutlined />,
              <FolderOpenOutlined />,
              <FolderOpenOutlined />
            ]
            const dataMap = (pid, index) => {
              return (tempData[pid] || []).map(item => {
                return {
                  title: item.name,
                  pid,
                  key: item.id,
                  content: item.content,
                  icon: iconMap[index],
                  level: index + 1,
                  children: tempData[item.id] && index < 1 ? dataMap(item.id, index+1) : []
                }
              })
            }
            data.evidenceTypeList.forEach(item => {
              tempData[item.pid] ? tempData[item.pid].push(item) : (tempData[item.pid] = [item])
            })
            let caseEvidences = {}
            data.caseEvidences.forEach(item => {
              caseEvidences[item.type] ? caseEvidences[item.type].push(item) : caseEvidences[item.type] = [item]
            })
            const treeData = dataMap(0, 0)
            return (
              <CustomEvidenceTree
                treeData={treeData}
                caseEvidences={caseEvidences}
                caseId={this.state.caseId}
                batchId={this.state.batchId}
              />
            )
          },
          data: {}
        },
        {
          tabName: '文书',
          isLoad: false,
          onChange: activeIndex => {
            this.setState({
              tabs: this.state.tabs.map((tab, index) => {
                return activeIndex == index
                  ? {
                    ...tab,
                    isLoad: true,
                    data: true
                  } : tab
              })
            })
          },
          render: () => {
            return (
              <CustomDocumentList
                caseId={this.state.caseId}
                batchId={this.state.batchId}
              />
            )
          },
          data: {}
        },
        {
          tabName: '动态',
          isLoad: false,
          onChange: activeIndex => {
            axios.postString(`${sf}/case/detailBase/${this.state.caseId}`).then(res => {
              this.setState({
                tabs: this.state.tabs.map((tab, index) => {
                  return activeIndex == index
                    ? {
                      ...tab,
                      isLoad: true,
                      data: res
                    } : tab
                })
              })
            }).catch(() => {})
          },
          render: data => {
            return (
              <Timeline>
                {
                  data.caseTrends.map((item, index) => {
                    return (
                      <Timeline.Item
                        key={index}
                        className={'align-left'}
                      >
                        {item.time ? transformatTime(new Date(item.time)) : ''}
                        <br/>
                        {item.content}
                      </Timeline.Item>
                    )
                  })
                }
              </Timeline>
            )
          },
          data: {}
        }
      ]
    }
  }

  saveBaseInfo = values => {
    this.props.formData.forEach(item => {
      if (item.valueFormat) {
        values[item.name] = item.valueFormat(values[item.name])
      } else if (item.type === 'date') {
        values[item.name] = values[item.name].format('YYYY-MM-DD')
      } else if (item.type === 'switch') {
        values[item.name] = values[item.name] ? '1' : '0'
      }
    })
    // Object.entries(values).forEach(item => {
    //   if (Object.prototype.toString.call(item[1]).replace(/\[object\s+([^\]]+)\]/,"$1").toLowerCase() === 'object') {
    //     if (item[1]._isAMomentObject) {
    //       values[item[0]] = item[1].format('YYYY-MM-DD')
    //     }
    //   } else if (typeof item[1] === 'boolean') {
    //     values[item[0]] = item[1] ? '1' : '0'
    //   }
    // })
    // console.log(values)
    // return
    axios[this.props.method || 'postString'](this.props.action, {
      ...this.props.defaultData,
      ...values
    }).then(res => {
      message.success('操作成功！')
      this.props.onHandleOK && this.props.onHandleOK(values, res)
      !this.props.notBack && setTimeout(() => {
        this.props.history.goBack()
      }, 1000)
    }).catch(() => {})
  }

  componentDidMount() {
    this.state.caseId ? this.state.tabs[0].onChange(0) : this.props.history.goBack()
  }


  render() {

    return (
      <>
      <CustomPageHeader
        title={'案件名称：' + this.state.name}
        extra={
          this.state.batchId ? [
            <Popconfirm
              title={`同步基本信息到群体案件其他子案件?`}
              icon={<Icon.QuestionCircleOutlined twoToneColor='blue'/>}
              onConfirm={() => {
                axios.postString(`batch/tb/info/${this.state.batchId}`).then(() => {
                  message.success('群体案件基本信息同步成功')
                }).catch(() => {})
              }}
            >
              <Button
                type="primary"
                icon={<Icon.SyncOutlined />}
              >
                同步基本信息
              </Button>
            </Popconfirm>
          ] : []
        }
        content={
          <Row justify='space-around'>
            <Col flex='auto'>
              <Descriptions
                size="small"
                style={{'tableLayout': 'auto'}}
                column={3}
              >
                {
                  this.state.headerDetail.map((item, index) => {
                    return (
                      <Descriptions.Item
                        key={index}
                        label={item.label}
                        span={item.span || 1}
                      >
                        {
                          getDetail(item)
                        }
                      </Descriptions.Item>
                    )
                  })
                }
              </Descriptions>
            </Col>
            <Col flex={'300px'}>
              <div
                style={{
                  display: 'flex',
                  width: 'max-content',
                  justifyContent: 'flex-end',
                }}
              >
                {/*<Statistic*/}
                {/*title="状态"*/}
                {/*value={details.stateStr}*/}
                {/*style={{*/}
                {/*marginRight: 32,*/}
                {/*}}*/}
                {/*/>*/}
                <Statistic
                  title="涉案金额"
                  prefix="¥"
                  value={this.state.amount || 0}
                />
              </div>
            </Col>
          </Row>
        }
        // extra={[
        //   <Button key="1" type="primary">
        //     审核
        //   </Button>,
        // ]}
      />
      <div className='detail-box'>
        <Steps current={this.state.stateIndex} progressDot >
          {
            this.state.states.map((item, index) => {
              return (
                <Steps.Step
                  title={item.label}
                  description={item.date}
                  key={index}
                />
              )
            })
          }
        </Steps>
      </div>
      <div className='detail-box mt20'>
        {/*<Descriptions*/}
        {/*bordered*/}
        {/*column={2}*/}
        {/*>*/}
        {/*{*/}
        {/*details.map((item, index) => {*/}
        {/*return (*/}
        {/*<Descriptions.Item*/}
        {/*key={index}*/}
        {/*label={item.label}*/}
        {/*span={item.span || 1}*/}
        {/*>*/}
        {/*{*/}
        {/*getDetail(item)*/}
        {/*}*/}
        {/*</Descriptions.Item>*/}
        {/*)*/}
        {/*})*/}
        {/*}*/}
        {/*</Descriptions>*/}
        <div>
          <Tabs
            defaultActiveKey="0"
            onChange={activeKey => {
              !this.state.tabs[activeKey].isLoad && this.state.tabs[activeKey].onChange && this.state.tabs[activeKey].onChange(activeKey)
            }}
          >
            {
              this.state.tabs.map((tab, index) => {
                return (
                  <Tabs.TabPane tab={tab.tabName} key={index}>
                    {
                      tab.isLoad
                        ? tab.render(tab.data)
                        : <Spin />
                    }
                  </Tabs.TabPane>
                )
              })
            }
            {/*{*/}
              {/*details.otherDetail.map((item, index) => {*/}
                {/*return item.list.length*/}
                  {/*? (*/}
                    {/*<Tabs.TabPane tab={item.tabName} key={index}>*/}
                      {/*{*/}
                        {/*item.list.map((item, index) => {*/}
                          {/*return (*/}
                            {/*<Descriptions*/}
                              {/*bordered*/}
                              {/*size="small"*/}
                              {/*key={index}*/}
                              {/*column={3}*/}
                              {/*style={{'marginBottom': '20px'}}*/}
                            {/*>*/}
                              {/*{*/}
                                {/*item.map((item, index) => {*/}
                                  {/*return (*/}
                                    {/*<Descriptions.Item*/}
                                      {/*key={index}*/}
                                      {/*label={item.label}*/}
                                      {/*span={item.span || 1}*/}
                                    {/*>*/}
                                      {/*{*/}
                                        {/*getDetail(item)*/}
                                      {/*}*/}
                                    {/*</Descriptions.Item>*/}
                                  {/*)*/}
                                {/*})*/}
                              {/*}*/}
                            {/*</Descriptions>*/}
                          {/*)*/}
                        {/*})*/}
                      {/*}*/}
                    {/*</Tabs.TabPane>*/}
                  {/*)*/}
                  {/*: ''*/}
              {/*})*/}
            {/*}*/}
          </Tabs>
          {/*<div className='align-center'>*/}
            {/*<Button*/}
              {/*onClick={() => {*/}
                {/*this.props.history.goBack()*/}
              {/*}}*/}
            {/*>*/}
              {/*返回*/}
            {/*</Button>*/}
          {/*</div>*/}
        </div>
      </div>
      </>
    )
  }

}
export default NoticeDetail
