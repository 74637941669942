import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import { specialityTypes } from '../../config/types'
import { transform } from '../../utils/utils'
import cookie from 'js-cookie'

function LawyerSet(props) {
  const formData = [
    {
      name: 'speciality',
      label: '专长类型',
      type: 'select',
      mode: 'multiple',
      valueFormat: (value) => value.toString(),
      options: Object.entries(specialityTypes).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      name: 'startDate',
      label: '执业开始时间',
      type: 'date'
    },
    {
      name: 'agencyName',
      label: '执业律所'
    },
    {
      name: 'occNo',
      label: '执业证号'
    },
    {
      name: 'occPic',
      label: '职业证书图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    },
    {
      name: 'occAgePic',
      label: '职业年检图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    },
    {
      name: 'memo',
      type: 'textArea',
      label: '个人简介'
    },
    // {
    //   name: 'isPub',
    //   label: '已实名认证',
    //   type: 'text',
    //   render: initialValues => initialValues['isReal'] === 1 ? '是' : '否'
    // }
  ]
  const  { uid }  = JSON.parse(cookie.get('user'))
  console.log(JSON.parse(cookie.get('user')))
  const [initialValues, setInitialValues] = useState({})
  useEffect(() => {
    axios.postString(`lawyer/detail/${uid}`).then(res => {
      const getValue = (item) => {
        switch (item.name) {
          case 'agencyName':
            return res.agency ? res.agency.agencyName : ''
          case 'speciality':
            return [...new Set(res.speciality.split(',').filter(item => item))]
          case 'startDate':
            return transform(new Date(res.userReal.startDate), 'YYYY-MM-DD')
          default:
            return res[item.name]
        }
      }
      setInitialValues({
        uid: res.uid,
        ...Object.assign.apply(this, formData.map(item => {
          return {
            [item.name]: getValue(item)
          }
        }))
      })
    }).catch(() => {
      // props.history.goBack()
    })
  }, [])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={initialValues}
        keyRow={'uid'}
        action='lawyer/lawerAdd'
        notBack={true}
      />
    </>
  )
}

export default withRouter(LawyerSet)
