import React from 'react'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'


export const isAidType = {
  '0': '委托案件',
  '1': '非委托案件'
}
export const accuserType = {
  '1': '原告',
  '2': '被告',
  '3': '当事人'
}
export const occType = {
  '1': '务工',
  '2': '务农',
  '3': '在家'
}
export const syrType = {
  '1': '农民工',
  '2': '未成年',
  '3': '妇女家庭权益保护',
  '4': '残疾人',
  '5': '老年人'
}
export const lawyerStateText = {
  '0': {
    label: '未审核',
    color: 'grey'
  },
  '1': {
    label: '审核通过',
    color: 'green'
  },
  '2': {
    label: '审核未通过',
    color: 'red'
  }
}
export const cashStateText = {
  '0': {
    label: '未打款',
    color: 'grey'
  },
  '1': {
    label: '已打款',
    color: 'green'
  }
}
export const agencyStateText = {
  '0': {
    label: '未审核',
    color: 'grey'
  },
  '1': {
    label: '审核通过',
    color: 'green'
  },
  '2': {
    label: '审核未通过',
    color: 'red'
  },
  '3': {
    label: '已冻结',
    color: 'yellow'
  }
}
export const sexType = {
  1: '男',
  0: '女'
}
export const isNeedType = {
  '1': '必要',
  '0': '非必要'
}
export const sueType = {
  '1': '原告',
  '2': '被告',
  '3': '第三人'
}
export const scopeType = {
  '0': '一般授权',
  '1': '特别授权'
}
export const relationType = {
  '1': '丈夫',
  '2': '妻子',
  '3': '儿子',
  '4': '女儿',
  '5': '父亲',
  '6': '母亲',
  '7': '兄弟',
  '8': '姐妹',
  '9': '爷爷',
  '10': '奶奶',
  '11': '外公',
  '12': '外婆',
  '13': '孙子',
  '14': '孙女'
}
export const caseStateText = {
  '10': {
    label: '待受理',
    color: 'grey'
  },
  '20': {
    label: '待指派',
    color: 'yellow'
  },
  '30': {
    label: '已指派',
    color: 'green'
  },
  '40': {
    label: '办理中',
    color: 'yellow'
  },
  '50': {
    label: '法院已受理',
    color: 'yellow'
  },
  '60': {
    label: '待开庭',
    color: 'yellow'
  },
  '70': {
    label: '已判决',
    color: 'green'
  },
  '80': {
    label: '待审查',
    color: 'yellow'
  },
  '90': {
    label: '已完成',
    color: 'green'
  },
  '11': {
    label: '审核未通过',
    color: 'red'
  },
  // //刑事案件
  // '13': {
  //   label: '待受理',
  //   color: 'yellow'
  // },
  '41': {
    label: '已拒绝',
    color: 'red'
  }
}
export const caseSuccessStep = [
  {
    value: '10',
    label: '待受理',
    field: 'createTime'
  },
  {
    label: '待指派',
    value: '20',
    field: 'yiDate'
  },
  {
    label: '已指派',
    value: '30',
    field: 'zpDate'
  },
  {
    label: '办理中',
    value: '40',
    field: 'blzDate'
  },
  {
    label: '法院已受理',
    value: '50',
    field: 'lianDate'
  },
  {
    label: '待开庭',
    value: '60',
    field: 'openDate'
  },
  {
    label: '已判决',
    value: '70',
    field: 'pjDate'
  },
  {
    label: '待审查',
    value: '80',
    field: 'jaDate'
  },
  {
    label: '已完成',
    value: '90',
    field: 'endDate'
  }
]
export const caseError11 = [
  {
    value: '10',
    label: '待受理',
    field: 'createTime'
  },
  {
    label: '审核未通过',
    value: '11',
    field: 'yiDate'
  }
]
export const caseError41 = [
  {
    value: '10',
    label: '待受理',
    field: 'createTime'
  },
  {
    label: '待指派',
    value: '20',
    field: 'yiDate'
  },
  {
    label: '已指派',
    value: '30',
    field: 'zpDate'
  },
  {
    label: '审核未通过',
    value: '41',
    field: 'blzDate'
  }
]

export const docStateText = {
  '0': {
    label: '已生成',
    color: 'grey'
  },
  '1': {
    label: '待签名',
    color: 'yellow'
  },
  '2': {
    label: '已签名',
    color: 'green'
  },
  '3': {
    label: '存档',
    color: 'green'
  },
  '4': {
    label: '存档',
    color: 'green'
  }
}
export const caseType = [
  {
    "value": "民事",
    "label": "民事",
    "children": [{
      "value": "诉讼",
      "label": "诉讼",
      "children": [{
        "value": "一审",
        "label": "一审"
      },{
        "value": "二审",
        "label": "二审"
      },{
        "value": "再审",
        "label": "再审"
      },{
        "value": "抗议",
        "label": "抗议"
      }]
    },{
      "value": "仲裁",
      "label": "仲裁",
      "children": [{
        "value": "劳动争议",
        "label": "劳动争议"
      },{
        "value": "涉外、海事海商",
        "label": "涉外、海事海商"
      }]
    },{
      "value": "执行",
      "label": "执行",
      "children": [{
        "value": "国内",
        "label": "国内"
      },{
        "value": "涉外",
        "label": "涉外"
      }]
    }]
  },
  {
    "value": "行政",
    "label": "行政",
    "children": [{
      "value": "听证",
      "label": "听证"
    },{
      "value": "复议",
      "label": "复议"
    },{
      "value": "诉讼",
      "label": "诉讼",
      "children": [{
        "value": "一审",
        "label": "一审"
      },{
        "value": "二审",
        "label": "二审"
      },{
        "value": "再审",
        "label": "再审"
      },{
        "value": "抗议",
        "label": "抗议"
      }]
    }]
  },
  {
    "value": "刑事",
    "label": "刑事",
    "children": [{
      "value": "侦察",
      "label": "侦察"
    },{
      "value": "审查起诉",
      "label": "审查起诉"
    },{
      "value": "审判",
      "label": "审判"
    },{
      "value": "自诉",
      "label": "自诉"
    }]
  }
]
export const docCataLog = [
  {
    "value": "委托手续",
    "label": "委托手续",
    // "children": [
    //   {
    //     "value": "接待笔录",
    //     "label": "接待笔录"
    //   },
    //   {
    //     "value": "委托受理合同",
    //     "label": "委托受理合同"
    //   },
    //   {
    //     "value": "授权委托书",
    //     "label": "授权委托书"
    //   }
    // ]
  },
  {
    "value": "律师办案",
    "label": "律师办案",
    // "children": [
    //   {
    //     "value": "会见函",
    //     "label": "会见函"
    //   },
    //   {
    //     "value": "起诉状",
    //     "label": "起诉状"
    //   },
    //   {
    //     "value": "答辩状",
    //     "label": "答辩状"
    //   },
    //   {
    //     "value": "办案思路",
    //     "label": "办案思路"
    //   }
    // ]
  },
  {
    "value": "审理机关程序性文书",
    "label": "审理机关程序性文书",
    // "children": [
    //   {
    //     "value": "传票",
    //     "label": "传票"
    //   },
    //   {
    //     "value": "案件受理通知书",
    //     "label": "案件受理通知书"
    //   },
    //   {
    //     "value": "开庭通知书",
    //     "label": "开庭通知书"
    //   },
    //   {
    //     "value": "应诉通知书",
    //     "label": "应诉通知书"
    //   }
    // ]
  },
  {
    "value": "裁判文书",
    "label": "裁判文书",
    // "children": [
    //   {
    //     "value": "裁定书",
    //     "label": "裁定书"
    //   },
    //   {
    //     "value": "调解书",
    //     "label": "调解书"
    //   },
    //   {
    //     "value": "判决书",
    //     "label": "判决书"
    //   },
    //   {
    //     "value": "仲裁裁决书",
    //     "label": "仲裁裁决书"
    //   }
    // ]
  }
]

export const agencyTypeText = {
  '1': {
    label: '律师事务所'
  },
  '2': {
    label: '法律援助机构'
  },
  '3': {
    label: '民办非企业单位'
  },
  '4': {
    label: '高校社团'
  },
  '5': {
    label: '直接资助办案单位'
  },
  '6': {
    label: '妇联法律帮助机构'
  },
  '7': {
    label: '工会法律帮助组织'
  },
}

export const companyReasonTypes = [
  {
    value: '1',
    label: '当事人经济状况和申请事项符合《法律援助条例》和本省（区、市）补充规定的法律援助事项范围，但当地法律援助经费确实存在困难的；'
  },
  {
    value: '2',
    label: '当事人的经济状况符合当地法律援助经济困难标准，但其申请法律援助的事项不属于《法律援助条例》和本省（区、市）补充规定的法律援助事项范围的,但属于《办法》第六条规定的案件类型的；'
  },
  {
    value: '3',
    label: '当事人经济状况高于当地法律援助经济困难标准，低于当地城镇职工最低工资标准，其申请法律援助的事项符合《法律援助条例》和本省（区、市）补充规定的法律援助事项范围的；'
  },
  {
    value: '4',
    label: '当事人经济状况高于当地法律援助经济困难标准，低于当地城镇职工最低工资标准，其申请法律援助的事项属于《办法》第六条规定的案件类型的。当事人的经济状况符合当地法律援助经济困难标准，但其申请法律援助的事项属于当地法律援助事项范围之外的；'
  },
  {
    value: '5',
    label: '刑事案件中符合《办法》规定的五类群体。'
  }
]

export const userTypes = {
  'Lawyer': {
    label: '律师'
  },
  'LawFirm': {
    label: '律所'
  },
  'Organization': {
    label: '公益机构'
  },
  'User': {
    label: '普通账号'
  },
  'Admin': {
    label: '超级管理员'
  }
}

export const specialityTypes = {
  '1': '婚姻家事',
  '2': '房产土地',
  '3': '公司工商',
  '4': '交通事故',
  '5': '合同纠纷',
  '6': '债权债务',
  '7': '劳动用工',
  '8': '治安刑事',
  '9': '诉讼仲裁',
  '10': '其他'
}

export const balanceTypes = {
  '1': {
    label: '收入',
    prefix: <PlusOutlined style={{ fontSize: '12px'}}/>,
    color: '#3f8600'
  },
  '2': {
    label: '支出',
    prefix: <MinusOutlined style={{ fontSize: '12px'}}/>,
    color: '#cf1322'
  }
}

export const acceptType = {
  image: 'image/*',
  audio: 'audio/*',
  video: 'video/*',
  pdf: 'application/pdf',
  word: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const aidType = {
  '1': '刑事辩护',
  '2': '刑事被害人代理',
  '3': '自诉代理',
  '4': '民事诉讼代理（包括刑事附带民事诉求）',
  '5': '行政诉讼代理',
  '6': '劳动仲裁代理',
  '7': '非诉讼代理',
  '8': '民事案件执行'
}
export const undertakeResult = {
  '1': '调解或和解',
  '2': '撤诉',
  '3': '胜诉',
  '4': '败诉',
  '5': '部分胜诉',
  '6': '全部采纳',
  '7': '部分采纳',
  '8': '未采纳',
  '9': '全部执行'
}

export const helpTypes = {
  '1': '按照《办法》第33条规定的一般情况申请办案补贴。',
  '2': '按照《办法》第33条规定的疑难案件和跨地区办理的案件申请办案补贴。',
  '3': '按照《办法》第35条的规定降低标准申请办案补贴。',
  '4': '按照《办法》第43条的规定减半申请办案补贴。'
}

export const documentModeType = {
  '委托手续': '委托手续',
  '律师办案': '律师办案'
}

export const modelTextType = {
  '1': '合同范本',
  '2': '法律文书范本'
}

export const isDownLoad = {
  '1': '已下载',
  '2': '未下载'
}
