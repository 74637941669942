import React from 'react'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { withRouter } from 'react-router-dom'

function AdviseQuery(props) {
  const columns = [
    {
      title: '建议内容',
      dataIndex: 'content'
    },
    {
      title: '联系方式',
      dataIndex: 'tel'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [

    ],
    params: [
    ]
  }
  const actions = [
    {
      type: 'link',
      url: 'detail/:id'
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        api='advise/query'
      />

    </>
  )
}
export default withRouter(AdviseQuery)
