import React from 'react'
import { withRouter } from 'react-router-dom'
import { ExportOutlined } from '@ant-design/icons'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { transformatTime } from '../../utils/utils'
import { stringify } from 'qs'
import { caseType, isDownLoad } from '../../config/types'
import { system } from '../../config/system'
import cookie from 'js-cookie'

function CaseExport(props) {

  const columns = [
    {
      title: '案件名称',
      dataIndex: 'name'
    },

    {
      title: '案件类型',
      dataIndex: 'type'
    },
    {
      title: '公益机构',
      dataIndex: 'orgAgencyName'
    },
    {
      title: '当事人',
      dataIndex: 'accuserNames'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    },
    {
      title: '办案律师',
      dataIndex: 'lawyerName',
      render: lawyerName => lawyerName.toString()
    }
  ]

  const search = {
    extra: [
      {
        label: '导出',
        icon: <ExportOutlined/>,
        onClick(father) {
          window.open(`${system.download}/caseDic/exportExcel?token=${cookie.get('token')}&${stringify(father.formRef.current.getFieldsValue())}`)
        }
      }
    ],
    params: [
      {
        name: 'title',
        label: '案件名称'
      },
      {
        name: 'accuserName',
        label: '我方当事人'
      },
      {
        name: 'duifangName',
        label: '对方当事人'
      },
      {
        name: 'lawyerName',
        label: '办案律师'
      },
      {
        name: 'caseType',
        label: '案件类型',
        type: 'cascader',
        options: caseType
      },
      {
        name: 'download',
        label: '是否下载',
        type: 'select',
        options: Object.entries(isDownLoad).map(item => {
          return {
            value: item[0],
            label: item[1]
          }
        })
      },
      {
        name: 'stime,etime',
        label: '办案时间',
        type: 'rangeDate'
      }
    ]
  }

  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      columns={columns}
      search={search}
      defaultData={{
        state: 90
      }}
      api='case/query'
    />
    </>
  )
}
export default withRouter(CaseExport)

