import React from 'react'
import {Form, Input, Comment, Tooltip, Avatar, Row, Button} from 'antd'
import axios from '../../request/axios';
import { transform, transformatTime } from '../../utils/utils'
import cookie from 'js-cookie'
import { connect } from 'react-redux'
import QueueAnim from 'rc-queue-anim'

class CommentList extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      list: props.list,
      loading: false
    }

  }
  formRef = React.createRef()
  replay = (values) => {
    const { content } = values
    const { headPic, nickName } = JSON.parse(cookie.get('user'))
    this.props.replay(values, this, isSuccess => {
      if (isSuccess) {
        this.formRef.current.resetFields()
        this.setState({
          list: this.state.list.concat({
            createTime: Date.now(),
            headPic: headPic,
            content,
            showName: nickName
          }),
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    })
  }
  render() {
    const { headPic, nickName } = JSON.parse(cookie.get('user'))
    console.log('CommentList.render()')
    return (
      <div>
        <QueueAnim
            type={'left'}
            appear={false}
        >
        {
          this.state.list.map((item, index) => {
            return (
              <Comment
                key={index}
                author={item.showName}
                avatar={
                  <Avatar
                    src={item.headPic}
                    alt={item.showName}
                  />
                }
                datetime={
                  <Tooltip title={transformatTime(new Date(item.createTime))}>
                    <span>{transform(new Date(item.createTime)).fromNow()}</span>
                  </Tooltip>
                }
                content={
                  <p>
                    {item.content}
                  </p>
                }
              >
              </Comment>
            )
          })
        }
        </QueueAnim>
        <Comment
          avatar={
            <Avatar
              src={ headPic }
              alt={ nickName }
            />
          }
          content={
            <Form
              ref={this.formRef}
              onFinish={this.replay}
            >
              <Form.Item
                name='content'
                rules={[{ required: true ,message: '请输入回复内容'}]}
              >
                <Row>
                  <div style={{ flex: 1 }}>
                    <Input.TextArea
                        rows={4}
                    />
                  </div>
                  <div
                    style={{'display': 'flex', 'margin': '0 0px 5px 20px', 'alignItems': 'flex-end'}}
                  >
                    <Button
                      type='primary'
                      htmlType="submit"
                      loading={this.state.loading}
                    >
                      回复
                    </Button>
                  </div>
                </Row>
              </Form.Item>
            </Form>
          }
        />
      </div>
    )
  }

}

export default connect(
    () => ({}), null, null, { forwardRef: true }
)(CommentList)
