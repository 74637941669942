import React from 'react'
import { DatePicker, Button } from 'antd'
import moment from 'moment'
import axios from '../request/axios'
import { syrType } from '../config/types'
import { system } from '../config/system'
import cookie from 'js-cookie'
import './census.less'

class Census extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      stime: '',
      etime: '',
      date: []
    }
  }
  componentDidMount() {
    this.getDate([])
  }

  token = cookie.get('token')

  getDate(dates) {
    axios.postString('/report/getIndex', dates.filter(item => item).length === 2 ? {
      stime: dates[0].format('YYYY-MM-DD'),
      etime: dates[1].format('YYYY-MM-DD'),
    } : {}).then(res => {
      let newDate = [
        // {
        //   name: '人员统计',
        //   children: [
        //     {
        //       name: '分类统计',
        //       children: [
        //         {
        //           name: '农民工',
        //           value: res.syrlx2[0].value
        //         },
        //         {
        //           name: '未成年',
        //           value: res.syrlx2[1].value
        //         },
        //         {
        //           name: '妇女家庭权益保护',
        //           value: res.syrlx2[2].value
        //         },
        //         {
        //           name: '残疾人',
        //           value: res.syrlx2[3].value
        //         },
        //         {
        //           name: '老年人',
        //           value: res.syrlx2[4].value
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          name: '案件统计',
          children: [
            {
              name: '案件（件数）',
              children: ((res.aj1 && res.aj1.filter(item => item).length) ?[
                {
                  name: '已结案件数',
                  value: res.aj1[0].name
                },
                {
                  name: '未结案件数',
                  value: res.aj1[0].value
                }
              ] : []).filter(item => item && item.name)
            },
            {
              name: '受援人类型（人数）',
              children: res.syrlx2.filter(item => item && item.name).map(item => {
                item.name = {
                  '1': '农民工',
                  '2': '未成年',
                  '3': '妇女家庭权益保护',
                  '4': '残疾人',
                  '5': '老年人'
                }[item.name]
                return item
              })
            },
            {
              name: '案件所在街道（人数）',
              children: res.ajjd3.filter(item => item && item.name)
            },
            {
              name: '受援人户籍所在地',
              children: res.ajhjszd4.filter(item => item && item.name)
            },
            {
              name: '受援人平均年龄',
              value: (res.pjnl5 && res.pjnl5.filter(item => item).length) ? res.pjnl5[0].name : 0
            },
            {
              name: '案件类型',
              children: res.ajlx6.filter(item => item && item.name).map(item => ({
                ...item,
                value: item.amount
              }))
            },
            {
              name: '是否跨地区案件（件数）',
              children: ((res.kqy7 && res.kqy7.filter(item => item).length) ?[
                {
                  name: '是',
                  value: res.kqy7[0].name
                },
                {
                  name: '否',
                  value: res.kqy7[0].value
                }
              ] : []).filter(item => item && item.name)
            },
            {
              name: '是否重大疑难案件（件数）',
              children: ((res.zdyn8 && res.zdyn8.filter(item => item).length) ?[
                {
                  name: '是',
                  value: res.zdyn8[0].name
                },
                {
                  name: '否',
                  value: res.zdyn8[0].value
                }
              ] : []).filter(item => item && item.name)
            }
          ]
        },
        {
          name: '资金统计',
          children: [
            {
              name: '受援人类型（挽回经济损失）金额：元',
              children: res.jjsssyrlx.filter(item => item && item.name).map(item => ({
                ...item,
                name: syrType[item.name]
              }))
            },
            {
              name: '案件类型（挽回经济损失）金额：元',
              children: res.jjssajlx.filter(item => item && item.name)
            },
            {
              name: '是否跨地区案件（挽回经济损失）金额：元',
              children: ((res.jjsskqy && res.jjsskqy.filter(item => item).length) ?[
                {
                  name: '是',
                  value: res.jjsskqy[0].name
                },
                {
                  name: '否',
                  value: res.jjsskqy[0].value
                }
              ] : []).filter(item => item && item.name)
            },
            {
              name: '是否重大疑难案件（挽回经济损失）金额：元',
              children: ((res.jjsszdyn && res.jjsszdyn.filter(item => item).length) ?[
                {
                  name: '是',
                  value: res.jjsszdyn[0].name
                },
                {
                  name: '否',
                  value: res.jjsszdyn[0].value
                }
              ] : []).filter(item => item && item.name)
            }
          ]
        }
      ]
      newDate.forEach(item => {
        item.rows = item.children.length > 1 ? item.children.reduce((total, item2) => {
          return (typeof total === 'object' ? (total.children ? total.children.length : 1) : total) + (item2.children ? item2.children.length : 1)
        }) : item.children[0].children.length
        // console.log(item.rows)
      })
      this.setState({
        stime: res.queryVo.stime,
        etime: res.queryVo.etime,
        date: newDate
      })
    })
  }


  render() {
    return (
      <>
        <div className="white-box">
          <DatePicker.RangePicker
            value={[this.state.stime ? moment(new Date(this.state.stime), 'YYYY-MM-DD') : '', this.state.etime ? moment(new Date(this.state.etime), 'YYYY-MM-DD') : '']}
            onCalendarChange={dates => this.getDate(dates)}
          />
          {/*<a*/}
            {/*href={`${system.download}/case/batchExport?stime=${this.state.stime}&etime=${this.state.etime}&token=${this.token}`}*/}
            {/*target={'_blank'}*/}
          {/*>*/}
            {/*<Button type={'primary'} >导出</Button>*/}
          {/*</a>*/}
        </div>
        <div className="white-box">
          <table className="date-table">
            <tbody>
            {
              this.state.date.map((item1, index1) => {
                return item1.children.map((item2, index2) => {
                  return item2.children ? item2.children.map((item3, index3) => (
                    <tr key={index3}>
                      {
                        !index2 && !index3 ? (
                          <th rowSpan={item1.rows}>{item1.name}</th>
                        ) : null
                      }
                      {
                        !index3 ? (
                          <th rowSpan={item2.children.length}>{item2.name}</th>
                        ) : null
                      }
                      <th>{item3.name}</th>
                      <td>{item3.value}</td>
                    </tr>
                  )) : (
                    <tr key={index2}>
                      {
                        !index2 ? (
                          <th rowSpan={item1.rows}>{item1.name}</th>
                        ) : null
                      }
                      <th colSpan="2">{item2.name}</th>
                      <td>{item2.value}</td>
                    </tr>
                  )
                })
              })
            }
            </tbody>
          </table>
        </div>
      </>
    )
  }

}
export default Census
