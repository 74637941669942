import React from 'react'
import { transformatTime } from '../../utils/utils'
import { Avatar, Badge, Switch, message } from 'antd'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'


function UserQuery() {
  const columns = [
    {
      title: '头像',
      dataIndex: 'headPic',
      render: (headPic, row) => {
        return (
          <Avatar
            src={headPic}
          >
            {row.nickName}
          </Avatar>

        )
      }
    },
    {
      title: '昵称',
      dataIndex: 'nickName'
    },
    {
      title: '手机号',
      dataIndex: 'mobile'
    },
    {
      title: '实名认证',
      dataIndex: 'isReal',
      render: isReal => isReal === 1 ? (<Badge status="success" />) : ''

    },
    {
      title: '管理员',
      dataIndex: 'isAdmin',
      align: 'center',
      render: (isAdmin, row) => (
        <Switch
          defaultChecked={!!isAdmin}
          onChange={(checked,e) => {
            console.log(e)
            axios.postString('user/adminEdit', {
              isAdmin: checked ? 1 : 0,
              id: row.id
            }).then(() => {
              message.success('设置成功')
            }).catch(()=>{

            })
          }}
        />
      )
    },
    {
      title: '机构管理员',
      dataIndex: 'adminOrgAgencyId',
      align: 'center',
      render: adminOrgAgencyId => adminOrgAgencyId > 0 ? (<Badge status="success" />) : ''
    },
    {
      title: '律所管理员',
      dataIndex: 'adminAgencyId',
      align: 'center',
      render: adminAgencyId => adminAgencyId > 0 ? (<Badge status="success" />) : ''
    },

    {
      title: '律师',
      dataIndex: 'isLawer',
      align: 'center',
      render: isLawer => isLawer === 1 ? (<Badge status="success" />) : ''
    },
    {
      title: '注册时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      // {
      //   label: '新建',
      //   icon: <PlusOutlined/>,
      //   onClick() {
      //     props.history.push('add')
      //   }
      // }
    ],
    params: [
      {
        name: 'nickName',
        label: '昵称'
      },
      {
        name: 'mobile',
        label: '手机号'
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      label: '实名信息',
      url: 'detail/:id',
      condition: row => row.isReal === 1
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="uid"
        columns={columns}
        search={search}
        actions={actions}
        api='user/query'
      />

    </>
  )
}
export default withRouter(UserQuery)
