import React from 'react'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'

const columns = [
  {
    title: '用户名',
    dataIndex: 'userName'
  },
  {
    title: '用户ID',
    dataIndex: 'userId',
    align: 'center'
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    align: 'center'
  },
  {
    title: '模块',
    dataIndex: 'module',
    align: 'center'
  },
  {
    title: '内容',
    dataIndex: 'content'
  },
  {
    title: '时间',
    dataIndex: 'time',
    render: time => transformatTime(new Date(time))
  }
]

function OpLog() {
  console.log('Oplog')
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        api="log"
        columns={columns}
      />
    </>
  )
}
export default OpLog
