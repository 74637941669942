/* eslint-disable default-case */
import React, { useState } from 'react'
import {
  Input,
  Select,
  Form,
  Button,
  Modal,
  message,
  Radio,
  Checkbox,
  Popconfirm,
  DatePicker,
  Cascader,
  Switch,
  InputNumber,
  ConfigProvider
} from 'antd'
import { ExclamationCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import AliyunOSSUpload from './AliyunOSSUpload'
import { getFullArea } from '../config/area'
import { docCataLog } from '../config/types'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { validateMessages } from '../config/validateConfig'
import axios from '../request/axios'
import Zmage from 'react-zmage'
import CustomAutoComplete from 'components/CustomAutoComplete'
import CustomArray from 'components/CustomArray'
import CustomSelect from 'components/CustomSelect'
import CustomEditor from 'components/CustomEditor'
import CustomInput from 'components/CustomInput'
import CustomTableNoRouter from 'components/CustomTableNoRouter'
import './factory.less'
import CustomModalForm from "components/CustomModalForm"
 /**
 * 获取表单编辑框
 * */
export function getFormItem(item, father) {
  switch (item.type) {
    case 'text':
      return item.render ? item.render(item, father) : item.value
    case 'input':
      return (
        <Input
          disabled={!!item.disabled}
          allowClear
          placeholder={item.placeholder || '请输入'}
          prefix={item.prefix}
          suffix={item.suffix}
          maxLength={item.maxLength}
          onChange={item.onChange}
        />
      )
    case 'password':
      return (
        <Input.Password
          disabled={!!item.disabled}
          allowClear
          placeholder={item.placeholder || '请输入'}
          prefix={item.prefix}
          suffix={item.suffix}
          maxLength={item.maxLength}
          onChange={item.onChange}
        />
      )
    case 'customInput':
      return (
        <CustomInput
          {...item}
          father={father}
          placeholder={item.placeholder || '请输入'}
        />
      )
    case 'number':
      return (
        <InputNumber
          formatter={item.formatter || ((value) => `${item.prefix || ''} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
          parser={value => value.replace(/[^\.\d]/g, '')}
          allowClear
          max={item.max}
          min={item.min}
          step={item.size || 1}
          precision={item.precision}
          placeholder={item.placeholder || '请输入'}
          style={{ width: '100%'}}
        />
      )
    case 'select':
      return (
        <Select
          mode={item.mode}
          allowClear
          placeholder={item.placeholder || '请选择'}
        >
          {
            item.options && item.options.map((item, index) => {
              return (
                <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
              )
            })
          }
        </Select>
      )
    case 'area':
      return (
        <>
        <Cascader
          key={item.value}
          options={getFullArea()}
          defaultValue={item.value ? item.value.split(',').filter((item, i) => i) : null}
          displayRender={label => label.join('-')}
          onChange={
            (values, selectedOptions) => {
              item.name.split(',').forEach((name, index) => {
                father.formRef.current.setFieldsValue({
                  [name]: index ? values[index-1] : selectedOptions.map(item => item.label).join('-')
                })
              })
            }
          }
        />
        {
          item.name.split(',').map((name, index) => {
            return index ? (
              <Form.Item
                key={'Cascader' + index}
                name={name}
                noStyle
              >
                <Input type='hidden'/>
              </Form.Item>
            ) : ''
          })
        }
        </>
      )
    case 'catalog':
      return (
        <>
        <Cascader
          key={item.value}
          options={docCataLog}
          defaultValue={item.value ? item.value.split('-') : null}
          displayRender={label => label.join('-')}
          onChange={
            (values, selectedOptions) => {
              item.name.split(',').forEach((name, index) => {
                father.formRef.current.setFieldsValue({
                  [name]: values[index]
                })
              })
            }
          }
        />
        {
          item.name.split(',').map((name, index) => {
            return (
              <Form.Item
                key={'catalog' + index}
                name={name}
                noStyle
              >
                <Input type='hidden'/>
              </Form.Item>
            )
          })
        }
        </>
      )
    case 'cascader':
      return (
        <>
          <Cascader
            defaultValue={item.value ? item.value.split('·') : []}
            options={item.options}
            key={item.value}
            onChange={
              (values, selectedOptions) => {
                father.formRef.current.setFieldsValue({
                  [item.name] : selectedOptions.map(item => item.label).join('·')
                })
              }
            }
            displayRender={label => label.join('·')}
          />
        </>
      )
    case 'uploadImg':
      return (
        <>
          <AliyunOSSUpload
            listType='picture-card'
            defaultValue={item.value}
            title={item.label}
            limit={item.limit || 1}
            folderPath={item.folderPath}
            onSuccess={res => {
              father.formRef.current.setFieldsValue({
                [item.name]: res.map(file => file.url).join(',')
              })
            }}
          />
        </>
      )
    case 'uploadFile':
      return (
        <AliyunOSSUpload
          defaultValue={item.value}
          title={item.label}
          limit={item.limit || 1}
          accept={item.accept}
          folderPath={item.folderPath}
          onSuccess={res => {
            father.formRef.current.setFieldsValue({
              [item.name]: res.map(file => file.url).join(',')
            })
          }}
        />
      )
    case 'date':
      return (
        <DatePicker
          placeholder={item.placeholder || '请选择日期'}
          style={{width: '100%'}}
        />
      )
    case 'autoComplete':
      return (
        <>
          <CustomAutoComplete
            {...item}
            father={father}
          />
          {
            item.extraData ? item.extraData(item.value).map(item => {
              return (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  noStyle
                >
                  <Input type='hidden' defaultValue={item.value}/>
                </Form.Item>
              )
            }) : null
          }
        </>
      )
    case 'switch':
      return (
        <Switch
          disabled={!!item.disabled}
          // defaultChecked={item.value}
          checkedChildren={item.checkedChildren}
          unCheckedChildren={item.unCheckedChildren}
          onChange={ checked => {

          }}
        />
      )
    case 'radio':
      return (
        <Radio.Group
          disabled={!!item.disabled}
          // defaultValue={item.value}
          options={item.options}
          optionType={item.optionType || 'button'}
          buttonStyle="solid"
        />
      )
    case 'textArea':
      return (
        <Input.TextArea allowClear rows={4} placeholder={item.placeholder || '请输入'}/>
      )
    case 'array':
      return (
        <CustomArray
          {...item}
          father={father}
        />
      )
    case 'customSelect':
      return (
        <>
          <CustomSelect
            {...item}
            father={father}
          />
          {
            item.extraData ? item.extraData(item.value).map(item => {
              return (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  noStyle
                >
                  <Input type='hidden' defaultValue={item.value}/>
                </Form.Item>
              )
            }) : null
          }
        </>
      )
    case 'editor':
      return (
        <div className='border'>
          <CustomEditor
            {...item}
            father={father}
          />
        </div>
      )
    default:
      return (
        <Input
          disabled={!!item.disabled}
          allowClear
          placeholder={item.placeholder || '请输入'}
          prefix={item.prefix}
          suffix={item.suffix}
          maxLength={item.maxLength}
          onChange={item.onChange}
        />
      )
  }
}

export function setSpecialValue(item, initialValues) {
  switch (item.type) {
    case 'area':
      item.value = item.name.split(',').map(name => {
        return initialValues[name]
      }).filter(value => value).join(',') || ''
      break
    case 'catalog':
      item.value = item.name.split(',').map(name => {
        return initialValues[name]
      }).filter(value => value).join('-') || ''
      break
    case 'uploadImg':
      item.value = initialValues[item.name] || ''
      break
    case 'autoComplete':
      item.value = initialValues[item.name] || ''
      break
    case 'editor':
      item.value = initialValues[item.name] || ''
      break
    case 'customSelect':
      item.value = initialValues[item.name] || ''
      break
    case 'customInput':
      item.value = initialValues[item.name] || ''
      break
    case 'array':
      item.value = initialValues[item.name] || ''
      break
    case 'text':
      item.value = initialValues[item.name] || ''
      break
    case 'cascader':
      item.value = initialValues[item.name] || ''
  }
}
/**
 * 获取普通输入框
 * */
export function getInput(item, row, father, _this) {
  switch (item.type) {
    case 'text':
      return item.getValue ? item.getValue(row) : row[item.name]
    case 'input':
      return (
        <Input
          disabled={!!item.disabled}
          allowClear
          placeholder={item.placeholder || '请输入'}
          prefix={item.prefix}
          suffix={item.suffix}
          maxLength={item.maxLength}
        />
      )
    case 'number':
      return (
        <InputNumber
          formatter={item.formatter || ((value) => `${item.prefix || ''} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
          parser={value => value.replace(/[^\.\d]/g, '')}
          allowClear
          max={item.max}
          min={item.min}
          step={item.size || 1}
          precision={item.precision}
          placeholder={item.placeholder || '请输入'}
          style={{ width: '100%'}}
        />
      )
    case 'textArea':
      return (<Input.TextArea allowClear rows={4} placeholder={item.placeholder || '请输入'}/>)
    case 'select':
      return (
        <Select allowClear placeholder={item.placeholder || '请选择'}>
          {
            item.options && item.options.map((item, index) => {
              return (
                <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
              )
            })
          }
        </Select>
      )
    case 'radio':
      return (
        <Radio.Group
          options={item.options}
          optionType={item.optionType || 'button'}
          onChange={() => {
            item.onChange && item.onChange(_this)
          }}
          // value={item.options[0].value}
        />
      )
    case 'date':
      return (
        <DatePicker
          placeholder={item.placeholder || '请选择日期'}
          style={{width: '100%'}}
        />
      )
    case 'rangeDate':
      return (
        <>
          <DatePicker.RangePicker
            placeholder={item.placeholder || ['开始日期', '结束日期']}
            style={{width: '100%'}}
            onChange={values => {
              item.name.split(',').map((name, index) => {
                father.formRef.current.setFieldsValue({
                  [name]: values[index].format('YYYY-MM-DD')
                })
              })
            }}
          />
          {
            item.name.split(',').map((name, index) => {
              return index ? (
                <Form.Item
                  key={'RangePicker' + index}
                  name={name}
                  noStyle
                >
                  <Input type='hidden'/>
                </Form.Item>
              ) : ''
            })
          }
        </>
      )
    case 'checkbox':
      return (
        <Checkbox.Group
          options={item.options}
          // defaultValue={item.options[0].value}
        />
      )
    case 'uploadImg':
      return (
        <AliyunOSSUpload
          listType='picture-card'
          title={item.label}
          row={row}
          limit={item.limit || 1}
          folderPath={item.folderPath}
          onSuccess={res => {
            father.formRef.current.setFieldsValue({
              [item.name]: res.map(file => file.url).join(',')
            })
          }}
        />
      )
    case 'uploadFile':
      return (
        <AliyunOSSUpload
          title={item.label}
          row={row}
          limit={item.limit || 1}
          accept={item.accept}
          folderPath={item.folderPath}
          onSuccess={res => {
            father.formRef.current.setFieldsValue({
              [item.name]: res.map(file => file.url).join(',')
            })
          }}
        />
      )
    case 'autoComplete':
      return (
        <>
        <CustomAutoComplete
          {...item}
          father={father}
        />
        {
          item.extraData ? item.extraData(item.value).map(item => {
            return (
              <Form.Item
                key={item.name}
                name={item.name}
                noStyle
              >
                <Input type='hidden' defaultValue={item.value}/>
              </Form.Item>
            )
          }) : null
        }
        </>
      )
    case 'customSelect':
      return (
        <>
        <CustomSelect
          {...item}
          father={father}
        />
        {
          item.extraData ? item.extraData(item.value).map(item => {
            return (
              <Form.Item
                key={item.name}
                name={item.name}
                noStyle
              >
                <Input type='hidden' defaultValue={item.value}/>
              </Form.Item>
            )
          }) : null
        }
        </>
      )
    case 'cascader':
      return (
        <>
        <Cascader
          defaultValue={item.value ? item.value.split('·') : []}
          options={item.options}
          onChange={
            (values, selectedOptions) => {
              father.formRef.current.setFieldsValue({
                [item.name] : selectedOptions.map(item => item.label).join('·')
              })
            }
          }
          displayRender={label => label.join('·')}
        />
        </>
      )
    default:
      return (<Input allowClear placeholder={item.placeholder || '请输入'} />)
  }
}

/**
 * 操作列表
 * */
export function getAction(item, row, index, key, _this) {
  switch (item.type) {
    case 'click':
      return (
        <Button
          key={key}
          size="small"
          type="link"
          onClick={() => {
            item.onClick(item, row, index)
          }}
        >
          {item.label || '修改'}
        </Button>
      )
    case 'link':
      return (
        <Button
          key={key}
          type="link"
          size="small"
          onClick={() => {
           // _this.props.history.push(item.url.split("/:").map((item, i) => i ? row[item] : item).join('/') + (item.params ? '?' + item.params.map(param => `default_${param}=${row[param]}`).join('&') : ''))
            if (item.target === '_blank') {
              window.open(item.setUrl ? item.setUrl(row) : item.url.split("/:").map((item, i) => i ? row[item] : item).join('/'))
            } else {
              _this.props.history.push({
                pathname: item.setUrl ? item.setUrl(row) : item.url.split("/:").map((item, i) => i ? row[item] : item).join('/'),
              })
            }
          }}
        >
          {item.label || '查看'}
        </Button>
      )
    case 'delete':
      return (
        <Popconfirm
          key={key}
          title={`${item.label || '删除'}?`}
          icon={<CloseCircleTwoTone twoToneColor='red'/>}
          onConfirm={() => {
            axios.postString(item.api.split('/').map(path => {
              if (path.charAt(0) === ':') {
                return row[path.substring(1)]
              } else {
                return path
              }
            }).join('/')).then(() => {
              message.success(`${item.label || '删除'}成功`)
              _this.setState({
                list: _this.state.list.filter((item, i) => i!==index)
              })
            }).catch(() => {})
          }}
        >
          <Button
            type="link"
            size="small"
          >
            {item.label || '删除'}
          </Button>
        </Popconfirm>
      )
    case 'audit':
      const tempFormRef = React.createRef()
      return item.params && item.params.length
        ?(
          <Button
            key={key}
            type="link"
            size="small"
            onClick={() => {
              Modal.info({
                className: 'modal-width-form',
                width: item.width || 500,
                maskClosable: true,
                keyboard: true,
                title: item.label || '审核',
                content: item.getModalForm ? item.getModalForm(tempFormRef, row) : (
                  <CustomModalForm
                    formRef={tempFormRef}
                    formData={item.params}
                    row={row}
                  />
                ),
                okText: '确认',
                cancelText: '取消',
                onOk: () => new Promise((resolve, reject) => {
                  tempFormRef.current.validateFields().then(() => {
                    var values = tempFormRef.current.getFieldsValue()
                    Object.entries(values).forEach(item => {
                      if (Object.prototype.toString.call(item[1]).replace(/\[object\s+([^\]]+)\]/,"$1").toLowerCase() === 'object') {
                        if (item[1]._isAMomentObject) {
                          values[item[0]] = item[1].format('YYYY-MM-DD')
                        }
                      } else if (typeof item[1] === 'boolean') {
                        values[item[0]] = values[item[0]] ? '1' : '0'
                      }
                    })
                    const datas = item.getData ? item.getData(values, row) : {
                      ...Object.assign.apply(_this, (item.dataName || '').split(',').map(item => {
                        return {
                          [item]: row[item]
                        }
                      })),
                      ...values,
                      ...(typeof item.defaultData === 'function' ? item.defaultData(row) : item.defaultData),
                    }
                    axios[item.method || 'postString'](item.api.split('/').map(item => item.charAt(0) === ':' ? item.substring(1).split('?').map((item, i) => i ? item : row[item]).join('?') || '' : item).join('/'), datas).then(() => {
                      message.success('操作成功')
                      _this.setState({
                        list: _this.state.list.map((row, i) => {
                          return i === index ? {
                            ...row,
                            ...Object.assign.apply(_this, item.params.map(param => {
                              return {
                                [param.to || param.name]: datas[param.name]
                              }
                            })),
                            ...item.defaultTo,
                            ...(typeof item.defaultData === 'function' ? item.defaultData(row) : item.defaultData)
                          } : row
                        })
                      })
                      item.handleOk && item.handleOk(row)
                      resolve()
                    }).catch(() => {
                      reject()
                    })
                  }).catch(() => {
                    reject()
                  })
                })
              })
            }}
          >
            {item.label || '审核'}
          </Button>
        )
      : (
          <Popconfirm
            key={key}
            title={`${item.label || '审核'}`}
            icon={<ExclamationCircleTwoTone twoToneColor='blue'/>}
            onConfirm={() => {
              const datas = {
                ...(typeof item.defaultData === 'function' ? item.defaultData(row) : item.defaultData),
              }
              axios.postString(item.api.split('/').map(path => {
                if (path.charAt(0) === ':') {
                  return row[path.substring(1)]
                } else {
                  return path
                }
              }).join('/'), datas).then(() => {
                message.success(`${item.label || '审核'}成功`)
                // _this.setState({
                //   list: _this.state.list.filter((item, i) => i!==index).map(item => item)
                // })
                _this.setState({
                  list: _this.state.list.map((row, i) => {
                    return i === index ? {
                      ...row,
                      ...item.defaultTo,
                      ...(typeof item.defaultData === 'function' ? item.defaultData(row) : item.defaultData)
                    } : row
                  })
                })
                item.handleOk && item.handleOk(row)
              }).catch(() => {})
            }}
          >
            <Button
              type="link"
              size="small"
            >
              {item.label || '审核'}
            </Button>
          </Popconfirm>
        )
    case 'download':
      return (
        <a
          href={row[item.url] || item.url.split('/').map(item => item.charAt(0) === ':' ? item.substring(1).split('?').map((item, i) => i ? item : row[item]).join('?') || '' : item).join('/')}
          target={'_blank'}
          key={key}
        >
          <Button type={'link'} size="small">{item.label}</Button>
        </a>
      )
    default:
      return ''
  }
}

/**
 * 多类型展示详情
 * */
export function getDetail(item) {

  switch (item.type) {
    case 'image':
      // const [previewVisible, setPreviewVisible] = useState(false)
      return (
        <>
          {
            item.value && item.value.split(item.splitStr || ',').map((src, index) => {
              return (
                <Zmage
                  key={index}
                  width={200}
                  height={200}
                  style={{objectFit: 'contain', margin: '10px'}}
                  src={src}
                  defaultPage={index}
                  set={item.value.split(item.splitStr || ',').map(src => {
                    return {
                      src
                    }
                  })}
                />
              )
            })
          }
        </>
      )
    default:
      return item.value
  }
}

export function getExtra(item, index, _this) {
  switch (item.type) {
    case 'modal':
      return (
        <Button
          key={index}
          type={item.buttonType || 'primary'}
          onClick={() => {
            const formRef = React.createRef()
            Modal.info({
              className: 'modal-width-form',
              maskClosable: true,
              keyboard: true,
              width: item.width || 400,
              title: item.label || '',
              content: (
                <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                  <Form
                    ref={formRef}
                  >
                    {
                      item.params.map((param, i) => {
                        return (
                          <Form.Item
                            key={i}
                            name={param.name}
                            label={param.label}
                            rules={param.rules || (param.type === 'text' ? [] : [{required: true}])}
                          >
                            {getInput(param, {}, {formRef})}
                          </Form.Item>
                        )
                      })
                    }
                  </Form>
                </ConfigProvider>
              ),
              okText: '确认',
              cancelText: '取消',
              onOk: () => new Promise((resolve, reject) => {
                formRef.current.validateFields().then(() => {
                  const datas = {
                    ...formRef.current.getFieldsValue(),
                    ...item.defaultData
                  }
                  axios[item.method || 'postString'](item.api, datas).then(id => {
                    message.success('操作成功')
                    resolve()
                    item.onHandleOk && item.onHandleOk({...datas, id}, _this)
                  }).catch(() => {
                    reject()
                  })
                }).catch(() => {
                  reject()
                })

              })
            })

          }}
          icon={item.icon}
        >
          {item.label}
        </Button>
      )
    case 'list':
      let selectedRowKeys = []
      return (
        <Button
          key={index}
          type={item.buttonType || 'primary'}
          onClick={() => {
            const modal = Modal.info({
              className: 'modal-width-form',
              maskClosable: true,
              keyboard: true,
              width: item.width || 800,
              title: item.label || '',
              content: (
                <CustomTableNoRouter
                  rowKey={item.rowKey}
                  api={item.api}
                  actions={item.action}
                  columns={item.columns}
                  search={item.search}
                  rowSelectionChange = {_selectedRowKeys => {
                    selectedRowKeys = _selectedRowKeys
                  }}
                />
              ),
              onOk: () => {
                item.onHandleOk(selectedRowKeys)
              },
              okText: item.okText || '确定'
            })
          }}
          icon={item.icon}
        >
          {item.label}
        </Button>
      )
    default:
      return (
        <Button
          key={index}
          type={item.buttonType || 'primary'}
          onClick={() => {
            item.onClick(_this)
          }}
          icon={item.icon}
        >
          {item.label}
        </Button>
      )
  }
}

export function getEvidencePreview(evidence, evidences, size) {
  const type = evidence.perfix ? evidence.perfix.split('/')[0] : ''
  switch (type) {
    case 'image':
      return (
        <Zmage
          width={'100%'}
          height={size || 240}
          style={{objectFit: 'cover'}}
          src={evidence.address + '?x-oss-process=image/resize,m_fill,h_300,w_300'}
          set={evidences.filter(evidence => evidence.perfix && evidence.perfix.split('/')[0] === 'image').map(item => {
            return {
              src: item.address + '?x-oss-process=image/resize,m_pad,h_800,w_800'
            }
          })}
          defaultPage={evidences.filter(evidence => evidence.perfix && evidence.perfix.split('/')[0] === 'image').findIndex(item => {
            return evidence.address === item.address
          })}
        />
      )
    case 'audio':
      return (
        <audio style={{'outline': 'none'}} src={evidence.address} controls="controls">
          你的浏览器不支持音频播放
        </audio>
      )
    case 'video':
      return (
        <video
          style={{ width: size || 240, height: size || 240, outline: 'none'}}
          src={evidence.address}
          controls="controls"
        >
          你的浏览器不支持视频播放
        </video>
      )
    default:
      return (
        <>
          文件格式不支持预览
          <br/>
          (perfix error)
        </>
      )
  }
}