import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'

function QuestionAdd(props) {
  const [types, setTypes] = useState([])
  useEffect(() => {
    axios.postString(`question/getType/question`).then(res => {
      setTypes(res.map(item => {
        return {
          value: item.value,
          label: item.name
        }
      }))
    }).catch(() => {
      props.history.goBack()
    })
  }, [])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={[
          {
            name: 'title',
            label: '标题'
          },
          {
            name: 'type',
            label: '咨询类型',
            type: 'select',
            options: types
          },
          {
            name: 'content',
            type: 'textArea',
            label: '简介'
          }
        ]}
        action='question/quesAdd'
        initialValues={{}}
      />
    </>
  )
}

export default withRouter(QuestionAdd)
