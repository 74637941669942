import React from 'react'
import { Badge, Statistic, Form, InputNumber } from 'antd'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { caseStateText, companyReasonTypes, caseType } from '../../config/types'
import { system } from '../../config/system'
import cookie from 'js-cookie'
import Appoint from "pages/case/appoint";

function CaseQuery(props) {
  const token = cookie.get('token')
  const userType = cookie.get('userType')
  const isLaywer = userType === 'Lawyer'
  const isOrganization = userType === 'Organization'
  const isAdmin = userType === 'Admin'
  const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
  const columns = [
    {
      title: '案件名称',
      dataIndex: 'name'
    },

    {
      title: '案件类型',
      dataIndex: 'type'
    },
    {
      title: '公益机构',
      dataIndex: 'orgAgencyName'
    },
    {
      title: '案号',
      dataIndex: 'caseNo',
      condition: () => !sf
    },
    // {
    //   title: '律所案号',
    //   dataIndex: 'caseNo'
    // },
    {
      title: '状态',
      dataIndex: 'state',
      render: state => {
        return (
          <Badge color={(caseStateText[state.toString()] || {}).color} text={(caseStateText[state.toString()] || {}).label} />
        )
      }
    },
    {
      title: '当事人',
      dataIndex: 'accuserNames'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    },
    {
      title: '办案律师',
      dataIndex: 'lawyerName',
      render: lawyerName => lawyerName.toString()
    }
  ]
  const search = {
    extra: [
       {
         label: '新建案件',
         icon: <PlusOutlined/>,
         onClick() {
           props.history.push('add')
         },
         condition: () => isLaywer && sf
       }
    ],
    params: [
      {
        name: 'title',
        label: '案件名称'
      },
      {
        name: 'accuserName',
        label: '我方当事人'
      },
      {
        name: 'duifangName',
        label: '对方当事人'
      },
      {
        name: 'lawyerName',
        label: '办案律师'
      },
      {
        name: 'caseType',
        label: '案件类型',
        type: 'cascader',
        options: caseType
      },
      {
        name: 'state',
        label: '状态',
        type: 'select',
        options: Object.entries(caseStateText).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      },
      {
        name: 'stime,etime',
        label: '办案时间',
        type: 'rangeDate'
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: `detail/:id`,
      target: '_blank'
    },
    {
      type: 'link',
      label: '卷宗目录',
      url: `archive/:id`,
      condition: row => isLaywer && row.state === 90
    },

    // {
    //   type: 'link',
    //   label: '证据管理',
    //   url: 'evidence/:id',
    //   condition: row => isLaywer && row.state > 1
    // },
    // {
    //   type: 'link',
    //   label: '文书管理',
    //   url: 'document/:id',
    //   condition: row => isLaywer && row.state > 1
    // },
    {
      type: 'audit',
      label: '机构受理',
      width: 700,
      defaultData: row => {
        return {
          id: row.id
        }
      },
      params: [
        {
          name: 'state',
          type: 'radio',
          options: [
            {
              value: '20',
              label: '同意受理'
            },
            {
              value: '11',
              label: '不同意受理'
            }
          ],
          label: '受理结果',
          onChange: (customModalForm) => {
            // modal.update({
            //   content: '2'
            // })
            customModalForm.props.formRef.current.setFieldsValue({
              'reason': ''
            })
            customModalForm.refresh()
          }
        },
        // {
        //   name: 'lawyerId',
        //   type: 'autoComplete',
        //   label: '指派律师',
        //   getValue: item => {
        //     return item.userReal.name
        //   },
        //   defaultData: {
        //     type: 2
        //   },
        //   api: 'lawyer/query',
        //   searchName: 'name',
        // },
        {
          name: 'reason',
          label: '同意理由',
          options: companyReasonTypes.map(item => {
            return {
              value: item.label,
              label: item.label
            }
          }),
          optionType: 'default',
          type: 'radio',
          condition: formRef => {
            return formRef.current && formRef.current.getFieldValue('state') === '20'
          }
        },
        {
          name: 'reason',
          label: '不同意理由',
          type: 'textArea',
          condition: formRef => formRef.current && formRef.current.getFieldValue('state') === '11'
        }
      ],
      // defaultTo: {
      //   state: 20
      // },
      api: `${sf}/case/reason`,
      condition: row => isOrganization && ['10'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '指派律师',
      getModalForm: (formRef, row) => (
        <Appoint
          formRef={formRef}
          row={row}
          sf={sf}
        />
      ),
      api: `${sf}/case/addLawyer`,
      defaultData: row => {
        return {
          caseId: row.id
        }
      },
      params: [
        {
          name: 'realIds'
        }
      ],
      defaultTo: {
        state: 30
      },
      condition: row => isOrganization && ['20', '30', '40'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '同意受理',
      api: `${sf}/case/edit`,
      defaultData: row => {
        return {
          state: 40,
          id: row.id
        }
      },
      condition: row => isLaywer && ['30'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '法院受理',
      api: `${sf}/case/accept`,
      defaultData: row => {
        return {
          id: row.id
        }
      },
      params: [
        {
          name: 'no',
          label: '法院案件编号'
        },
        {
          name: 'acceptDate',
          label: '受理日期',
          type: 'date'
        },
        {
          name: 'url',
          label: '受理通知书',
          folderPath: `${sf}/case/:id/`,
          limit: 100,
          type: 'uploadFile'
        }
      ],
      defaultTo: {
        state: 50
      },
      condition: row => isLaywer && ['40'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '上传开庭通知书',
      api: `${sf}/case/kaiting`,
      defaultData: row => {
        return {
          id: row.id
        }
      },
      params: [
        {
          name: 'date',
          label: '开庭日期',
          type: 'date'
        },
        {
          name: 'url',
          label: '开庭通知书',
          folderPath: `${sf}/case/:id/`,
          limit: 100,
          type: 'uploadFile'
        }
      ],
      defaultTo: {
        state: 60
      },
      condition: row => isLaywer && ['50'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '上传判决通知书',
      api: `${sf}/case/pj`,
      defaultData: row => {
        return {
          id: row.id
        }
      },
      params: [
        {
          name: 'date',
          label: '判决日期',
          type: 'date'
        },
        {
          name: 'url',
          label: '判决通知书',
          folderPath: `${sf}/case/:id/`,
          limit: 100,
          type: 'uploadFile'
        }
      ],
      defaultTo: {
        state: 70
      },
      condition: row => isLaywer && ['60'].includes(row.state.toString())
    },
    {
      type: 'link',
      label: '结案录入',
      url: `${sf}/case/caseClose/:id`,
      condition: row => isLaywer && ['70'].includes(row.state.toString())
    },
    {
      type: 'link',
      label: '结案审查',
      url: `${sf}/case/caseEnd/:id`,
      condition: row => isOrganization && ['80'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '援金分配',
      api: `${sf}/account/fp`,
      defaultData: row => {
        return {
          id: row.id,
        }
      },
      getData: (values, row) => {
        let json = []
        row.lawyerList.forEach((lawyer, index) => {
          json.push({
            realId: lawyer.uid,
            amount: values['json_' + index]
          })
        })
        return {
          caseId: row.id,
          lawyerAmounts: JSON.stringify(json)
        }
      },
      method: 'post',
      params: [
        {
          name: 'lawyerAmounts',
          label: '总金额',
          getValue: row => {
            return (
              <Statistic
                valueStyle={{ fontSize: '14px' }}
                prefix={'¥'}
                valueRender={() => {
                  return (row.caseHelp.amount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }}
              />
            )
          },
          type: 'text'
        },
        {
          render: (row) => {
            return row.lawyerList.map((lawyer, index) => {
              return (
                <Form.Item
                  key={'render_amount_' + index}
                  name={'json_' + index}
                  label={lawyer.userReal.name}
                  rules={
                    index + 1 === row.lawyerList.length ? [
                      ({ getFieldValue }) => ({
                        validator() {
                          let totalAmount = 0
                          row.lawyerList.forEach(lawyer => {
                            totalAmount += getFieldValue('json_' + index) || 0
                          })
                          if (!totalAmount) {
                            return Promise.reject(`请分配援金`)
                          } else if (totalAmount > row.caseHelp.amount) {
                            return Promise.reject(`分配总金额（${totalAmount}）不能超过总援金`)
                          }
                          return Promise.resolve()
                        }
                      })
                    ] : []
                  }
                >
                  <InputNumber
                    formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    allowClear
                    max={row.caseHelp.amount}
                    min={0}
                    step={100}
                    placeholder={'请输入分配金额'}
                    style={{ width: '100%'}}
                  />
                </Form.Item>
              )
            })
          }
        }
      ],
      defaultTo: {
        grantState: 1
      },
      condition: row => isOrganization && row.isAid == 1 && row.grantState != '1' && ['90'].includes(row.state.toString())
    },
    {
      type: 'download',
      label: '证据下载',
      url: `${system.download}/${sf}/caseEvidence/down/:id?token=${token}`
    },
    {
      type: 'download',
      label: '基金会模板下载',
      url: `${system.download}/${sf}/case/export/:id?token=${token}`,
      condition: row => isOrganization && row.isAid == 1  && ['90'].includes(row.state.toString())
    },
    {
      type: 'delete',
      api: `${sf}/case/del/:id`,
      condition: row => isAdmin
      // dataName: 'id'
    }
  ]
  let rowSelection = {
    // getCheckboxProps: row => ({
    //   disabled: row.state !== 90, // Column configuration not to be checked
    // }),
    buttons: [
      // {
      //   name: '批量导出',
      //   onClick: selectedRowKeys => {
      //      window.open(`${system.download}/case/batchExport?${selectedRowKeys.map(item => `caseId=${item}`).join('&')}&token=${token}`)
      //   }
      // },
      {
        name: '并入群体案件',
        onClick: selectedRowKeys => {

        },
        condition: () => !sf
      }
    ]
  }
  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      columns={columns}
      search={search}
      actions={actions}
      api={`${sf}/case/query`}
      // rowSelection={rowSelection}
    />
    </>
  )
}
export default withRouter(CaseQuery)
