/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import { aidType, undertakeResult, helpTypes } from '../../../config/types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import axios from '../../../request/axios'

function CaseCloseAdd(props) {
  const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
  const formData = [
    {
      name: 'type',
      type: 'select',
      options: Object.entries(aidType).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      }),
      label: '援助类型'
    },
    {
      name: 'helpType',
      label: '办案补贴类型',
      type: 'radio',
      optionType: 'default',
      options: Object.entries(helpTypes).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      name: 'undertake',
      type: 'select',
      options: Object.entries(undertakeResult).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      }),
      label: '承办结果'
    },
    {
      name: 'crossReg',
      type: 'textArea',
      label: '属于跨地区理由',
      placeholder: '不属于跨地区办理案件留空',
      rules: [{}]
    },
    {
      name: 'majorReg',
      type: 'textArea',
      label: '属于重大疑案理由',
      placeholder: '不属于重大疑难案件留空',
      rules: [{}]
    },
    {
      name: 'amount',
      type: 'number',
      label: '为受援人取得利益',
      prefix: '¥',
      precision: 2,
      min: 0
    },
    {
      name: 'pjResult',
      type: 'textArea',
      label: '案件及结果简要介绍'
    },
    {
      name: 'cbyj',
      type: 'textArea',
      label: '承办人意见'
    },
    {
      type: 'text',
      label: '承办人签名',
      name: 'qm',
      render: item => {
        return item.value ? (
          <img src={item.value} height={100} />
        ) : (
          <Tag
            icon={<ExclamationCircleOutlined />}
            color="warning"
          >
            您暂无签名图，请进去"律师全"app律师端，"我的"-"签名"录入
          </Tag>
        )
      }
    }
  ]

  const { caseId } = props.match.params
  
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    caseId && axios.postString(`${sf}/case/getQm/${caseId}`).then(res => {
      setInitialValues({
        ...res
      })
    }).catch(() => {})
  }, [caseId])
  return (
    <>
    <CustomPageHeader/>
    <CustomForm
      formData={formData}
      defaultData={{ id: caseId }}
      initialValues={initialValues}
      action={`${sf}/case/jall`}
    />
    </>
  )
}

export default withRouter(CaseCloseAdd)
