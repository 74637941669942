import React, { Component } from 'react'
import { Layout, Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { collapsedtoProps } from '../redux/switchCollapsed'
import menuList from '../config/menuList'
import logo from '../logo.png'
import './Sider.less'
import {getMatchMenuTree} from '../utils/utils';
import cookie from 'js-cookie'

class Sider extends Component {

  render() {
    const { collapsed } = this.props
    const userType = cookie.get('userType')
    const menuMap = (list,level = 1) => {
      return list.filter(menu => {
        return !menu.userTypes || menu.userTypes.includes(userType)
      }).map((menu, index) => {
        if (!menu.final) {
          //return menuMap(menu.children)
          return (
            <Menu.SubMenu
              title={menu.name}
              key={menu.to || index}
              icon={menu.icon}
            >
              {
                menuMap(menu.children, ++level)
              }
            </Menu.SubMenu>
          )
        } else {
          return (
            <Menu.Item
              key={menu.to || index}
              icon={menu.icon}
            >
              <Link to={menu.to}>
                {menu.name}
              </Link>
            </Menu.Item>
          )
        }
      })
    }
    const selectedKeys = getMatchMenuTree(this.props.history).filter(menu => menu.to).map(menu => menu.to)
    return (
      <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
        <div
          style={{'padding': !collapsed ? '20px 50px 20px 50px' : '20px 10px 20px 10px'}}
        >
          <img src={logo} alt={'logo'} width={!collapsed ? 100 : 60}/>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
        >
          {
            menuMap(menuList)
          }
        </Menu>
      </Layout.Sider>
    )
  }

}

export default withRouter(connect(collapsedtoProps)(withRouter(Sider)))
