import React from 'react'
import { Tag } from 'antd'
import { tagColors, transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'

const columns = [
  {
    title: '设备号',
    dataIndex: 'deviceId',
    width: 200
  },
  {
    title: '请求方法',
    dataIndex: 'method',
    align: 'center'
  },
  {
    title: '请求地址',
    dataIndex: 'url'
  },
  {
    title: '请求参数',
    dataIndex: 'body',
    width: 300,
    render: (json = '{}') => (
      <>
      {
        Object.entries(JSON.parse(json || '{}')).map((item, index) => {
          return (
            <Tag
              color={tagColors[index%tagColors.length]}
              key={item[0]}
              style={{'margin': '5px'}}
            >
              {`${item[0]}:${item[1]}`}
            </Tag>
          )
        })
      }
      </>
    )
  },
  {
    title: '时间',
    dataIndex: 'addDate',
    render: time => transformatTime(new Date(time))
  }
]


function ReuqestLog() {
  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      columns={columns}
      api='reqLog'
    />
    </>
  )
}
export default ReuqestLog
