import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import { transform } from '../../utils/utils'


function LawyerAdd(props) {
  const formData = [
    {
      name: 'name',
      label: '律师'
    },
    {
      name: 'agencyName',
      label: '律所名称',
      type: 'autoComplete',
      dataName: 'agencyName',
      defaultData: {
        type: 2
      },
      api: 'agency/query',
      searchName: 'agencyName',
    },
    {
      name: 'startDate',
      label: '执业开始时间',
      type: 'date'
    },
    {
      name: 'occNo',
      label: '职业证号'
    },
    {
      name: 'occPic',
      label: '职业证书图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    },
    {
      name: 'isPub',
      type: 'switch',
      label: '是否公益'
    },
    {
      name: 'memo',
      type: 'textArea',
      label: '简介'
    },
    {
      name: 'occAgePic',
      label: '职业年检图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    }
  ]
  const [initialValues, setInitialValues] = useState({})
  const id = props.match.params.id
  useEffect(() => {
    id && axios.postString(`lawyer/detail/${id}`).then(res => {
      const getValue = (item) => {
        switch (item.name) {
          case 'agencyName':
            return res.agency.agencyName
          case 'startDate':
            return transform(new Date(res.userReal.startDate), 'YYYY-MM-DD')
          case 'name':
            return res.userReal.name
          default:
            return res[item.name]
        }
      }

      setInitialValues({
        uid: res.uid,
        ...Object.assign.apply(this, formData.map(item => {
          return {
            [item.name]: getValue(item)
          }
        }))
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={initialValues}
        action='lawyer/lawerAdd'
        keyRow='uid'
      />
    </>
  )
}

export default withRouter(LawyerAdd)
