import React, { Component } from 'react'
import { Form, Input, Button, Radio, Select } from 'antd'
import SendVCode from '../components/sendVCode'
import { withRouter } from 'react-router-dom'
import axios from '../request/axios'
import { logIn } from '../utils/utils'
import QueueAnim from 'rc-queue-anim'
import './login.less'
import cookie from 'js-cookie'
import { userTypes } from '../config/types'

const formLayout = {
  labelCol: {
    span: 0
  },
  wrapperCol: {
    span: 24
  }
}

class Login extends Component{
  constructor(props) {
    super(props)
    this.state = {
      loginType: 'PSD'
    }
  }

  formRef = React.createRef()
  render() {
    const { loginType } = this.state
    const onFinish = (values) => {
      axios.postString('login', {
        ...values,
        client: 'PC',
        loginType
      }, {
        NPprogress: true
      }).then(res => {
        cookie.set('userType', values.userType)
        logIn(res, this.props.history)
      }).catch(error => {})
    }

    return (
      <div className='background'>
        <div className='box'>
          <Form
            {...formLayout}
            ref={this.formRef}
            initialValues={{userType: 'Lawyer'}}
            size='large'
            onFinish={onFinish}
          >
            <Form.Item
              name="userType"
              label="手机号"
              rules={[{ required: true }]}
            >
              <Select
                placeholder={'请选择身份'}
              >
                {
                  Object.entries(userTypes).map((item, index) => {
                    return (
                      <Select.Option value={item[0]} key={index}>我是{item[1].label}</Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="loginName"
              label="手机号"
              // defaultValue={'13588674425'}
              rules={[{ required: true }]}
            >
              <Input
                allowClear
                placeholder="请输入手机号"
              />
            </Form.Item>
            <QueueAnim
              duration={[1000, 0]}
              appear={false}
              animConfig={[
                {opacity: [1, 0], translateX: [0, 50]}
              ]}
            >
              {
                this.state.loginType === 'PSD' ? (
                  <Form.Item
                    key='psd'
                    name="password"
                    label="密码"
                    // defaultValue={'123456'}
                    // rules={[{ required: true }]}
                  >
                    <Input.Password
                      allowClear
                      placeholder="请输入密码"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    key='code'
                    name="checkCode"
                    label="验证码"
                    // rules={[{ required: true }]}
                  >
                    <SendVCode
                      type='Login'
                      vcode='checkCode'
                      mobile='loginName'
                      formRel={this.formRef}
                    />
                  </Form.Item>
                )
              }
            </QueueAnim>
            <div
              style={{ textAlign: 'right' }}

            >
              {
                this.state.loginType === 'PSD' ? (
                  <Button
                    key='psdBtn'
                    type="link"
                    size="small"
                    onClick={() => this.setState({ 'loginType': 'Code'})}
                  >
                    短信登录
                  </Button>
                ) : (
                  <Button
                    key='codeBtn'
                    type="link"
                    size="small"
                    onClick={() => this.setState({ 'loginType': 'PSD'})}
                  >
                    密码登录
                  </Button>
                )
              }
            </div>
            <Form.Item
              style={{ marginTop: '10px' }}
            >
              <Button
                block
                shape="round"
                type="primary"
                htmlType="submit"
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={'record'}>
          <a href={'http://www.beian.miit.gov.cn/'} target={'_blank'} >浙ICP备20025091号</a> 义乌市律通信息科技有限公司
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
