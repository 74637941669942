import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import cookie from 'js-cookie'
import { documentModeType } from '../../config/types'

function DocumentModeAdd(props) {
  const { agencyId, uid } = JSON.parse(cookie.get('user'))
  const formData = [
    {
      name: 'type',
      label: '模板类型',
      type: 'select',
      options: Object.entries(documentModeType).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      name: 'name',
      label: '模板名称'
    },
    // {
    //   name: 'isPub',
    //   type: 'switch',
    //   label: '是否公开'
    // },
    {
      name: 'content',
      type: 'editor',
      label: '模板内容'
    }
  ]
  const [initialValues, setInitialValues] = useState({
    //isPub: true
  })
  const id = props.match.params.id
  useEffect(() => {
    id && axios.postString(`mould/getById/${id}`).then(res => {
      setInitialValues({
        ...res
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
    <CustomPageHeader/>
    <CustomForm
      formData={formData}
      initialValues={initialValues}
      defaultData={{ agencyId, sId: uid, isPub: '1' }}
      action='mould/edit'
    />
    </>
  )
}

export default withRouter(DocumentModeAdd)
