import React, { Component } from 'react'
import { PageHeader } from 'antd'
import {Link, withRouter} from 'react-router-dom';
import { getMatchMenuTree } from '../utils/utils'
import { HomeOutlined } from '@ant-design/icons'
import './CustomPageHeader.less'
class CustomPageHeader extends Component{
  constructor(props) {
    super(props)
  }
  render() {
    const params = this.props.match.params
    const matchMenuTree = getMatchMenuTree(this.props.history)
    const routes = [{
      breadcrumbName: '',
      icon: <HomeOutlined/>,
      path: '/home'
    }].concat(matchMenuTree.map((menu, index) => {
      return Object.assign({
        breadcrumbName: menu.name
      }, index < matchMenuTree.length - 1 && menu.to ? {
        path: menu.to.split('/').map(path => {
          if (path.charAt(0) === ':') {
            return params[path.substring(1)]
          } else {
            return path
          }
        }).join('/')
      } : {})
    }))
    
    const itemRender = (route) => {
      return route.path ? (
          <Link to={route.path}>{route.icon ? route.icon : route.breadcrumbName}</Link>
      ) : route.breadcrumbName
    }
    return (
      <PageHeader
        title={this.props.title || routes[routes.length - 1].breadcrumbName}
        breadcrumb={{itemRender, routes}}
        extra={
          this.props.extra ? this.props.extra.map((item, index) => {
            return <div key={index}>{item}</div>
          }) : null
        }
      >
        {
          this.props.content
        }
      </PageHeader>
    )
  }

}
export default withRouter(CustomPageHeader)
