// 发送验证码

export const vCodeStateReady = 'VCODE_READY'
export const vCodeStateSending = 'VCODE_SENDING'
export const vCodeStateOK = 'VCODE_OK'

export const vCodeTextReady = '发送验证码'
export const vCodeTextSending = '发送中...'
export const count = 60

export const vCodeSend = 'VCODE_SEND'
export const vCodeSendOk = 'VCODE_OK'
export const vCodeCountDown = 'VCODE_COUNTDOWN'
export const vCodeCountDownEnd = 'VCODE_COUNTDOWN_END'


export const vCodetoProps = state => ({
  sendVCode: state.sendVCode
})

export const vCodeDispatch = dispatch => ({
  clickSend: () => {
    dispatch({
      type: vCodeSend
    })
  },
  sendOk: () => {
    dispatch({
      type: vCodeSendOk,
      count
    })
  },
  autoCountDown: count => {
    dispatch({
      type: vCodeCountDown,
      count
    })
  },
  countDownEnd: () => {
    dispatch({
      type: vCodeCountDownEnd
    })
  }
})

export default function sendVCode (state = {
  vCodeState: vCodeStateReady,
  vCodeText: vCodeTextReady
}, action) {
  switch (action.type) {
    case vCodeSend:
      return Object.assign({}, state, {
        vCodeState: vCodeStateSending,
        vCodeText: vCodeTextSending
      })
    case vCodeSendOk:
      return Object.assign({}, state, {
        vCodeState: vCodeStateOK,
        vCodeText: count
      })
    case vCodeCountDown:
      return Object.assign({}, state, {
        vCodeText: action.count
      })
    case vCodeCountDownEnd:
      return Object.assign({}, state, {
        vCodeState: vCodeStateReady,
        vCodeText: vCodeTextReady
      })
    default:
      return state
  }
}