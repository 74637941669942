import React from 'react'
import { Input, Select } from 'antd'

class CustomInput extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      ...Object.assign.apply(this, Object.entries(props).filter(item => {
        return typeof item[1] !== 'function'
      }).map(item => ({[item[0]]: item[1]})))
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      })
      this.onChange({currentTarget: {value: nextProps.value}})
    }
  }

  onChange = (e) => {
    this.props.father.formRef.current.setFieldsValue({
      [this.props.name]: e.currentTarget.value,
    })
    this.props.onValueChange(this, e)
  }
  tagRef = React.createRef()
  render() {
    return (
      <>
      <Input
        {...this.state}
        ref={this.tagRef}
        onChange={this.onChange}
      />
      </>
    )

  }
}

export default CustomInput