import React from 'react'
import { Avatar } from 'antd'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'


function QuestionQuery() {
  const columns = [
    {
      title: '咨询标题',
      dataIndex: 'title'
    },
    {
      title: '回复律师',
      dataIndex: 'num',
      render: (num, row) => {
        return (
          <Avatar.Group maxCount={5}>
            {
              row.picList.map((avatar, index) => {
                return (
                  <Avatar
                    key={index}
                    src={avatar}
                  />
                )
              })
            }
          </Avatar.Group>
        )
      }
    },
    {
      title: '更新时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      // {
      //   label: '咨询',
      //   icon: <PlusOutlined/>,
      //   onClick() {
      //     props.history.push('add')
      //   }
      // }
    ],
    params: [
      {
        name: 'title',
        label: '标题'
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: 'detail/:id'
    }
  ]
  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      columns={columns}
      search={search}
      actions={actions}
      api='question/myQuesList'
    />

    </>
  )
}
export default withRouter(QuestionQuery)
