import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { validateMessages } from '../config/validateConfig'
import { Row, Col, Tree, Button, message, Modal, Form, Input, Tag, Card, ConfigProvider, Popconfirm, Tooltip } from 'antd'
import * as Icon from '@ant-design/icons'
import AliyunOSSUpload from '../components/AliyunOSSUpload'
import { acceptType } from '../config/types'
import { getEvidencePreview } from '../components/factory'
import axios from '../request/axios'
import cookie from 'js-cookie'
import { withRouter } from 'react-router-dom'

class CustomEvidenceTree extends React.Component {
  constructor(props) {
    super(props)
    const userType = cookie.get('userType')
    this.state = {
      treeData: props.treeData,
      caseEvidences: props.caseEvidences,
      caseId: props.caseId,
      isLaywer: userType === 'Lawyer',
      groupDes: '',
      evidenceData: [],
      level: 0,
      activeNodeId: '',
      activeNodeTag: ''
    }
  }

  render() {
    const sf = this.props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
    return (
      <Row
        style={{ flexWrap: 'nowrap' }}
        justify='space-around'
      >
        <Col flex='300px'>
          <Tree
            showIcon
            treeData={this.state.treeData}
            onSelect={(selectedKeys, e) => {
              if (e.selected) {
                this.setState({
                    level: e.node.level,
                    groupDes: e.node.content,
                    activeNodeId: e.node.key,
                    activeNodeTag: e.node.title,
                    evidenceData: this.state.caseEvidences[e.node.key] || []
                  }
                )
              } else {
                this.setState({
                  level: 0,
                  groupDes: '',
                  activeNodeId: '',
                  activeNodeTag: '',
                  evidenceData: []
                })
              }
            }}
            titleRender={node => {
              return (
                <>
                <div
                  className={'tree-title'}
                >
                  {node.title}
                </div>
                <div
                  className={'tree-px'}
                >
                  {
                    this.state.isLaywer ? (
                      node.level < 2 ? (
                        <Tooltip title={'新增分组'} >
                          <Button
                            type={'link'}
                            onClick={e => {
                              e.stopPropagation()
                              const tempFormRef = React.createRef()
                              if (this.state.activeNodeId) {
                                Modal.info({
                                  className: 'modal-width-form',
                                  maskClosable: true,
                                  keyboard: true,
                                  title: '新增分组',
                                  content: (
                                    <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                                      <Form
                                        ref={tempFormRef}
                                      >
                                        <Form.Item
                                          name={'name'}
                                          label={'组名'}
                                          rules={[{ required: true }]}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                        <Form.Item
                                          name={'content'}
                                          label={'备注'}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                      </Form>
                                    </ConfigProvider>
                                  ),
                                  okText: '确认',
                                  cancelText: '取消',
                                  onOk: () => new Promise((resolve, reject) => {
                                    tempFormRef.current.validateFields().then(() => {
                                      var values = tempFormRef.current.getFieldsValue()
                                      axios.postString(`${sf}/caseEvidence/type/edit`, {
                                        ...values,
                                        pid: node.key,
                                        caseId: this.state.caseId
                                      }).then(id => {
                                        message.success('操作成功')
                                        this.setState({
                                          treeData: this.state.treeData.map(item => {
                                            if (item.key === this.state.activeNodeId) {
                                              item.children.push({
                                                key: id,
                                                title: values['name'],
                                                content: values['content'],
                                                level: 2,
                                                icon: <Icon.FolderOpenOutlined />
                                              })
                                            }
                                            return item
                                          })
                                        })
                                        resolve()
                                      }).catch(() => {
                                        reject()
                                      })
                                    }).catch(() => {
                                      reject()
                                    })
                                  })
                                })
                              } else {
                                message.info('请先选择身份/分组')
                              }
                            }}
                          >
                            <Icon.AppstoreAddOutlined />
                          </Button>
                        </Tooltip>

                      ) : (
                        <>
                        <Tooltip title={'上传证据'} >
                          <Button
                            type={'link'}
                            onClick={e => {
                              e.stopPropagation()
                              const tempFormRef = React.createRef()
                              if (this.state.activeNodeId) {
                                Modal.info({
                                  className: 'modal-width-form',
                                  maskClosable: true,
                                  keyboard: true,
                                  title: '上传证据',
                                  content: (
                                    <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                                      <Form
                                        ref={tempFormRef}
                                      >
                                        <Form.Item
                                          name={'image'}
                                          label={'上传图片证据'}
                                        >
                                          <AliyunOSSUpload
                                            listType='picture-card'
                                            title={'上传图片'}
                                            accept={acceptType.image}
                                            folderPath={'/case/'}
                                            limit={Infinity}
                                            onSuccess={file => {
                                              tempFormRef.current.setFieldsValue({
                                                'image': JSON.stringify(file)
                                              })
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          name={'audio'}
                                          label={'上传音频证据'}
                                        >
                                          <AliyunOSSUpload
                                            title={'上传音频证据'}
                                            folderPath={'/case/'}
                                            accept={acceptType.audio + ',' + acceptType.video + ',' + acceptType.pdf}
                                            limit={Infinity}
                                            onSuccess={file => {
                                              tempFormRef.current.setFieldsValue({
                                                'audio': JSON.stringify(file)
                                              })
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          name={'picDes'}
                                          label={'图片描述'}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                      </Form>
                                    </ConfigProvider>
                                  ),
                                  okText: '确认',
                                  cancelText: '取消',
                                  onOk: () => new Promise((resolve, reject) => {
                                    tempFormRef.current.validateFields().then(ids => {
                                      var values = tempFormRef.current.getFieldsValue()
                                      const address = JSON.parse(values.image || '[]').concat(JSON.parse(values.audio || '[]')).map(file => {
                                        return {
                                          imgUrl: file.url,
                                          picDes: values.picDes,
                                          perfix: file.type
                                        }
                                      })
                                      if (address.length) {
                                        axios.post(`${sf}/caseEvidence/evidence/add`, {
                                          address: JSON.stringify(address),
                                          tag: this.state.activeNodeTag,
                                          isSee: 1,
                                          type: this.state.activeNodeId,
                                          caseId: this.state.caseId
                                        }).then(ids => {
                                          message.success('操作成功')
                                          const newEvidence = address.map((item, index) => {
                                            return {
                                              ...item,
                                              address: item.imgUrl,
                                              tag: this.state.activeNodeTag,
                                              id: ids[index]
                                            }
                                          })
                                          const evidenceData = (this.state.caseEvidences[this.state.activeNodeId] || []).concat(newEvidence)

                                          this.setState({
                                            caseEvidences: {
                                              ...this.state.caseEvidences,
                                              [this.state.activeNodeId]: evidenceData
                                            },
                                            evidenceData
                                          })
                                          resolve()
                                        }).catch(() => {
                                          reject()
                                        })
                                      } else {
                                        message.warn('至少上传一个证据')
                                        reject()
                                      }
                                    }).catch(() => {
                                      reject()
                                    })
                                  })
                                })
                              } else {
                                message.info('请先选择身份/分组')
                              }
                            }}
                          >
                            <Icon.UploadOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip title={'修改分组'} >
                          <Button
                            type={'link'}
                            onClick={e => {
                              e.stopPropagation()
                              const tempFormRef = React.createRef()
                              if (this.state.activeNodeId) {
                                Modal.info({
                                  className: 'modal-width-form',
                                  maskClosable: true,
                                  keyboard: true,
                                  title: '修改分组',
                                  content: (
                                    <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                                      <Form
                                        ref={tempFormRef}
                                        initialValues={{
                                          name: node.title,
                                          content: node.content
                                        }}
                                      >
                                        <Form.Item
                                          name={'name'}
                                          label={'组名'}
                                          rules={[{ required: true }]}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                        <Form.Item
                                          name={'content'}
                                          label={'备注'}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                      </Form>
                                    </ConfigProvider>
                                  ),
                                  okText: '确认',
                                  cancelText: '取消',
                                  onOk: () => new Promise((resolve, reject) => {
                                    tempFormRef.current.validateFields().then(() => {
                                      var values = tempFormRef.current.getFieldsValue()
                                      axios.postString(`${sf}/caseEvidence/type/edit`, {
                                        ...values,
                                        id: node.key,
                                        pid: node.pid,
                                        caseId: this.state.caseId
                                      }).then(id => {
                                        message.success('操作成功')
                                        this.setState({
                                          treeData: this.state.treeData.map(item => {
                                            if (item.key === node.pid) {
                                              return {
                                                ...item,
                                                children: item.children.map(item => {
                                                  if (item.key === node.key) {
                                                    return {
                                                      ...item,
                                                      title: values['name'],
                                                      content: values['content']
                                                    }
                                                  } else {
                                                    return item
                                                  }
                                                })
                                              }
                                            } else {
                                              return item
                                            }
                                          }),
                                          groupDes: values['content'],
                                          activeNodeTag: values['name']
                                        })
                                        resolve()
                                      }).catch(() => {
                                        reject()
                                      })
                                    }).catch(() => {
                                      reject()
                                    })
                                  })
                                })
                              } else {
                                message.info('请先选择身份/分组')
                              }

                            }}
                          >
                            <Icon.EditOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip title={'删除分组'} >
                          <Popconfirm
                            title={`删除该分组?`}
                            icon={<Icon.CloseCircleTwoTone twoToneColor='red'/>}
                            onConfirm={() => {
                              axios.postString(`${sf}/caseEvidence/delType/${node.key}`).then(() => {
                                this.setState({
                                  treeData: this.state.treeData.map(item => {
                                    if (item.key === node.pid) {
                                      return {
                                        ...item,
                                        children: item.children.filter(item => {
                                          return item.key !== node.key
                                        })
                                      }
                                    } else {
                                      return item
                                    }
                                  }),
                                  level: 0,
                                  groupDes: '',
                                  activeNodeId: '',
                                  activeNodeTag: '',
                                  evidenceData: []
                                })
                              }).catch(() => {})
                            }}
                          >
                            <Button
                              type={'link'}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <Icon.CloseCircleOutlined />
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                        {
                          this.props.batchId ? 
                          <Tooltip title={'同步证据'} >
                            <Popconfirm
                              title={`同步该证据文件夹到群体案件其他子案件?`}
                              icon={<Icon.QuestionCircleOutlined twoToneColor='blue'/>}
                              onConfirm={() => {
                                axios.postString(`${sf}/batch/tb/zj/${this.props.batchId}?zjId=${node.key}`).then(() => {
                                  message.success('文件夹证据同步成功')
                                }).catch(() => {})
                              }}
                            >
                              <Button
                                type={'link'}
                                onClick={e => {
                                  e.stopPropagation()
                                }}
                              >
                                <Icon.SyncOutlined />
                              </Button>
                            </Popconfirm>
                          </Tooltip>
                          : ''
                        }
                        
                        </>
                      )
                    ) : ''
                  }
                </div>
                </>
              )
            }}
          />
        </Col>
        <Col
          flex='auto'
        >

          {
            this.state.groupDes
            ? (
                <div className={'align-left'} style={{ marginBottom: 20 }}><Tag color="orange">{this.state.groupDes}</Tag></div>
              )
            : ''
          }
          <Row
            gutter={[20, 20]}
          >
            {
              this.state.evidenceData
                ? this.state.evidenceData.map((evidence, index) => {
                  return (
                    <Col
                      key={index}
                      span={6}
                    >
                      <Card
                        style={{ width: 240 }}
                        title={evidence.tag}
                        size="small"
                        extra={(
                          <Popconfirm
                            title={`是否确认删除此证据?`}
                            icon={<Icon.CloseCircleTwoTone twoToneColor='red'/>}
                            onConfirm={() => {
                              axios.postString(`${sf}/caseEvidence/del`, {
                                id: `${evidence.id}`
                              }).then(() => {
                                message.success(`删除成功`)
                                this.setState({
                                  evidenceData: this.state.evidenceData.filter((item, i) => i!==index)
                                })
                              }).catch(() => {})
                            }}
                          >
                            <Button
                              type={'link'}
                            >
                              删除
                            </Button>
                          </Popconfirm>
                        )}
                        cover={
                          <div
                            style={{ width: 240, height: 240, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' }}
                          >
                            {
                              getEvidencePreview(evidence, this.state.evidenceData)
                            }
                          </div>
                        }
                      >
                        <Card.Meta description={evidence.picDes} />
                      </Card>
                    </Col>
                  )
                })
                : ''
            }
          </Row>
        </Col>
      </Row>
    )
  }
}

export default withRouter(CustomEvidenceTree)