import React, { Component } from 'react'
import { Input, Button } from 'antd'
import { connect } from 'react-redux'
import { vCodetoProps, vCodeDispatch, vCodeStateReady, count } from '../redux/sendVCode'
import axios from '../request/axios'
import './sendVCode.less'

class SendVCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value : ''
    }
  }
  render() {
    const { sendVCode, clickSend, sendOk, autoCountDown, countDownEnd } = this.props
    const onChange = e => {
      this.props.formRel.current.setFieldsValue(
        {
          [this.props.vcode]: e.currentTarget.value
        }
      )
    }
    let t
    let countDown = count
    const send = () => {
      clickSend()
      const mobile = this.props.formRel.current.getFieldsValue()[this.props.mobile]
      const type = this.props.type
      axios.postString('send', {
        mobile,
        type
      }).then(() => {
        sendOk()
        t = setInterval(() => {
          if (-- countDown) {
            autoCountDown(countDown)
          } else {
            clearInterval(t)
            countDownEnd()
          }
        }, 1000)
      }).catch(() => {
        countDownEnd()
      })
    }
    return (
      <Input
        className='sendVCode'
        onChange={onChange}
        addonAfter={
          (
            <Button
              disabled={sendVCode.vCodeState !== vCodeStateReady}
              type="primary"
              onClick={send}
            >{sendVCode.vCodeText}</Button>
          )
        }
        placeholder="请输入验证码"
      />
    )
  }
}

export default connect(
  vCodetoProps,
  vCodeDispatch,
  null, { forwardRef: true }
)(SendVCode)
