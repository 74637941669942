import React from 'react'
import { Layout } from 'antd'
import { Route, Switch } from 'react-router-dom'
import Index from '../pages/index'
import Census from '../pages/census'
import RequestLog from '../pages/logger/requestLog'
import OpLog from '../pages/logger/opLog'

import AgencyQuery from '../pages/agency/query'
import AgencyAdd from '../pages/agency/add'
import AgencyDetail from '../pages/agency/detail'

import LawyerQuery from '../pages/lawyer/query'
import LawyerAdd from '../pages/lawyer/add'
import LawyerDetail from '../pages/lawyer/detail'

import NoticeQuery from '../pages/notice/query'
import NoticeAdd from '../pages/notice/add'
import NoticeDetail from '../pages/notice/detail'

import CaseQuery from '../pages/case/query'
import CaseAdd from '../pages/case/add'
import CaseDetail from '../pages/case/detail'
// import EvidenceQuery from '../pages/case/evidence/query'
// import EvidenceDetail from '../pages/case/evidence/detail'
// import DocumentQuery from '../pages/case/document/query'
// import DocumentAdd from '../pages/case/document/add'
import ArchiveQuery from '../pages/case/archive/query'
import DocumentModeQuery from '../pages/documentMode/query'
import DocumentModeAdd from '../pages/documentMode/add'
import CaseCloseAdd from '../pages/case/caseClose/add'
import CaseEndAdd from '../pages/case/caseEnd/add'
import CaseExport from '../pages/case/export'
import GroupCaseQuery from '../pages/case/groupCase/query'
import GroupCaseDetail from '../pages/case/groupCase/detail'
import GroupCaseList from '../pages/case/groupCase/list'




import QuestionQuery from '../pages/question/query'
import QuestionAdd from '../pages/question/add'
import QuestionDetail from '../pages/question/detail'

import legalAidQuery from '../pages/legalAid/query'

import CenterSet from '../pages/center/set'
import CenterApply from '../pages/center/apply'
import CenterAccount from '../pages/center/account'
import CenterCashOutRecord from '../pages/center/cashOutRecord'
import CenterPassword from '../pages/center/password'

import CenterLawyerSet from '../pages/center/lawyerSet'

import UserQuery from '../pages/user/query'
import UserDetail from '../pages/user/detail'

import LawRulesTree from '../pages/lawRules/tree'

import LegalTypeTree from '../pages/legalType/tree'

import ModelTextQuery from '../pages/modelText/query'

import CashApplyQuery from '../pages/cashApply/query'

import AdviseQuery from '../pages/advise/query'
import AdviseDetail from '../pages/advise/detail'
function Content () {
  return (
    <Layout.Content>
      <Switch>
        <Route exact path="/index" component={Index} />
        {/*日志管理*/}
        <Route exact path="/home" component={Census} />
        {/*日志管理*/}
        <Route exact path="/logger/requestLog" component={RequestLog} />
        <Route exact path="/logger/opLog" component={OpLog} />
        {/*机构管理*/}
        <Route exact path="/agency/query" component={AgencyQuery} />
        <Route exact path="/agency/add" component={AgencyAdd} />
        <Route exact path="/agency/edit/:id" component={AgencyAdd} />
        <Route exact path="/agency/detail/:id" component={AgencyDetail} />
        {/*事务所管理*/}
        <Route exact path="/lawFirm/query" component={AgencyQuery} />
        <Route exact path="/lawFirm/add" component={AgencyAdd} />
        <Route exact path="/lawFirm/edit/:id" component={AgencyAdd} />
        <Route exact path="/lawFirm/detail/:id" component={AgencyDetail} />
        {/*事务所管理*/}
        <Route exact path="/lawyer/query" component={LawyerQuery} />
        <Route exact path="/lawyer/add" component={LawyerAdd} />
        <Route exact path="/lawyer/edit/:id" component={LawyerAdd} />
        <Route exact path="/lawyer/detail/:id" component={LawyerDetail} />
        {/*公告管理*/}
        <Route exact path="/notice/query" component={NoticeQuery} />
        <Route exact path="/notice/add" component={NoticeAdd} />
        <Route exact path="/notice/edit/:id" component={NoticeAdd} />
        <Route exact path="/notice/detail/:id" component={NoticeDetail} />
        {/*法律援助*/}
        <Route exact path="/case/query" component={CaseQuery} />
        <Route exact path="/case/add" component={CaseAdd} />
        <Route exact path="/case/edit/:id" component={CaseAdd} />
        <Route exact path="/case/detail/:caseId" component={CaseDetail} />
        
        {/* <Route exact path="/case/evidence/:caseId" component={EvidenceQuery} />
        <Route exact path="/case/evidence/:caseId/detail/:evidenceId" component={EvidenceDetail} />
        <Route exact path="/case/document/:caseId" component={DocumentQuery} />
        <Route exact path="/case/document/:caseId/add" component={DocumentAdd} />
        <Route exact path="/case/document/:caseId/edit/:documentId" component={DocumentAdd} /> */}
        <Route exact path="/case/archive/:caseId" component={ArchiveQuery} />
        <Route exact path="/case/caseClose/:caseId" component={CaseCloseAdd} />
        <Route exact path="/case/caseEnd/:caseId" component={CaseEndAdd} />
        <Route exact path="/case/export" component={CaseExport} />
        {/*群体案件*/}
        <Route exact path="/case/groupCase/query" component={GroupCaseQuery} />
        <Route exact path="/case/groupCase/detail" component={GroupCaseDetail} />
        <Route exact path="/case/groupCase/list/:batchInfo" component={GroupCaseList} />
        <Route exact path="/case/groupCase/:batchInfo/edit/:caseId" component={CaseDetail} />


        {/*收费案件*/}
        <Route exact path="/sf/case/query" component={CaseQuery} />
        <Route exact path="/sf/case/add" component={CaseAdd} />
        <Route exact path="/sf/case/edit/:id" component={CaseAdd} />
        <Route exact path="/sf/case/detail/:caseId" component={CaseDetail} />
        <Route exact path="/sf/case/archive/:caseId" component={ArchiveQuery} />
        <Route exact path="/sf/case/caseClose/:caseId" component={CaseCloseAdd} />
        <Route exact path="/sf/case/caseEnd/:caseId" component={CaseEndAdd} />




        <Route exact path="/documentMode/query" component={DocumentModeQuery} />
        <Route exact path="/documentMode/add" component={DocumentModeAdd} />
        <Route exact path="/documentMode/edit/:id" component={DocumentModeAdd} />

        {/*我的咨询*/}
        <Route exact path="/question/query" component={QuestionQuery} />
        <Route exact path="/question/add" component={QuestionAdd} />
        <Route exact path="/question/detail/:id" component={QuestionDetail} />
        {/*法律援助*/}
        <Route exact path="/legalAid/query" component={legalAidQuery} />
        {/*个人中心*/}
        <Route exact path="/center/set" component={CenterSet} />
        <Route exact path="/center/apply" component={CenterApply} />
        <Route exact path="/center/account" component={CenterAccount} />
        <Route exact path="/center/cashOutRecord" component={CenterCashOutRecord} />
        <Route exact path="/center/lawyerSet" component={CenterLawyerSet} />
        <Route exact path="/center/password" component={CenterPassword} />
        {/*用户管理*/}
        <Route exact path="/user/query" component={UserQuery} />
        <Route exact path="/user/detail/:id" component={UserDetail} />

        {/*法律法规*/}
        <Route exact path="/lawRules/tree" component={LawRulesTree} />

        {/*法律文书*/}
        <Route exact path="/legalType/tree" component={LegalTypeTree} />

        {/*范文管理*/}
        <Route exact path="/modelText/query" component={ModelTextQuery} />

        {/*提现申请*/}
        <Route exact path="/cashApply/query" component={CashApplyQuery} />

        {/*投诉建议*/}
        <Route exact path="/advise/query" component={AdviseQuery} />
        <Route exact path="/advise/detail/:id" component={AdviseDetail} />
      </Switch>
    </Layout.Content>
  )
}
export default Content
