import React from 'react'

class Index extends React.Component{
  constructor(props) {
    super(props)

  }
  componentDidMount() {

  };
  render() {
    return (
      <div>
        index
      </div>
    )
  }
}
export default Index
