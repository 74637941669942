/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import { helpTypes } from '../../../config/types'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import axios from '../../../request/axios'
function CaseEndAdd(props) {
  const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
  const formData = [
    {
      name: 'overResult',
      type: 'textArea',
      label: '不同意结案理由',
      placeholder: '如若不同意结案请填写不同意结案理由，同意留空',
      rules: [{}]
    },
    {
      name: 'amount',
      type: 'number',
      label: '申请补贴金额',
      prefix: '¥',
      precision: 2,
      min: 0
    },
    {
      name: 'helpType',
      type: 'radio',
      optionType: 'default',
      label: '办案补贴类型',
      options: Object.entries(helpTypes).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      type: 'text',
      label: '机构盖章',
      name: 'qmPic',
      render: item => {
        return item.value ? (
          <img src={item.value} height={150}/>
        ) : (
          <Tag
            icon={<ExclamationCircleOutlined />}
            color="warning"
          >
            您暂无机构盖章
          </Tag>
        )
      }
    },
    {
      type: 'text',
      label: '承办人签名',
      name: 'qm',
      render: item => {
        return item.value ? (
          <img src={item.value} height={100} />
        ) : (
          <Tag
            icon={<ExclamationCircleOutlined />}
            color="warning"
          >
            您暂无签名图，请进去"律师全"app律师端，"我的"-"签名"录入
          </Tag>
        )
      }
    }
  ]

  const { caseId } = props.match.params

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    caseId && axios.postString(`${sf}/case/getQm/${caseId}`).then(res => {
      setInitialValues({
        ...res
      })
    }).catch(() => {})
  }, [caseId])
  return (
    <>
    <CustomPageHeader/>
    <CustomForm
      formData={formData}
      defaultData={{ id: caseId }}
      initialValues={initialValues}
      action={`${sf}/case/end`}
    />
    </>
  )
}

export default withRouter(CaseEndAdd)
