import React from 'react'
import { Typography } from 'antd'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'
import AskList from './AskList'
import { transformatTime } from '../../utils/utils'

class QuestionDetail extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      detail: {},
      title: ''
    }
  }

  componentDidMount() {
    const questionId = this.props.match.params.id
    if (questionId) {
      axios.postString(`question/quesDetail`, {
        questionId
      }).then(res => {
        this.setState({
          detail: {
            ...res
          },
          title: res.content,
          createTime: res.createTime
        })
      }).catch(() => {
        this.props.history.goBack()
      })
    } else {
      this.props.history.goBack()
    }
  }

  render() {
    // console.log('question.detail.render()')
    const questionId = this.props.match.params.id
    return (
      <>
      <CustomPageHeader
        title={'提问：' + this.state.title}
        // content={
        //   <>
        //     <Typography.Paragraph>
        //       {this.state.title}
        //     </Typography.Paragraph>
        //     <div className='c999 align-right'>{transformatTime(new Date(this.state.createTime))}</div>
        //   </>
        // }
      />
      <AskList
        detail={this.state.detail}
        api={'question/quesDetail'}
        defaultData={questionId}
      >
      </AskList>
      </>
    )
  }


}

export default QuestionDetail
