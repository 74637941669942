import React from 'react'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { modelTextType, acceptType } from '../../config/types'

function modelTextQuery(props) {
  const columns = [
    {
      title: '范本标题',
      dataIndex: 'title',
      align: 'center'
    },
    {
      title: '范本类型',
      dataIndex: 'type',
      align: 'center',
      render: type => modelTextType[type]
    }
  ]
  const search = {
    extra: [
      {
        type: 'modal',
        label: '上传范本',
        api: 'wenshu/wenshuUpload',
        params: [
          {
            name: 'title',
            label: '标题'
          },
          {
            name: 'type',
            label: '类型',
            type: 'select',
            options: Object.entries(modelTextType).map(item => {
              return {
                value: item[0],
                label: item[1]
              }
            })
          },
          {
            name: 'uploadFile',
            label: '范本',
            type: 'uploadFile',
            accept: acceptType.word,
            folderPath: '/modelText/',
            limit: 1
          }
        ]
      }
    ],
    params: [
      {
        name: 'title',
        label: '标题'
      },
      {
        name: 'type',
        label: '类型',
        type: 'select',
        options: Object.entries(modelTextType).map(item => {
          return {
            value: item[0],
            label: item[1]
          }
        })
      }
    ]
  }
  const actions = [
    {
      type: 'delete',
      api: 'wenshu/del/:id'
    },
    {
      type: 'download',
      label: '下载',
      url: 'url'
    },
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        api='wenshu/query'
      />

    </>
  )
}
export default withRouter(modelTextQuery)
