import Axios from 'axios'
import { message, Modal } from 'antd'
import { stringify } from 'qs'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import cookie from 'js-cookie'
import { toLogin } from '../utils/utils'

NProgress.configure({ showSpinner: false })

const timeout = 30000
const axios = Axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'deviceId': 'PC'
  },
  baseURL: '/api',
  timeout
})
export default {
  get (api, data) {
    return axios.get(api, {params: data})
  },
  post (api, data = {}, config = {}) {
    return axios.post(api, data, Object.assign({
      headers: {
        'Content-Type': 'application/json'
      }
    }, config))
  },
  postString (api, data, config = {}) {
    return axios.post(api, stringify(data), config)
  },

  spread: Axios.spread
}

// 请求拦截器
axios.interceptors.request.use(
  config => {
    config.NPprogress && NProgress.start()
    if (cookie.get('token')) {
      config.headers['token'] = cookie.get('token')
    }

    Object.assign(config.headers, {
      t: new Date().getTime()
    })
    return config
  },
  error => {
    NProgress.done()
    message.error(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  response => {
    NProgress.done()
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const { state, content } = dataAxios
    if (state === 'success') {
      return content
    } else if (state === 'error') {
      message.error(content)
      return Promise.reject(content)
    } else if (state === 'login') {
      let secondsToGo = 5
      const modal = Modal.warning({
        title: '登录过期',
        content: `您的登录已过期， ${secondsToGo} 秒后自动跳转到登录界面.`,
        okText: '立即登录',
        onOk: () => {
          toLogin()
        }
      })
      setInterval(() => {
        modal.update({
          content: `您的登录已过期， ${secondsToGo} 秒后自动跳转到登录界面.`,
        })
        if (!--secondsToGo) {
          toLogin()
        }
      }, 1000)
    } else if (state === 'real') {
      message.error(content)
      return Promise.reject(content)
    } else if (state === 'exception') {
      message.error(content)
      return Promise.reject(content)
    }
    // // 根据 code 进行判断
    // if (code === undefined) {
    //   return dataAxios
    // } else {
    //   // 有 code 代表这是一个后端接口 可以进行进一步的判断
    //   if ([1].includes(code)) { // 请求成功的code集合
    //     return dataAxios.obj
    //   } else if ([-2].includes(code)) { // 需要重新登录的code集合
    //     sessionStorage.clear()
    //     cookie.remove('uuid')
    //     cookie.remove('JSESSIONID')
    //     if (location.hash.substr(1) && location.hash.substr(1) !== '/') {
    //       cookie.set('redirects', location.hash.substr(1))
    //     }
    //     location.href = staticData.baseURL + '/user/departmentlogin/oa/'
    //   } else {
    //     message.error(dataAxios)
    //     return Promise.reject(dataAxios)
    //   }
    // }
    return dataAxios
  },
  error => {
    NProgress.done()
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.msg = '请求错误'
          break
        case 401:
          error.msg = '未授权，请登录'
          break
        case 403:
          error.msg = '拒绝访问'
          break
        case 404:
          error.msg = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.msg = '请求超时'
          break
        case 500:
          error.msg = '服务器内部错误'
          break
        case 501:
          error.msg = '服务未实现'
          break
        case 502:
          error.msg = '网关错误'
          break
        case 503:
          error.msg = '服务不可用'
          break
        case 504:
          error.msg = '网关超时'
          break
        case 505:
          error.msg = 'HTTP版本不受支持'
          break
        default:
          error.msg = '请求错误'
          break
      }
    }
    message.error(error.message)
    return Promise.reject(error.message)
  }
)
