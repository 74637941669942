// 切换菜单

export const actionType = 'COLLAPSED_TOGGLE'

export const collapsedtoProps = state => ({
  collapsed: state.switchCollapsed
})

export const collapsedDispatch = dispatch => ({
  switchCollapsed: () => {
    dispatch({
      type: actionType
    })
  }
})

export default function switchCollapsed (collapsed = false, action) {
  switch (action.type) {
    case actionType:
      return !collapsed
    default:
      return collapsed
  }
}