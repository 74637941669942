import React from 'react'
import E from 'wangeditor'

// import 'braft-editor/dist/index.css'
// import BraftEditor from 'braft-editor'
// import { Editor } from '@tinymce/tinymce-react'

class CustomEditor extends React.Component {
  constructor(props) {
    super(props)
    // this.state = {
    //   editorState: BraftEditor.createEditorState(props.value), // 设置编辑器初始内容
    // }
    this.state = {
      editor: null,
      value: props.value
    }
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.props.value) {
      this.state.editor.txt.html(nextProps.value)
    }
  }
  componentDidMount() {
    const elemMenu = this.refs.editorElemMenu;
    const elemBody = this.refs.editorElemBody;
    const editor = new E(elemMenu,elemBody)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
    editor.customConfig.onchange = () => {
      this.props.father.formRef.current && this.props.father.formRef.current.setFieldsValue({
        [this.props.name]: editor.txt.html()
      })
    }
    editor.customConfig.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      // 'quote',  // 引用
      // 'emoticon',  // 表情
      // 'image',  // 插入图片
      'table',  // 表格
      // 'video',  // 插入视频
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    editor.customConfig.uploadImgShowBase64 = true
    editor.create()
    editor.txt.html(this.state.value)
    this.setState({
      editor
    })
  }
  render() {
    return (
      <div className="shop">
        <div className="text-area" >
          <div ref="editorElemMenu"
               style={{backgroundColor:'#fff',border:"1px solid #ccc"}}
               className="editorElem-menu">
          </div>
          <div
            style={{
              padding:"0 10px",
              overflowY:"scroll",
              backgroundColor: '#fff',
              height:300,
              border:"1px solid #ccc",
              borderTop:"none"
            }}
            ref="editorElemBody" className="editorElem-body"
          >
          </div>
        </div>
      </div>
    )
  }

}

export default CustomEditor