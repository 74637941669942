import React, { useEffect, useState } from 'react'
import { Row, Col, Statistic, Badge } from 'antd'
import { MoneyCollectOutlined } from '@ant-design/icons'
import { transformatTime } from '../../utils/utils'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { balanceTypes } from '../../config/types'
import validation from '../../config/validation'


function Account(props) {
  const [balance, setBalance] = useState(0)
  useEffect(() => {
    axios.postString(`account/my`).then(res => {
      setBalance(res.amount2 || 0)
    }).catch(e => {})
  }, [])
  const columns = [
    {
      title: '描述',
      dataIndex: 'memo'
    },
    {
      title: '收入/支出',
      dataIndex: 'amount',
      render: (amount, row) => {
        return (
          <Statistic
            prefix={balanceTypes[row.type || 1].prefix}
            valueStyle={{ fontSize: '14px', color: balanceTypes[row.type || 1].color }}
            value={amount}
          />
        )
      },
      align: 'center'
    },
    {
      title: '余额',
      dataIndex: 'balance',
      align: 'center'
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      {
        label: '提现',
        icon: <MoneyCollectOutlined />,
        type: 'modal',
        api: 'cash/apply',
        defaultData: {
          amount2: balance
        },
        params: [
          {
            label: '金额',
            name: 'amount2',
            type: 'text',
            getValue: () => `¥ ${balance}`
          },
          {
            label: '银行卡号',
            name: 'bankId',
            type: 'input',
            maxLength: 18,
            rules: [{required: true }, { ...validation.bankNo, validateTrigger: ['onBlur'] }]
          },
          {
            label: '银行名称',
            name: 'bankName'
          },
          {
            label: '持卡人姓名',
            name: 'bankUserName'
          }

        ],
        onHandleOk: () => {
          props.history.push('cashOutRecord')
          // setBalance(parseFloat(balance) - parseFloat(values.amount2))
        }
      }
    ],
    params: [
      {
        name: 'type',
        label: '类型',
        type: 'select',
        options: Object.entries(balanceTypes).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      }
    ]
  }

  return (
    <>
    <CustomPageHeader
      content={
        <Row
          justify='end'
        >
          <Col flex={'200px'}>
            <Statistic
              title="余额"
              prefix="¥"
              value={ balance }
            />
          </Col>
        </Row>
      }
    />
    <CustomTable
      rowKey="id"
      api="account/flow"
      columns={columns}
      search={search}
    />
    </>
  )
}
export default withRouter(Account)
