import React from 'react'
import * as Icon from '@ant-design/icons'

export default [
  {
    name: '日志',
    userTypes: ['Admin'],
    children: [
      {
        name: '操作日志',
        to: '/logger/opLog',
        final: true
      },
      {
        name: '请求日志',
        to: '/logger/requestLog',
        final: true
      }
    ],
    icon: <Icon.CopyOutlined />
  },
  {
    name: '公益机构管理',
    to: '/agency/query',
    userTypes: ['Admin'],
    final: true,
    children: [
      {
        name: '公益机构详情',
        to: '/agency/detail'
      },
      {
        name: '新增公益机构',
        to: '/agency/add'
      },
      {
        name: '修改公益机构',
        to: '/agency/edit'
      }
    ],
    icon: <Icon.HeartOutlined />
  },
  {
    name: '律所管理',
    to: '/lawFirm/query',
    userTypes: ['Admin'],
    final: true,
    children: [
      {
        name: '律所详情',
        to: '/lawFirm/detail'
      },
      {
        name: '新增律所',
        to: '/lawFirm/add'
      },
      {
        name: '修改律所',
        to: '/lawFirm/edit'
      }
    ],
    icon: <Icon.TeamOutlined />
  },

  // {
  //   name: '公告管理',
  //   to: '/notice/query',
  //   final: true,
  //   children: [
  //     {
  //       name: '公告详情',
  //       to: '/notice/detail'
  //     },
  //     {
  //       name: '新增公告',
  //       to: '/notice/add'
  //     },
  //     {
  //       name: '修改公告',
  //       to: '/notice/edit'
  //     }c
  //   ],
  //   icon: <Icon.SnippetsOutlined />
  // },
  {
    name: '案件管理',
    userTypes: ['Lawyer', 'Organization', 'LawFirm', 'Admin'],
    children: [
      {
        name: '法律援助',
        to: '/case/query',
        userTypes: ['Lawyer', 'Organization', 'LawFirm', 'Admin'],
        final: true,
        children: [
          {
            name: '新增案件',
            to: '/case/add'
          },
          {
            name: '卷宗目录',
            to: '/case/archive/:caseId'
          },
          {
            name: '结案录入',
            to: '/case/caseClose/:caseId'
          },
          {
            name: '结案审核',
            to: '/case/caseEnd/:caseId'
          },
          {
            name: '案件详情',
            to: '/case/detail/:id'
          },
          {
            name: '修改案件',
            to: '/case/edit/:id'
          },

          
          // {
          //   name: '证据管理',
          //   to: '/case/evidence/:caseId',
          //   children: [
          //     {
          //       name: '证据详情',
          //       to: '/case/evidence/:caseId/detail/:evidenceId'
          //     }
          //   ]
          // },
          // {
          //   name: '文书管理',
          //   to: '/case/document/:caseId',
          //   children: [
          //     {
          //       name: '文书新增',
          //       to: '/case/document/:caseId/add'
          //     },
          //     {
          //       name: '文书编辑',
          //       to: '/case/document/:caseId/edit/:documentId'
          //     }
          //   ]
          // }


        ],
        icon: <Icon.SnippetsOutlined />
      },
      {
        name: '收费案件',
        to: '/sf/case/query',
        userTypes: ['Lawyer', 'Organization', 'LawFirm', 'Admin'],
        final: true,
        children: [
          {
            name: '新增案件',
            to: '/sf/case/add'
          },
          {
            name: '卷宗目录',
            to: '/sf/case/archive/:caseId'
          },
          {
            name: '结案录入',
            to: '/sf/case/caseClose/:caseId'
          },
          {
            name: '结案审核',
            to: '/sf/case/caseEnd/:caseId'
          },
          {
            name: '案件详情',
            to: '/sf/case/detail/:id'
          },
          {
            name: '修改案件',
            to: '/sf/case/edit/:id'
          }
        ],
        icon: <Icon.SnippetsOutlined />
      },
      {
        name: '群体案件',
        to: '/case/groupCase/query',
        final: true,
        userTypes: ['Lawyer', 'Organization'],
        children: [
          {
            name: '群体案件子案件',
            to: '/case/groupCase/list/:batchInfo',
            children: [
              {
                name: '编辑主案件',
                to: '/case/groupCase/:batchInfo/edit/:caseId',
              }
            ]
          },
          // {
          //   name: '群体案件详情',
          //   to: '/case/groupCase/detail'
          // }
        ],
        icon: <Icon.TeamOutlined />
      },
      {
        name: '导出审报表',
        to: '/case/export',
        final: true,
        userTypes: ['Organization', 'Lawyer'],
        icon: <Icon.ExportOutlined />
      }
    ],
    icon: <Icon.FolderOutlined />
  },
  {
    name: '律师管理',
    to: '/lawyer/query',
    userTypes: ['LawFirm', 'Admin', 'Organization'],
    final: true,
    children: [
      {
        name: '律师详情',
        to: '/lawyer/detail'
      },
      {
        name: '新增律师',
        to: '/lawyer/add'
      },
      {
        name: '修改律师',
        to: '/lawyer/edit'
      }
    ],
    icon: <Icon.UserOutlined />
  },
  {
    name: '文书模板管理',
    to: '/documentMode/query',
    userTypes: ['Lawyer', 'Admin'],
    final: true,
    children: [
      {
        name: '新增文书模板',
        to: '/documentMode/add'
      },
      {
        name: '修改文书模板',
        to: '/documentMode/edit'
      },
    ],
    icon: <Icon.BorderTopOutlined />
  },
  {
    name: '法律法规管理',
    to: '/lawRules/tree',
    userTypes: ['Admin'],
    final: true,
    icon: <Icon.SnippetsOutlined />
  },
  {
    name: '裁判文书管理',
    to: '/legalType/tree',
    userTypes: ['Admin'],
    final: true,
    icon: <Icon.SnippetsOutlined />
  },
  {
    name: '我的咨询',
    to: '/question/query',
    userTypes: ['Lawyer'],
    final: true,
    children: [
      {
        name: '咨询详情',
        to: '/question/detail'
      },
      {
        name: '咨询',
        to: '/question/add'
      }
    ],
    icon: <Icon.QuestionCircleOutlined />
  },
  {
    name: '用户管理',
    to: '/user/query',
    userTypes: ['Admin'],
    final: true,
    children: [
      {
        name: '用户详情',
        to: '/user/detail'
      }
    ],
    icon: <Icon.UsergroupAddOutlined />
  },
  {
    name: '范本管理',
    to: '/modelText/query',
    userTypes: ['Admin'],
    final: true,
    icon: <Icon.SnippetsOutlined />
  },
  {
    name: '提现申请管理',
    to: '/cashApply/query',
    userTypes: ['Organization'],
    final: true,
    icon: <Icon.StrikethroughOutlined />
  },
  {
    name: '投诉建议',
    to: '/advise/query',
    userTypes: ['Admin'],
    final: true,
    children: [
      {
        name: '投诉建议详情',
        to: '/advise/detail',
        userTypes: ['Admin']
      }
    ],
    icon: <Icon.BulbOutlined />
  },
  // {
  //   name: '法律援助',
  //   to: '/legalAid/query',
  //   final: true,
  //   icon: <Icon.HeartOutlined />
  // },
  {
    name: '账号中心',
    // userTypes: ['Lawyer', 'User'],
    children: [
      {
        name: '基本设置',
        to: '/center/set',
        // userTypes: ['Lawyer', 'User'],
        final: true,
        icon: <Icon.SettingOutlined />
      },
      {
        name: '密码管理',
        to: '/center/password',
        final: true,
        icon: <Icon.LockOutlined />
      },
      {
        name: '律师设置',
        to: '/center/lawyerSet',
        userTypes: ['Lawyer'],
        final: true,
        icon: <Icon.SolutionOutlined />
      },
      {
        name: '账户余额',
        to: '/center/account',
        userTypes: ['Lawyer'],
        final: true,
        icon: <Icon.DollarOutlined />
      },
      {
        name: '提现记录',
        to: '/center/cashOutRecord',
        userTypes: ['Lawyer'],
        final: true,
        icon: <Icon.StrikethroughOutlined />
      },
      {
        name: '申请成为律师',
        to: '/center/apply',
        userTypes: ['User'],
        final: true,
        icon: <Icon.UserSwitchOutlined />
      }
    ],
    icon: <Icon.UserOutlined />
  }

]
