import React, { useEffect, useState } from 'react'
import { Descriptions, Button } from 'antd'
import { getDetail } from '../../components/factory'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'


function AdviseDetail(props) {
  const [details, setDetails] = useState([

    {
      name: 'content',
      label: '建议内容',
      span: 2
    },
    {
      name: 'url',
      type: 'image',
      splitStr: ',',
      label: '附件',
      span: 2
    }
  ])
  const id = props.match.params.id
  useEffect(() => {
    id && axios.postString(`advise/detail/${id}`).then(res => {
      setDetails(details.map(item => {
        return {
          ...item,
          value: res[item.name]
        }
      }))
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <div className='detail-box'>
        <Descriptions
          bordered
          column={2}
        >
          {
            details.map((item, index) => {
              return (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span || 1}
                >
                  {
                    getDetail(item)
                  }
                </Descriptions.Item>
              )
            })
          }
        </Descriptions>
        <div className='align-center'>
          <Button
            style={{'margin': '20px auto'}}
            size='large'
            onClick={() => {
              props.history.goBack()
            }}
          >
            返回
          </Button>
        </div>
      </div>
    </>
  )
}
export default AdviseDetail
