import React, { useEffect, useState } from 'react'
import { Descriptions, Button } from 'antd'
import { getDetail } from '../../components/factory'
import { transformDate } from '../../utils/utils'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'

function UserDetail(props) {
  const [details, setDetails] = useState([
    {
      name: 'name',
      label: '姓名'
    },
    {
      name: 'mobile',
      label: '手机号'
    },
    {
      name: 'addStr',
      label: '省市区'
    },
    {
      name: 'address',
      label: '详细地址'
    },
    {
      name: 'idcard',
      label: '身份证号',
      span: 2
    },
    {
      name: 'idcardZ',
      label: '身份证正面',
      type: 'image'
    },
    {
      name: 'idcardF',
      label: '身份证反面',
      type: 'image'
    }
  ])
  const id = props.match.params.id
  useEffect(() => {
    id && axios.postString(`user/getUserInfo/${id}`).then(res => {
      const getValue = (item) => {
        switch (item.name) {
          default:
            return res[item.name]
        }
      }
      setDetails(details.map(item => {
        return {
          ...item,
          value: getValue(item)
        }
      }))
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <div className='detail-box'>
        <Descriptions
          bordered
          column={2}
        >
          {
            details.map((item, index) => {
              return (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span || 1}
                >
                  {
                    getDetail(item)
                  }
                </Descriptions.Item>
              )
            })
          }
        </Descriptions>
        <div className='align-center'>
          <Button
            style={{'margin': '20px auto'}}
            size='large'
            onClick={() => {
              props.history.goBack()
            }}
          >
            返回
          </Button>
        </div>
      </div>
    </>
  )
}
export default UserDetail
