import React, { Component } from 'react'
import { Layout, Avatar, Dropdown, Menu } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined, SearchOutlined, BellOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { collapsedtoProps, collapsedDispatch } from '../redux/switchCollapsed'
import cookie from 'js-cookie'
import { toLogin } from "utils/utils";

import './Header.less'

class Header extends Component {
  render() {
    const iconSize = '24px'
    const { collapsed, switchCollapsed } = this.props
    const { headPic, nickName } = JSON.parse(cookie.get('user'))

    return (
      <Layout.Header>
        {
          React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            style: {fontSize: iconSize},
            onClick: switchCollapsed
          })
        }
        <div className="header-right">
          {/*<SearchOutlined style={{fontSize: iconSize}}/>*/}
          {/*<BellOutlined style={{fontSize: iconSize}}/>*/}
          <Dropdown
            overlay={
              (
                <Menu>
                  <Menu.Item
                  >
                    <div onClick={() => {
                      toLogin()
                    }}>退出登录</div>
                  </Menu.Item>
                </Menu>
              )
            }
            placement="bottomLeft"
            arrow
          >
            <div>
              <Avatar size={24} src={headPic} />
              <span style={{marginLeft: '10px'}}>{nickName}</span>
            </div>
          </Dropdown>
        </div>
      </Layout.Header>
    )
  }

}

export default connect(
  collapsedtoProps,
  collapsedDispatch
)(Header)
