import React from 'react'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { ConfigProvider } from 'antd'
import { BrowserRouter as Router, Route, Redirect, withRouter } from 'react-router-dom'
import history from './history/history'
import { isLogin } from './utils/utils'
import { validateMessages } from './config/validateConfig'
import Home from './pages/home'
import Login from './pages/login'

// css
import './App.less'



const AuthPage = ({ ...rest }) => {
  const isLoggedIn = isLogin()
  return (
    <Route
      {...rest}
      render={
        props => {
          if(isLoggedIn) {
            return ['/login'].includes(props.location.pathname) ? (
              <Redirect to={{ pathname: '/apply', state: { from: props.location } }} />
            ) : (
              <Home/>
            )
          } else {
            return ['/login'].includes(props.location.pathname) ? (
              <Login/>
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
          }
        }
      }
    />
  )
}

function App() {
  return (
    <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
      <Router history={history}>
        <div className="App">
          {
            React.createElement(withRouter(AuthPage), {
              path: '/'
            })
          }
        </div>
      </Router>
    </ConfigProvider>
  )

}

export default App
