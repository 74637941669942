import React from 'react'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'


function CashOutRecord() {
  const columns = [
    {
      title: '持卡人姓名',
      dataIndex: 'bankUserName'
    },
    {
      title: '银行名称',
      dataIndex: 'bankName'
    },
    {
      title: '银行卡号',
      dataIndex: 'bankId'
    },
    {
      title: '提现金额',
      dataIndex: 'amount2',
      render: amount => `¥ ${amount}`
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [],
    params: []
  }

  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      api="cash/query"
      columns={columns}
      search={search}
    />
    </>
  )
}
export default CashOutRecord
