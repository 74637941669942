import React from 'react'
import { transformatTime } from '../../../utils/utils'
import CustomPageHeader from '../../../components/CustomPageHeader'
import CustomTable from '../../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'

function groupCaseQuery() {
  const columns = [
    {
      title: '群体案件名',
      dataIndex: 'name'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      {
        label: '新增',
        icon: <PlusOutlined />,
        type: 'modal',
        api: 'batch/addBatch',
        params: [
          {
            label: '群体案件名称',
            name: 'name'
          }
        ],
        onHandleOk: (values , _this) => {
          _this.setState({
            list: [{
              ...values,
              createTime: new Date().getTime()
            }].concat(_this.state.list)
          })
        }
      }
    ],
    params: [
      {
        name: 'name',
        label: '群体案件名'
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      label: '子案件管理',
      setUrl: row => {
        return `list/${row['id']}_${row['caseId']}_${row['name']}`
      }
    },
    {
      type: 'audit',
      label: '重命名',
      api: 'batch/updateName/:id',
      params: [
        {
          name: 'name',
          label: '新名称'
        }
      ]
    },
    {
      type: 'delete',
      label: '删除',
      api: 'batch/delBatch/:id'
    },
  ]
  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      api="batch/list"
      actions={actions}
      columns={columns}
      search={search}
    />
    </>
  )
}
export default groupCaseQuery
