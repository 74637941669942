import React from 'react'
import { Table, Button } from 'antd'
import { getAction } from './factory'
import CustomModal from './CustomModal'
import { PlusOutlined } from '@ant-design/icons'

class CustomArray extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      activeIndex: null,
      list: props.value || []
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.value && nextProps.value) {
      this.setState({
        list: nextProps.value
      })
    }
  }
  addOrEdit = (activeIndex = null) => {
    this.setState({
      visible: true,
      activeIndex
    })
  }
  actions = [
    {
      type: 'click',
      label: '修改',
      onClick: (item, row, index) => {
        this.addOrEdit(index)
      }
    },
    this.props.customDelete ||
    {
      type: 'click',
      label: '删除',
      onClick: (item, row, index) => {
        // new Promise((resolve) => {
        //   if (this.props.handleDelete) {
        //     this.props.handleDelete(row).then(() => {
        //       resolve()
        //     })
        //   } else {
        //     resolve()
        //   }
        // }).then(() => {

        // })
        const list = this.state.list.filter((item, i) => index !== i)
        this.setState({
          list: list
        })
        this.props.father.formRef.current.setFieldsValue({
          [this.props.name]: list
        })
      }
    },
  ]
  render() {
    return (
      <>
      {
        this.state.list.length ? (
          <Table
            rowKey={'index'}
            dataSource={this.state.list.map((item, index) => {
              return {
                ...item,
                index
              }
            })}
            columns={this.props.options.filter(item => item.type !== 'hidden').map(item => {
              return {
                ...item,
                title: item.label,
                dataIndex: item.name.split(',')[0]
              }
            }).concat({
              title: '操作',
              dataIndex: 'id',
              render: (id, row, index) => {
                return (
                  <>
                  {
                    this.actions.map((item, key) => getAction(item, row, index, key, this))
                  }
                  </>
                )
              }
            })}
            bordered
            pagination={false}
            size={'small'}
          />
        ) : ''
      }
      <div>
        <Button type={'link'} icon={<PlusOutlined />} onClick={() => {this.addOrEdit()}}>添加{this.props.label}</Button>
      </div>
        {
          this.state.visible ? (
          <CustomModal
            {
              ...this.props
            }
            {
              ...this.state
            }
            handleOk={values =>
              new Promise((resolve, reject) => {
                if (this.props.handleOk) {
                  this.props.handleOk(values).then(idKV => {
                    resolve(idKV)
                  }).catch(() => {
                    reject()
                  })
                } else {
                  resolve()
                }
              }).then(idKV => {
                let list
                if (this.state.activeIndex !== null) {
                  list = this.state.list.map((item, i) => {
                    return i === this.state.activeIndex ? values : item
                  })
                } else {
                  list = this.state.list.concat(Object.assign(values, idKV))
                }
                this.setState({
                  list,
                  visible: false
                })
                this.props.father.formRef.current.setFieldsValue({
                  [this.props.name]: list
                })
              })

            }
            handleCancel={() => {
              this.setState({
                visible: false
              })
            }}
          />
          ) : ''
        }
      </>
    )
  }



}

export default CustomArray
