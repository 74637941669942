import React, { useState } from 'react'
import { AutoComplete } from 'antd'
import axios from '../request/axios'

let loading = false
let searchQueue = []
let cacheData = {}

function CustomAutoComplete(props) {
  // const [value, setValue] = useState('')
  const [options, setOptions] = useState([])

  const onSearch = function (value) {
    if (value.trim()) {
      searchQueue.push(value)
      if (!loading) {
        doSearch()
      }
    }
  }
  const doSearch = function () {
    const value = searchQueue[searchQueue.length - 1]
    searchQueue = []
    if (cacheData[value]) {
      updateOptions(cacheData[value])
    } else {
      loading = true
      axios.postString(props.api, {
        ...props.defaultData,
        [props.searchName]: value
      }).then(res => {
        // console.log(res)
        cacheData[value] = res
        clearLoading()
        updateOptions(res)
      }).catch(() => {
        clearLoading()
      })
    }
  }
  const clearLoading = function () {
    setTimeout(() => {
      loading = false
      if (searchQueue.length) {
        doSearch()
      }
    }, 1000)
  }
  const updateOptions = function (res) {
    setOptions(res.records.map((item, index) => {
      return props.extraData ? Object.assign.apply(null, [{
        key: index,
        value: props.getValue ? props.getValue(item) : item[props.dataName]
      }].concat(props.extraData(item).map(extra => {
        return {
          [extra.name.toLowerCase()]: extra.value
        }
      }))) : {
        key: index,
        value: props.getValue ? props.getValue(item) : item[props.dataName]
      }
    }))
  }
  const onSelect = (value, obj) => {
    props.father.formRef.current.setFieldsValue({
      [props.name]: value
    })
    props.extraData && props.extraData().forEach(extra => {
      props.father.formRef.current.setFieldsValue({
        [extra.name]: obj[extra.name.toLowerCase()]
      })
    })
  }
  return (
    <AutoComplete
      options={options}
      onSearch={onSearch}
      onSelect={onSelect}
      defaultValue={props.value || ''}
      key={props.value}
      placeholder={props.placeholder || '请输入或搜索'}
      notFoundContent={(
        <div className={'align-center'}>没有符合的项</div>
      )}
    />
  )
}

export default CustomAutoComplete