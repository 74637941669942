import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'


function NoticeAdd(props) {
  const formData = [
    {
      name: 'title',
      label: '标题'
    },
    {
      name: 'content',
      type: 'textArea',
      label: '简介'
    }
  ]
  const [initialValues, setInitialValues] = useState({})
  const id = props.match.params.id
  useEffect(() => {
    id && axios.postString(`notice/detail/${id}`).then(res => {
      setInitialValues({
        ...res
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={initialValues}
        action='notice/edit'
      />
    </>
  )
}

export default withRouter(NoticeAdd)
