import React from 'react'
import { Badge } from 'antd'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { cashStateText } from '../../config/types'

function CashApplyQuery() {
  const columns = [
    {
      title: '持卡人姓名',
      dataIndex: 'bankUserName'
    },
    {
      title: '银行名称',
      dataIndex: 'bankName'
    },
    {
      title: '银行卡号',
      dataIndex: 'bankId'
    },
    {
      title: '提现金额',
      dataIndex: 'amount2',
      render: amount => `¥ ${amount}`
    },
    {
      title: '状态',
      dataIndex: 'state',
      render: state => {
        return (
          <Badge color={cashStateText[(state || '0').toString()].color} text={cashStateText[(state || '0').toString()].label} />
        )
      }
    },
    {
      title: '操作时间',
      dataIndex: 'createTime',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [],
    params: []
  }
  const actions = [
    {
      type: 'audit',
      label: '打款',
      api: 'cash/pay',
      defaultData: row => ({
        id: row.id
      }),
      params: [
        {
          name: 'time',
          type: 'date',
          label: '打款时间'
        },
        {
          name: 'memo',
          label: '备注',
          type: 'textArea',
          rules: []
        }
      ],
      defaultTo: {
        state: 1
      },
      condition: row => ['0'].includes(row.state.toString())
    },
  ]
  return (
    <>
    <CustomPageHeader/>
    <CustomTable
      rowKey="id"
      api="cash/query"
      actions={actions}
      columns={columns}
      search={search}
    />
    </>
  )
}
export default CashApplyQuery
