import React from 'react'
import { Badge } from 'antd'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { caseStateText } from '../../config/types'


function LegalAidQuery(props) {
  const columns = [
    {
      title: '案件编号',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: '案件名称',
      dataIndex: 'name'
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      render: state => {
        return (
          <Badge color={caseStateText[state.toString()].color} text={caseStateText[state.toString()].label} />
        )
      }
    },
    {
      title: '律师事务所',
      dataIndex: 'agencyNames',
      align: 'center',
      render: agencyNames => (agencyNames || []).join('，')

    },
    {
      title: '代理律师',
      dataIndex: 'lawyerNames',
      render: lawyerNames => (lawyerNames || []).join('，')
    },
    {
      title: '受理时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [],
    params: [
      {
        name: 'title',
        label: '案件名称'
      },
      {
        name: 'state',
        label: '状态',
        type: 'select',
        options: Object.entries(caseStateText).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: '/case/detail/:id'
    },
    {
      type: 'audit',
      api: 'agency/audit',
      dataName: 'id',
      params: [
        {
          name: 'auditStatus',
          to: 'state',
          type: 'radio',
          label: '审核结果',
          options: Object.entries(caseStateText).filter(item => ['1', '2'].includes(item[0])).map(item => {
            return {
              value: item[0],
              label: item[1].label
            }
          })
        },
        {
          name: 'auditContent',
          label: '审核意见',
          type: 'textArea'
        }
      ],
      condition: row => ['0', '2'].includes(row.state.toString())
    },
    {
      type: 'delete',
      api: 'notice/del/:id'
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        defaultData={{ isAid: '1'}}
        api='case/query'
      />

    </>
  )
}
export default withRouter(LegalAidQuery)
