import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import { transform } from '../../utils/utils'


function ApplyLawyer(props) {
  const formData = [
    {
      name: 'name',
      label: '律师'
    },
    {
      name: 'agencyName',
      label: '律所名称',
      type: 'autoComplete',
      dataName: 'agencyName',
      defaultData: {
        type: 2
      },
      api: 'agency/query',
      searchName: 'agencyName',
    },
    {
      name: 'startDate',
      label: '执业开始时间',
      type: 'date'
    },
    {
      name: 'occNo',
      label: '职业证号'
    },
    {
      name: 'occPic',
      label: '职业证书图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    },
    {
      name: 'isPub',
      type: 'switch',
      label: '是否公益'
    },
    {
      name: 'memo',
      type: 'textArea',
      label: '简介'
    },
    {
      name: 'occAgePic',
      label: '职业年检图片',
      type: 'uploadImg',
      folderPath: '/person/cert/'
    }
  ]

  return (
    <>
    <CustomPageHeader/>
    <CustomForm
      formData={formData}
      initialValues={{}}
      action='lawyer/lawerAdd'
      keyRow='uid'
      notBack={true}

    />
    </>
  )
}

export default withRouter(ApplyLawyer)
