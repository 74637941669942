import React, { useEffect, useState } from 'react'
import { Descriptions, Button } from 'antd'
import { getDetail } from '../../components/factory'
import axios from '../../request/axios'
import CustomPageHeader from '../../components/CustomPageHeader'


function AgencyDetail(props) {
  const id = props.match.params.id
  const AGENCYPATH = `/agency/detail/${id}`

  const [details, setDetails] = useState([
    {
      name: 'agencyName',
      label: props.history.location.pathname === AGENCYPATH ? '机构名称' : '律师事务所'
    },
    {
      name: 'agencyCode',
      label: props.history.location.pathname === AGENCYPATH ? '机构代码' : '事务所代码'
    },
    {
      name: 'manager',
      label: props.history.location.pathname === AGENCYPATH ? '机构负责人' : '事务所负责人'
    },
    {
      name: 'addStr',
      label: '省市区',

    },
    {
      name: 'address',
      label: '地址'
    },
    {
      name: 'tel',
      label: '电话'
    },
    {
      name: 'memo',
      type: 'textArea',
      label: '描述',
      span: 2
    },
    {
      name: 'busPic',
      label: '营业执照',
      type: 'image',
      span: 2
    },
    {
      name: 'licencePic',
      label: '执业许可证',
      type: 'image',
      span: 2
    }
  ])
  useEffect(() => {
    id && axios.postString(`agency/detail/${id}`).then(res => {
      setDetails(details.map(item => {
        return {
          ...item,
          value: res[item.name]
        }
      }))
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
      <CustomPageHeader/>
      <div className='detail-box'>
        <Descriptions
          bordered
          column={2}
        >
          {
            details.map((item, index) => {
              return (
                <Descriptions.Item
                  key={index}
                  label={item.label}
                  span={item.span || 1}
                >
                  {
                    getDetail(item)
                  }
                </Descriptions.Item>
              )
            })
          }
        </Descriptions>
        <div className='align-center'>
          <Button
            style={{'margin': '20px auto'}}
            size='large'
            onClick={() => {
              props.history.goBack()
            }}
          >
            返回
          </Button>
        </div>
      </div>
    </>
  )
}
export default AgencyDetail
