import React from 'react'
import { transformatTime } from '../../utils/utils'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'


function NoticeQuery(props) {
  const columns = [
    {
      title: '公告标题',
      dataIndex: 'title'
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      {
        label: '新建',
        icon: <PlusOutlined/>,
        onClick() {
          props.history.push('add')
        }
      }
    ],
    params: [
      {
        name: 'title',
        label: '标题'
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: 'detail/:id'
    },
    {
      type: 'link',
      label: '修改',
      url: 'edit/:id'
    },
    {
      type: 'delete',
      api: 'notice/del/:id'
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        api='notice/query'
      />

    </>
  )
}
export default withRouter(NoticeQuery)
