import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import cookie from 'js-cookie'
import { sexType, sueType, scopeType, relationType, caseType, accuserType, occType } from '../../config/types'
// const occType = {
//   '1': '一般授权',
//   '2': '特别授权'
// }
function NoticeAdd(props) {
  const { agencyId } = JSON.parse(cookie.get('user'))
  const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
  const formData = [
    {
      name: 'name',
      label: '案件名称'
    },
    {
      name: 'type',
      label: '聘请内容',
      type: 'cascader',
      options: caseType
    },
    {
      name: 'caseNo',
      label: '机构案号'
    },
    /* {
      name: 'orgAgencyName',
      label: '法律援助机构',
      type: 'autoComplete',
      extraData: (item = {}) => {
        return [
          {
            name: 'agencyId',
            value: item.id
          }
        ]
      },
      dataName: 'agencyName',
      defaultData: {
        type: 1,
        state: 1
      },
      api: 'agency/query',
      searchName: 'agencyName'
    }, */
    {
      name: 'anyou',
      label: '案由'
    },
    {
      name: 'sue',
      label: '诉讼地位',
      type: 'select',
      options: Object.entries(sueType).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      name: 'amount',
      label: '争议金额'
    },
    {
      name: 'scope',
      label: '委托授权',
      type: 'select',
      options: Object.entries(scopeType).map(item => {
        return {
          value: item[0],
          label: item[1]
        }
      })
    },
    {
      name: 'addStr,province,city,area',
      label: '案件所在地',
      type: 'area'
    },
    {
      name: 'address',
      label: '所在街道'
    },
    {
      name: 'hearId',
      type: 'customSelect',
      label: '审理机关',
      getLabel: (item = {}) => {
        return item.name
      },
      getValues: (value) => {
        const item = JSON.parse(value)
        return item.id
      },
      api: 'hear/query',
      searchName: 'name',
    }, 
    {
      name: 'des',
      type: 'textArea',
      label: '案件描述'
    },
    {
      name: 'pursue',
      type: 'textArea',
      label: '诉求'
    },
    {
      name: 'fee',
      label: '收费金额'
    },
    {
      name: 'layers',
      type: 'customSelect',
      mode: 'multiple',
      label: '代理律师',
      defaultData: {
        agencyId
      },
      getLabel: (item = {}) => {
        return item.userReal.name
      },
      getValues: (values = []) => {
        return values.map(value => {
          const item = JSON.parse(value)
          return {
            realId: item.userReal.id,
            agencyId: item.agency.id
          }
        })
      },
      api: 'lawyer/query',
      searchName: 'name',
    },
    /* {
      name: 'accusers',
      type: 'array',
      label: '当事人',
      options: [
        // {
        //   name: 'type',
        //   label: '身份',
        //   type: 'select',
        //   options: Object.entries(accuserType).map(item => {
        //     return {
        //       value: item[0],
        //       label: item[1]
        //     }
        //   }),
        //   render: type => accuserType[type]
        // },
        {
          name: 'name',
          label: '姓名'
        },
        {
          name: 'sex',
          label: '性别',
          type: 'select',
          options: Object.entries(sexType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: value => value ? sexType[value] : ''
        },
        {
          name: 'type',
          label: '身份',
          type: 'select',
          options: Object.entries(accuserType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: value => value ? accuserType[value] : ''
        },
        {
          name: 'tel',
          label: '电话'
        },
        {
          name: 'idcard',
          label: '身份证'
        },
        {
          name: 'orgCode',
          label: '机构编码'
        },
        {
          name: 'companyName',
          label: '企业名称'
        },
        {
          name: 'addStr,province,city,area',
          label: '省市区',
          type: 'area'
        },
        {
          name: 'address',
          label: '地址'
        },
        {
          name: 'occ',
          type: 'select',
          label: '职业',
          options: Object.entries(occType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: value => value ? occType[value] : ''
        },
        {
          name: 'income',
          label: '收入'
        }
      ]
    }, */
    /* {
      name: 'isDp',
      type: 'switch',
      label: '是否接受调派'
    }, */
    // {
    //   name: 'isAid',
    //   type: 'radio',
    //   label: '案件类型',
    //   options: Object.entries(isAidType).map(item => {
    //     return {
    //       value: item[0],
    //       label: item[1]
    //     }
    //   })
    // },
   /*  {
      name: 'entrusts',
      label: '委托人',
      type: 'array',
      options: [
        {
          name: 'name',
          label: '姓名'
        },
        {
          name: 'idcard',
          label: '身份证'
        }
      ]
    },
 */
    /* {
      name: 'duifangPos',
      type: 'array',
      label: '对方当事人',
      options: [
        // {
        //   name: 'type',
        //   label: '身份',
        //   type: 'select',
        //   options: Object.entries(accuserType).map(item => {
        //     return {
        //       value: item[0],
        //       label: item[1]
        //     }
        //   })
        // },
        {
          name: 'name',
          label: '姓名'
        },
        {
          name: 'type',
          label: '身份',
          type: 'select',
          options: Object.entries(accuserType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: value => value ? accuserType[value] : ''
        },
        {
          name: 'tel',
          label: '电话'
        },
        {
          name: 'idcard',
          label: '身份证'
        },
        {
          name: 'orgCode',
          label: '机构编码'
        },
        {
          name: 'companyName',
          label: '企业名称'
        },
        {
          name: 'addStr,province,city,area',
          label: '省市区',
          type: 'area'
        },
        {
          name: 'address',
          label: '地址'
        }
      ]
    }, */
    
    
    
    // {
    //   name: 'isNeed',
    //   label: '共同诉讼',
    //   type: 'radio',
    //   options: Object.entries(isNeedType).map(item => {
    //     return {
    //       value: item[0],
    //       label: item[1]
    //     }
    //   })
    // },
    /* {
      name: 'clients',
      type: 'array',
      label: '家庭成员',
      options: [
        {
          name: 'name',
          label: '姓名'
        },
        {
          name: 'sex',
          type: 'radio',
          label: '性别',
          options: Object.entries(sexType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: type => sexType[type]
        },
        {
          name: 'age',
          label: '年龄'
        },
        {
          name: 'relation',
          label: '家庭关系',
          type: 'select',
          options: Object.entries(relationType).map(item => {
            return {
              value: item[0],
              label: item[1]
            }
          }),
          render: type => relationType[type]
        },
        {
          name: 'work',
          label: '工作'
        },
        {
          name: 'income',
          label: '收入'
        }
      ]
    }, */
    /* {
      name: 'familyMemo',
      label: '家庭收入情况'
    }, */
    // {
    //   name: 'occ',
    //   label: '职业当事人',
    //   type: 'select',
    //   options: Object.entries(occType).map(item => {
    //     return {
    //       value: item[0],
    //       label: item[1]
    //     }
    //   })
    // },
    
  ]
  const [initialValues, setInitialValues] = useState({
    isDp: true,
    isAid: '0',
    isNeed: '1'
  })
  const id = props.match.params.id

  useEffect(() => {
    id && axios.postString(`${sf}/case/detail/${id}`).then(res => {
      setInitialValues({

        id: res.lawCase.id,
        type: res.lawCase.type,
        orgAgencyName: res.lawCase.orgAgencyName,
        pursue: res.lawCase.caseDetail.pursue,
        des: res.lawCase.caseDetail.des,
        layers: res.caseLawyerList.map(item => {
          return JSON.stringify({
            userReal: {
              name: item.userReal.name,
              id: item.realId
            },
            agencyId: item.agencyId
          })
        }),
        accusers: res.accuserList
        // .map(item => {
        //   return {
        //     name: item.name,
        //     type: item.type,
        //     tel: item.tel,
        //     idcard: item.idcard,
        //     orgCode: item.orgCode,
        //     companyName: item.companyName,
        //     addStr: item.addStr
        //   }
        // })
        ,
        isDp: res.isDp === '1',
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [id])
  return (
    <>
    <CustomPageHeader/>
    <CustomForm
      formData={formData}
      initialValues={initialValues}
      action={`${sf}/case/add`}
      method='post'
      defaultData={
        { 
          agencyId,
          isWeb:1
        }
      }
    />
    </>
  )
}

export default withRouter(NoticeAdd)
