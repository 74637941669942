import React from 'react'
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  message
} from 'antd';
import './CustomTable.less'
import { SearchOutlined, ClearOutlined } from '@ant-design/icons'
import axios from '../request/axios'
import { parse } from 'qs'
import { getAction, getInput, getExtra } from "./factory"

class CustomTable extends React.Component{
  constructor(props) {
    super(props)
    const data = props.data || {}
    this.state = {
      list: data.records || [],
      pageInfo: {
        current: data.current || 1,
        size: data.size || 5,
        total: data.total || 0
      },
      loading: false
    }
    this.initialValues = {}
  }
  componentDidMount() {
    !this.props.data && this.getList({
      current: this.state.pageInfo.current,
      size: this.state.pageInfo.size
    })
    // history.push(this.props.history.location.pathname+'?a=1')
  }
  // shouldComponentUpdate() {
  //   return true
  // }
  componentWillReceiveProps(nextProps) {
    this.initialValues = {
      ...parse(window.location.search.substring(1))
    }
    this.formRef.current && this.formRef.current.setFieldsValue(
      Object.assign.apply(this, Object.keys(this.formRef.current.getFieldsValue()).map(item => {
        return {
          [item]: this.initialValues[item] || null
        }
      }))
    )
    this.getList(null, nextProps)
  }

  getList(params, props = this.props) {
    if (params) {
      Object.assign(this.initialValues, params)
    }
    this.setState({
      loading: true
    })
    // console.log('getList',this.props.defaultData)
    axios.postString(props.api, {
      ...props.defaultData,
      ...this.initialValues
    }, {
      NPprogress: true
    }).then(res => {
      this.setState({
        loading: false,
        list: props.map ? props.map(res.records) : res.records,
        pageInfo: {
          current: res.current,
          size: res.size,
          total: res.total
        }
      })
    }).catch(() => {
      this.setState({
        loading: false
      })
    })
  }
  clearSearch() {
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue(
        Object.assign.apply(this, Object.keys(this.formRef.current.getFieldsValue()).map(item => {
          return {
            [item]: null
          }
        }))
      )
      this.formRef.current.submit()
    }
  }
  doSearch(values) {
    this.getList({
      current: 1,
      ...values
    })
  }
  formRef = React.createRef()
  render() {
    let _selectedRowKeys = []
    const pagination = {
      current: this.state.pageInfo.current,
      size: this.state.pageInfo.size,
      total: this.state.pageInfo.total,
      defaultPageSize: 5,
      showQuickJumper: true,
      // showSizeChanger: true,
      showTotal: () => {
        return `共 ${this.state.pageInfo.total} 条记录 第 ${this.state.pageInfo.current} / ${Math.ceil(this.state.pageInfo.total/this.state.pageInfo.size)} 页`
      },
      onChange:(current, size) => {
        this.getList({current})
      },
      // onShowSizeChange: (current, size) => {
      //   this.getList({current, size})
      // },
    }
    return (
      <>
        {
          this.props.search && ((this.props.search.params && this.props.search.params.length) || (this.props.search.extra && this.props.search.extra.length)) ?
            (
              <Form
                ref={this.formRef}
                className='search-form'
                layout='inline'
                onFinish={(values) => {
                  this.doSearch(values)
                }}
                initialValues={this.initialValues}
              >
                <Row justify='space-around'>
                  <Col flex='1 1 0'>
                    <Row gutter={[8, 8]}>
                      {
                        this.props.search.params.map((item, index) => {
                          return (
                            <Col span={this.props.search.params.length > 1 ? 12 : 24} key={index}>
                              <Form.Item
                                name={item.name}
                                label={item.label}
                                rules={item.rules || []}
                              >
                                {getInput(item, {}, this)}
                              </Form.Item>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                  <Col className='align-right' flex={ 200 + (this.props.search.extra ? this.props.search.extra.length * 200 : 0) + 'px'} >
                    {
                      this.props.search && this.props.search.params && this.props.search.params.length ?
                        (
                          <>
                            <Button
                              type="primary"
                              icon={<SearchOutlined />}
                              htmlType="submit"
                            >
                              查询
                            </Button>
                            <Button
                              icon={<ClearOutlined />}
                              onClick={() => {
                                this.clearSearch()
                              }}
                            >
                              重置
                            </Button>
                          </>
                        ) : ''
                    }

                    {
                      this.props.search.extra && this.props.search.extra.filter((item, index) => {
                        return !item.condition || item.condition()
                      }).map((item, index) => {
                        return getExtra(item, index, this)
                      })
                    }
                  </Col>
                </Row>
              </Form>
            ) : ''
        }
        <Table
          rowKey={this.props.rowKey}
          dataSource={this.state.list}
          rowSelection={(this.props.rowSelection || this.props.rowSelectionChange)  ? {
            onChange: (selectedRowKeys) => {
              _selectedRowKeys = selectedRowKeys
              this.props.rowSelectionChange && this.props.rowSelectionChange(selectedRowKeys)
            }
          } : null}
          columns={
              (this.props.noNumber ? [] : [{
                title: '序号',
                align: 'center',
                render: (row1, row2, index) => {
                  return index + 1 + (this.state.pageInfo.current - 1) * this.state.pageInfo.size
                }
              }]).concat(this.props.columns).concat(
                this.props.actions && this.props.actions.length ? {
                  title: '操作',
                  dataIndex: 'id',
                  render: (id, row, index) => {
                    return (
                      <>
                        {
                          this.props.actions.filter(item => !item.condition || item.condition(row)).map((item, key) => getAction(item, row, index, key, this))
                        }
                      </>
                    )
                  }
                } : []
              )
          }
          loading={this.state.loading}
          pagination={pagination}
        />
        {
          this.props.rowSelection && this.props.rowSelection.buttons
          ?
            <div className="normal-box align-right">
              {
                this.props.rowSelection.buttons.filter((item, index) => {
                  return !item.condition || item.condition()
                }).map((item, index) => {
                  return <Button
                    type={item.type || 'primary'}
                    onClick={() => {
                      if (_selectedRowKeys.length) {
                        item.onClick(_selectedRowKeys)
                      } else {
                        message.info('请选择列')
                      }
                    }}
                    key={index}
                  >{item.name}</Button>
                })
              }
            </div>
           : ''
        }
      </>
    )
  }
}
export default CustomTable
