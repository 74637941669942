import React from 'react'
import * as Icon from '@ant-design/icons'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomForm from '../../components/CustomForm'
import { Tree, Button, Row, Col, Tooltip, Modal, ConfigProvider, Form, Input, message, Drawer, Popconfirm } from 'antd'
import axios from '../../request/axios'
import { validateMessages } from '../../config/validateConfig'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { transform } from '../../utils/utils'
class LawRulesTree extends React.Component{
  constructor(props) {
    super(props)
    const caseId = props.match.params.caseId
    this.state = {
      caseId,
      treeData: [{
        title: '根目录',
        id: 0,
        key: 'type0',
        nodeType: 'type',
        icon: (<Icon.FolderOutlined />),
        children: []
      }],
      loadedKeys: ['type0'],
      activePid: null,
      content: null,
      activeTypeName: null,
      initialValues: {
        type: true
      },
      visible: false
    }
    this.config = {
      formData: [
        {
          label: '法规类型',
          type: 'text',
          render: () => this.state.activeTypeName,
          condition: values => !values.id
        },
        {
          name: 'title',
          label: '法规标题'
        },
        {
          name: 'announcement',
          label: '公布机构'
        },
        {
          name: 'openTime',
          label: '公布时间',
          type: 'date'
        },
        {
          name: 'goTime',
          label: '施行时间',
          type: 'date'
        },
        {
          name: 'content',
          type: 'editor',
          label: '具体内容'
        },
        {
          name: 'type',
          label: '有效力',
          type: 'switch'
        }
      ]
    }
  }

  componentDidMount() {
    axios.postString(`lawType/typeList`, {}, {
      NPprogress: true
    }).then(res => {
      this.setState({
        treeData: [{
          ...this.state.treeData[0],
          children: res.records.map((item, index) => {
            return {
              ...item,
              title: item.name,
              key: 'type' + item.id,
              nodeType: 'type',
              isLeaf: !item.exist,
              icon: (<Icon.AppstoreOutlined />),
              index
            }
          })
        }]
      })

    }).catch(() => {})
  }
  updateTree = (list, data, key, {isLoad = false, isReplace = false, isDelete = false}) => {
    return list.map(item => {
      if (item.key === key) {
        return isDelete ? null : isReplace ? Object.assign({}, item, data[0]) : {
          ...item,
          isLeaf: false,
          children: isLoad ? data : (item.children || []).concat(data)
        }
      } else if (item.children && item.children.length) {
        const children = this.updateTree(item.children, data, key, {isLoad, isReplace, isDelete})
        return {
          ...item,
          isLeaf: !children.length,
          children
        }
      } else {
        return item
      }
    }).filter(item => item)
  }
  onLoadData = (node) => {
    return new Promise((resolve, reject) => {
      axios.postString(`lawType/getListByPid/${node.id}`).then(res => {
        const children = res.typeList.map(child => {
          return {
            ...child,
            title: child.name,
            key: 'type' + child.id,
            nodeType: 'type',
            isLeaf: !child.exist,
            icon: (<Icon.AppstoreOutlined />)
          }
        }).concat(res.contentList.map(rule => {
          return {
            ...rule,
            nodeType: 'rule',
            key: 'rule' + rule.id,
            icon: (<Icon.FileTextOutlined />),
            isLeaf: true
          }
        }))
        children.length && this.setState({
          treeData: this.updateTree(this.state.treeData, children, node.key, {isLoad: true}),
          loadedKeys: this.state.loadedKeys.concat(node.key)
        })
        resolve()
      }).catch(() => {
        reject()
      })
    })

  }

  render() {
    return (
      <>
      <CustomPageHeader/>
      <div
        className={'detail-box'}
      >
        <Row
          style={{ flexWrap: 'nowrap' }}
          justify='space-around'
        >
          <Col flex='300px'>
            <Tree
              showIcon
              draggable
              treeData={this.state.treeData}
              loadData={this.onLoadData}
              loadedKeys={this.state.loadedKeys}
              defaultExpandedKeys={['type0']}
              titleRender={node => {
                return (
                  <>
                  <div
                    className={'tree-title'}
                  >
                    {node.title}
                  </div>
                  <div
                    className={'tree-px'}
                  >
                    {
                      node.nodeType === 'type' ? (
                        <>
                          <Tooltip title={'新增类型'} >
                            <Button
                              type={'link'}
                              onClick={e => {
                                e.stopPropagation()
                                const tempFormRef = React.createRef()
                                Modal.info({
                                  className: 'modal-width-form',
                                  maskClosable: true,
                                  keyboard: true,
                                  title: '新增法规类型',
                                  content: (
                                    <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                                      <Form
                                        ref={tempFormRef}
                                      >
                                        <Form.Item
                                          name={'name'}
                                          label={'组名'}
                                          rules={[{ required: true }]}
                                        >
                                          <Input allowClear placeholder={'请输入'} />
                                        </Form.Item>
                                      </Form>
                                    </ConfigProvider>
                                  ),
                                  okText: '确认',
                                  cancelText: '取消',
                                  onOk: () => new Promise((resolve, reject) => {
                                    tempFormRef.current.validateFields().then(() => {
                                      var values = tempFormRef.current.getFieldsValue()
                                      axios.postString('lawType/typeAdd', {
                                        ...values,
                                        pId: node.id
                                      }).then(id => {
                                        message.success('操作成功')
                                        this.setState({
                                          treeData: this.updateTree(this.state.treeData, [{
                                            title: values.name,
                                            key: 'type' + id,
                                            nodeType: 'type',
                                            pid: node.id,
                                            id,
                                            icon: (<Icon.AppstoreOutlined />),
                                            isLeaf: true
                                          }], `type${node.id}`, {})
                                        })
                                        resolve()
                                      }).catch(() => {
                                        reject()
                                      })
                                    }).catch(() => {
                                      reject()
                                    })
                                  })
                                })
                              }}
                            >
                              <Icon.AppstoreAddOutlined />
                            </Button>
                          </Tooltip>
                          {
                            node.key !== 'type0' ? (
                              <>
                              <Tooltip title={'修改类型'} >
                                <Button
                                  type={'link'}
                                  onClick={e => {
                                    e.stopPropagation()
                                    const tempFormRef = React.createRef()
                                    Modal.info({
                                      className: 'modal-width-form',
                                      maskClosable: true,
                                      keyboard: true,
                                      title: '修改法规类型',
                                      content: (
                                        <ConfigProvider form={{ validateMessages }} locale={zh_CN}>
                                          <Form
                                            ref={tempFormRef}
                                            initialValues={{
                                              name: node.title
                                            }}
                                          >
                                            <Form.Item
                                              name={'name'}
                                              label={'组名'}
                                              rules={[{ required: true }]}
                                            >
                                              <Input allowClear placeholder={'请输入'} />
                                            </Form.Item>
                                          </Form>
                                        </ConfigProvider>
                                      ),
                                      okText: '确认',
                                      cancelText: '取消',
                                      onOk: () => new Promise((resolve, reject) => {
                                        tempFormRef.current.validateFields().then(() => {
                                          var values = tempFormRef.current.getFieldsValue()
                                          axios.postString('lawType/typeAdd', {
                                            ...values,
                                            id: node.id,
                                            pId: node.pid
                                          }).then(id => {
                                            message.success('操作成功')
                                            this.setState({
                                              treeData: this.updateTree(this.state.treeData, [{
                                                title: values.name,
                                                key: 'type' + id,
                                                nodeType: 'type',
                                                pid: node.pid,
                                                id,
                                                icon: (<Icon.AppstoreOutlined />)
                                              }], `type${node.id}`, {isReplace: true})
                                            })
                                            resolve()
                                          }).catch(() => {
                                            reject()
                                          })
                                        }).catch(() => {
                                          reject()
                                        })
                                      })
                                    })
                                  }}
                                >
                                  <Icon.EditOutlined />
                                </Button>
                              </Tooltip>
                              <Tooltip title={'新增法律法规'} >
                                <Button
                                  type={'link'}
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.setState({
                                      visible: true,
                                      initialValues: {
                                        id: null,
                                        title: null,
                                        content: null,
                                        memo: null,
                                        announcement: null,
                                        openTime: null,
                                        goTime: null,
                                        type: true
                                      }
                                    })
                                    // this.switchIndex(e, node.index, node.index + 1)
                                  }}
                                >
                                  <Icon.FileAddOutlined />
                                </Button>
                              </Tooltip>
                              <Tooltip title={'删除类型'} >
                                <Popconfirm
                                  title={`删除该类型及其所有内容?`}
                                  icon={<Icon.CloseCircleTwoTone twoToneColor='red'/>}
                                  onConfirm={() => {
                                    axios.postString(`lawType/delType/${node.id}`).then(() => {
                                      this.setState({
                                        treeData: this.updateTree(this.state.treeData, [], `type${node.id}`, {isDelete: true})
                                      })
                                    }).catch(() => {})
                                  }}
                                >
                                  <Button
                                    type={'link'}
                                    onClick={e => {
                                      e.stopPropagation()
                                    }}
                                  >
                                    <Icon.CloseCircleOutlined />
                                  </Button>
                                </Popconfirm>
                              </Tooltip>
                              </>
                            ) : ''
                          }
                        </>
                      ) : (
                        <>
                        <Tooltip title={'修改法律法规'} >
                          <Button
                            type={'link'}
                            onClick={e => {
                              e.stopPropagation()
                              axios.postString(`lawType/contentDetail/${node.id}`).then(res => {
                                this.setState({
                                  visible: true,
                                  initialValues: {
                                    ...res,
                                    openTime: transform(new Date(res.openTime), 'YYYY-MM-DD'),
                                    goTime: transform(new Date(res.goTime), 'YYYY-MM-DD')
                                  }
                                })
                              }).catch(() => {})

                              // this.switchIndex(e, node.index, node.index + 1)
                            }}
                          >
                            <Icon.EditOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip title={'删除法律法规'} >
                          <Popconfirm
                            title={`删除该法律法规?`}
                            icon={<Icon.CloseCircleTwoTone twoToneColor='red'/>}
                            onConfirm={() => {
                              axios.postString(`lawType/delContent/${node.id}`).then(() => {
                                this.setState({
                                  treeData: this.updateTree(this.state.treeData, [], `rule${node.id}`, {isDelete: true})
                                })
                              }).catch(() => {})
                            }}
                          >
                            <Button
                              type={'link'}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <Icon.CloseCircleOutlined />
                            </Button>
                          </Popconfirm>
                        </Tooltip>
                        </>
                      )
                    }
                  </div>
                  </>
                )
              }}
              onSelect={(selectedKeys, e) => {
                if (e.node.nodeType === 'rule') {
                  this.setState({
                    content: e.node.content,
                    activePid: e.node.lawsTypeId,
                    activeId: e.node.id
                  })
                } else {
                  this.setState({
                    content: null,
                    activePid: e.node.id,
                    activeTypeName: e.node.title
                  })
                }
              }}
            />
          </Col>
          <Col
            flex='auto'
          >
            {
              this.state.content ?
                (
                  <div
                    className={'css-initial'}
                    dangerouslySetInnerHTML={{__html: this.state.content}}
                  >

                  </div>
                ) : ''
            }
          </Col>
        </Row>
      </div>
      <Drawer
        title="新建法律法规"
        placement={'bottom'}
        height={'top'}
        // closeIcon={(
        //   <Button
        //     type="primary"
        //   >
        //     保存
        //   </Button>
        // )}
        onClose={() => {
          this.setState({
            visible: false
          })
        }}
        visible={this.state.visible}
      >
        <CustomForm
          formData={this.config.formData}
          defaultData={{ lawsTypeId: this.state.activePid }}
          initialValues={this.state.initialValues}
          notBack={true}
          onHandleOK={(values, id) => {
            this.setState({
              visible: false,
              content: values.content,
              treeData: this.updateTree(this.state.treeData, [{
                ...values,
                key: 'rule' + id,
                nodeType: 'rule',
                id,
                icon: (<Icon.FileTextOutlined />),
                isLeaf: true
              }], !!this.state.initialValues.id ? `rule${id}` : `type${this.state.activePid}`, {isReplace: !!this.state.initialValues.id})
            })
          }}
          action='lawType/contentAdd'
        />
      </Drawer>
      </>
    )
  }


}

export default LawRulesTree