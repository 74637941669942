import React from 'react'
import { Badge, Drawer, Button, Modal, Descriptions } from 'antd'
import CustomTable from './CustomTable'
import CustomForm from './CustomForm'
import { PlusOutlined, LinkOutlined } from '@ant-design/icons'
import { docStateText, docCataLog, acceptType } from '../config/types'
import cookie from 'js-cookie'
import { transformatTime } from '../utils/utils'
import { system } from '../config/system'
import axios from '../request/axios'
import { getDetail } from '../components/factory'
import { withRouter } from 'react-router-dom'


class CustomDocumentList extends React.Component{
  constructor(props) {
    super(props)
    const { token } = JSON.parse(cookie.get('user'))
    const userType = cookie.get('userType')
    const sf = props.history.location.pathname.split('/')[1] === 'sf' ? '/sf' : ''
    this.state = {
      caseId: props.caseId,
      initialValues: {},
      sf,
      isLaywer: userType === 'Lawyer',
      visible: false,
      visible2: false,
      details: []
    }
    this.config = {
      columns: [
        {
          title: '文书类型',
          dataIndex: 'type1'
        },
        {
          title: '文书名称',
          dataIndex: 'type2'
        },
        {
          title: '状态',
          dataIndex: 'state',
          render: state => {
            return (
              <Badge color={docStateText[(state || '0').toString()].color} text={docStateText[(state || '0').toString()].label} />
            )
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          render: createTime => transformatTime(new Date(createTime))
        }
      ],
      search: {
        extra: [
          {
            label: '新建文书',
            icon: <PlusOutlined/>,
            onClick: () => {
              this.setState({
                visible: true,
                initialValues: {
                  type1: null,
                  type2: null,
                  memo: null,
                  content: null,
                  id: null
                }
              })
            },
            condition: () => this.state.isLaywer
          }
        ],
        params: [
          {
            name: 'type1',
            label: '文书类型',
            type: 'select',
            options: docCataLog
          }
        ]
      },
      actions: [
        {
          type: 'download',
          label: '下载',
          url: `${system.download}/${sf}/caseDoc/down/:id?token=${token}`
        },
        {
          type: 'audit',
          api: `${sf}/caseDoc/edit/:id`,
          label: '上传',
          params: [
            {
              name: 'url',
              label: '文书',
              type: 'uploadFile',
              folderPath: '/',
              limit: Infinity,
              accept: acceptType.pdf + ',' + acceptType.image,
            }
          ],
          defaultTo: {
            state: '3'
          },
          condition: () => this.state.isLaywer
        },
        {
          type: 'click',
          label: '修改',
          onClick: (item, row, index) => {
            this.setState({
              visible: true,
              initialValues: {
                type1: row.type1,
                type2: row.type2,
                memo: row.memo,
                content: row.content,
                id: row.id
              }
            })
          },
          condition: row => !row.url && this.state.isLaywer && ['0', '1'].includes((row.state || '0').toString())
        },
        {
          type: 'click',
          label: '查看',
          onClick: (item, row, index) => {
            Modal.info({
              className: 'modal-width-form',
              width: 500,
              maskClosable: true,
              keyboard: true,
              title: '文件列表',
              content: row.url.split(',').map((url, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      window.open(url)
                    }}
                    type={'link'}
                    size={'small'}
                    icon={<LinkOutlined />}
                  >
                    {url.split('/').pop()}
                  </Button>
                )
              }),
              okText: '确认'
            })
          },
          condition: row => row.url
        },
        {
          type: 'click',
          label: '预览',
          onClick: (item, row, index) => {
            Modal.info({
              className: 'modal-width-form',
              width: 1000,
              maskClosable: true,
              keyboard: true,
              title: '文书预览',
              content: <div dangerouslySetInnerHTML={{__html: row.content}} />,
              okText: '确认'
            })
          },
          condition: row => row.content
        },
        {
          type: 'audit',
          api: `${sf}/caseDoc/edit/:id`,
          label: '发起签名',
          defaultData: {
            state: 1
          },
          condition: row => ['0'].includes((row.state || '0').toString())
        },
        {
          type: 'delete',
          label: '删除',
          api: `${sf}/caseDoc/del/:id`,
          condition: row => this.state.isLaywer
        },
        {
          type: 'audit',
          label: '同步并请求签名',
          api: `${sf}/batch/tb/doc/${props.batchId}`,
          defaultData: row => {
            return {
              docId: row['id']
            }
          },
          condition: () => !!props.batchId
        }
      ],
      formData: [
        {
          name: 'type1',
          type: 'select',
          options: docCataLog,
          label: '文书类型'
        },
        {
          name: 'memo',
          type: 'customSelect',
          label: '选择模版',
          placeholder: '请搜索选择模板',
          rules: [],
          getLabel: (item = {}) => {
            return item.name
          },
          handleCheck: (value, form) => {
            const item = JSON.parse(value)
            if (!item.needNot) {
              axios.postString(`${sf}/caseDoc/init`, {
                caseId: this.state.caseId,
                tempId: item.id
              }).then(res => {
                const type2 = form.getFieldsValue()['type2'] || item.name
                this.setState({
                  initialValues: {
                    'type1': form.getFieldsValue()['type1'] || item.type,
                    'type2': type2 === '接待笔录' ? '' : type2,
                    'memo':  JSON.stringify({id:item.id , name: item.name, needNot: true}),
                    'content': res
                  }
                })
                // form.setFieldsValue({
                //   'content': res
                // })
              })
            }

          },
          api: 'mould/myQuery',
          searchName: 'name',
          condition: initialValues => !initialValues['id']
        },
        {
          name: 'type2',
          label: '文书名'
        },
        {
          name: 'content',
          type: 'editor',
          label: '文书内容'
        }
      ]
    }
  }
  render() {
    return (
      <>
        <CustomTable
          rowKey="id"
          columns={this.config.columns}
          search={this.config.search}
          actions={this.config.actions}
          refresh={this.state.refresh}
          api={`${this.state.sf}/caseDoc/query/${this.state.caseId}`}
        />
        <Drawer
          title="新建文书"
          placement={'bottom'}
          height={'top'}
          // closeIcon={(
          //   <Button
          //     type="primary"
          //   >
          //     保存
          //   </Button>
          // )}
          onClose={() => {
            this.setState({
              visible: false
            })
          }}
          visible={this.state.visible}
        >
          <CustomForm
            formData={this.config.formData}
            defaultData={{ caseId: this.state.caseId, a: 0 }}
            initialValues={this.state.initialValues}
            notBack={true}
            onHandleOK={() => {
              this.setState({
                refresh: !this.state.refresh,
                visible: false,
                initialValues: {
                  type1: null,
                  type2: null,
                  memo: null,
                  content: null,
                  id: null
                }
              })
            }}
            action={`${this.state.sf}/caseDoc/editDoc`}
          />
        </Drawer>
        <Drawer
          title="查看文书"
          placement={'bottom'}
          height={'top'}
          // closeIcon={(
          //   <Button
          //     type="primary"
          //   >
          //     保存
          //   </Button>
          // )}
          onClose={() => {
            this.setState({
              visible2: false
            })
          }}
          visible={this.state.visible2}
        >
          <Descriptions
            bordered
            column={2}
          >
            {
              this.state.details.map((item, index) => {
                return (
                  <Descriptions.Item
                    key={index}
                    label={item.label}
                    span={item.span || 1}
                  >
                    {
                      getDetail(item)
                    }
                  </Descriptions.Item>
                )
              })
            }
          </Descriptions>
          <div className='align-center'>
            <Button
              style={{'margin': '20px auto'}}
              size='large'
              onClick={() => {
                this.setState({
                  visible2: false
                })
              }}
            >
              返回
            </Button>
          </div>
        </Drawer>
      </>

    )
  }

}

export default withRouter(CustomDocumentList)
