import React from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'

function Password() {
  const formData = [
    {
      name: 'psd',
      label: '新密码',
      type: 'password'
    },
    {
      name: 'psd2',
      label: '再次输入',
      type: 'password'
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={{}}
        notBack={true}
        action='user/updatePsd'
        onHandleOK={(values, res, _this) => {
          _this.formRef.current.setFieldsValue(
            Object.assign.apply({}, Object.keys(_this.formRef.current.getFieldsValue()).map(item => {
              return {
                [item]: null
              }
            }))
          )
        }}
      />
    </>
  )
}

export default withRouter(Password)
