import React, { useEffect, useState } from 'react'
import CustomPageHeader from 'components/CustomPageHeader'
import CustomForm from 'components/CustomForm'
import { withRouter } from 'react-router-dom'
import axios from '../../request/axios'
import { sexType, userTypes } from '../../config/types'
import Zmage from 'react-zmage'
import cookie from 'js-cookie'

function CenterSet(props) {
  const userType = cookie.get('userType')
  const formData = [
    {
      name: 'id',
      label: '我是',
      type: 'text',
      render: () => userTypes[userType].label
    },
    {
      name: 'name',
      label: '姓名',
      type: 'text'
    },
    {
      name: 'nation',
      label: '民族',
      type: 'text'
    },
    {
      name: 'idcard',
      label: '身份证号',
      type: 'text',
      condition: initialValues => initialValues['isReal'] === 1
    },

    {
      name: 'mobile',
      label: '手机号',
      type: 'text'
    },
    {
      name: 'sex',
      label: '性别',
      type: 'text',
      render: item => sexType[item.value || 0]
      // render: type => sexType[type]
    },
    {
      type: 'text',
      label: '身份证号',
      render: (item, father) => {
        //return father.props.initialValues['idcardF']
        const imgs = [{
          src: father.props.initialValues['idcardZ']
        }, {
          src: father.props.initialValues['idcardF']
        }]
        return imgs.map((img, index) => (
          <Zmage
            key={index}
            width={120}
            height={120}
            style={{objectFit: 'contain', margin: '10px', border: '1px solid #d0d0d0'}}
            src={img.src}
            defaultPage={index}
            set={imgs}
          />
        ))
      },
      condition: initialValues => initialValues['isReal'] === 1
    },
    {
      name: 'nickName',
      label: '昵称'
    },
    {
      name: 'headPic',
      type: 'uploadImg',
      folderPath: '/person/avatar/',
      label: '头像'
    },
    {
      name: 'addStr,province,city,area',
      label: '省市区',
      type: 'area'
    },
    {
      name: 'address',
      label: '地址'
    },
    {
      name: 'isReal',
      label: '已实名认证',
      type: 'text',
      render: item => item.value === 1 ? '是' : '否'
    },
    {
      name: 'isLawyer',
      label: '是否律师',
      type: 'text',
      render: item => item.value === 1 ? '是' : '否'
    }
  ]
  const [initialValues, setInitialValues] = useState({})
  useEffect(() => {
    axios.postString(`user/personalDetail`).then(res => {
      setInitialValues({
        ...res
      })
    }).catch(() => {
      props.history.goBack()
    })
  }, [])
  return (
    <>
      <CustomPageHeader/>
      <CustomForm
        formData={formData}
        initialValues={initialValues}
        action='user/personalEdit'
        notBack={true}
        onHandleOK={values => {
          const newUser = JSON.parse(decodeURIComponent(cookie.get('user')))
          newUser.headPic = values.headPic
          newUser.nickName = values.nickName
          cookie.set('user', JSON.stringify(newUser))
        }}
      />
    </>
  )
}

export default withRouter(CenterSet)
