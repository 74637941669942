import React from 'react'
import { transformatTime } from '../../utils/utils'
import { Badge } from 'antd'
import CustomPageHeader from '../../components/CustomPageHeader'
import CustomTable from '../../components/CustomTable'
import { PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { agencyStateText } from '../../config/types'



function AgencyQuery(props) {
  const isOrganization = props.history.location.pathname === '/agency/query'
  const columns = [
    {
      title: isOrganization ? '机构名称' : '事务所名称',
      dataIndex: 'agencyName',
    },
    {
      title: '状态',
      dataIndex: 'state',
      align: 'center',
      // filters: Object.entries(stateText).map(item => {
      //   return {
      //     value: item[0],
      //     text: item[1].text
      //   }
      // }),
      render: state => {
        return (
          <Badge color={agencyStateText[state].color} text={agencyStateText[state].label} />
        )
      }
    },
    {
      title: '地区',
      dataIndex: 'addStr',
      align: 'center',
      // render: (area,row) => [row.province, row.city, row.area].filter(item => item).map(item => item).join('-')
    },
    {
      title: '入驻时间',
      dataIndex: 'createTime',
      align: 'center',
      render: createTime => transformatTime(new Date(createTime))
    }
  ]
  const search = {
    extra: [
      {
        label: '新建',
        icon: <PlusOutlined/>,
        onClick() {
          props.history.push('add')
        }
      }
    ],
    params: [
      {
        name: 'agencyName',
        label: isOrganization ? '机构名称' : '律所名称'
      },
      {
        name: 'state',
        label: '状态',
        type: 'select',
        options: Object.entries(agencyStateText).map(item => {
          return {
            value: item[0],
            label: item[1].label
          }
        })
      }
    ]
  }
  const actions = [
    {
      type: 'link',
      url: 'detail/:id'
    },
    {
      type: 'link',
      label: '修改',
      url: 'edit/:id'
    },
    {
      type: 'delete',
      api: 'agency/del/:id'
    },
    {
      type: 'audit',
      api: 'agency/audit',
      dataName: 'id',
      params: [
        {
          name: 'auditStatus',
          to: 'state',
          type: 'radio',
          label: '审核结果',
          options: Object.entries(agencyStateText).filter(item => ['1', '2'].includes(item[0])).map(item => {
            return {
              value: item[0],
              label: item[1].label
            }
          })
        },
        {
          name: 'auditContent',
          label: '审核意见',
          type: 'textArea'
        }
      ],
      condition: row => ['0', '2'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '冻结',
      api: 'agency/audit',
      dataName: 'id',
      defaultData: { auditStatus: 3 },
      defaultTo: { state: 3 },
      params: [
        {
          name: 'auditContent',
          label: '冻结理由',
          type: 'textArea'
        }
      ],
      condition: row => ['1'].includes(row.state.toString())
    },
    {
      type: 'audit',
      label: '解冻',
      api: 'agency/audit',
      dataName: 'id',
      defaultData: { auditStatus: 1 },
      defaultTo: { state: 1 },
      params: [
        {
          name: 'auditContent',
          label: '审核意见',
          type: 'textArea'
        }
      ],
      condition: row => ['3'].includes(row.state.toString())
    }
  ]
  return (
    <>
      <CustomPageHeader/>
      <CustomTable
        rowKey="id"
        columns={columns}
        search={search}
        actions={actions}
        defaultData={{ type: isOrganization ? '2' : '1'}}
        api='agency/query'
      />

    </>
  )
}
export default withRouter(AgencyQuery)
